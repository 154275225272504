import React from 'react';
import {
	Backdrop,
	copyFormFieldsToDataFields,
	deepCopy,
	FormField,
	generic_string,
	GenericComponent,
	GenericForm,
	genericstring,
	GenericTable,
	getInitialState,
	Modal,
	Modal_view_content,
	raiseError,
} from 'WebUI_Framework';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { resetFilters } from '../lib/helper';
import AddCommercialTaxDetail from './AddCommercialTaxDetail';
import serviceRoutines from './CommercialtaxdetailServiceRoutines';
import EditCommercialTaxDetail from './EditCommercialTaxDetail';

let refCode_location = window.getRefCode('00012');

const fields = {
	location: {
		id: 'location',
		name: 'location',
		label: window.getLabelLiteralWithColon('LOCATION'),
		class: genericstring,
		component: generic_string,
		mandatory: false,
	},
	tin_type: {
		id: 'tin_type',
		name: 'tin_type',
		label: window.getLabelLiteralWithColon('TIN_TYPE'),
		class: genericstring,
		component: generic_string,
		mandatory: false,
	},
	tin_number: {
		id: 'tin_number',
		name: 'tin_number',
		label: window.getLabelLiteralWithColon('TIN_NUMBER'),
		class: genericstring,
		component: generic_string,
		mandatory: false,
	},
};

let mapping = [
	['location', 'location'],
	['tin_type', 'tin_type'],
	['tin_number', 'tin_number'],
];

class ManageCommercialTax extends GenericComponent {
	constructor(p) {
		super(p);
		this.recordsPerFrame = window.getParam('CURSOR_FRAME_SIZE');
		this.pageNumber = 1;
		this.selectArrayIndex = 0;
		this.selectedOrgId = 0;
		this.selectedData = '';
		//this.recordsPerPage = 10;
		this.recordsPerPage = window.getParam('TABLE_PAGE_SIZE');
		this.state.showErrorFlag = false;
		this.error1 = '';
		this.org_id = this.session.getLoggedInCompanyId();
		this.serverInstance = this.session.getServerInstance('ROC');
		this.accessToken = this.session.getAccessToken();
		this.getRow = this.getRow.bind(this);
		this.getFrame = this.getFrame.bind(this);
		this.onConfirm = this.onConfirm.bind(this);
		this.button.func = this.button.func.bind(this);
		this.data = [];
		this.state.data1 = [];
		this.state.isLoading = true;
		this.state.showModal = false;
		this.state.commercial_tax_details = [];
		this.Commercialtaxdetails = {};
	}

	componentDidMount() {
		this.getFrame(1, this.recordsPerFrame);
	}

	initialState = getInitialState(fields);

	state = {
		data: deepCopy(this.initialState),
	};
	onReturn = (ret) => {
		console.log('manage commercial tax detail page');

		this.setState((prevState, props) => {
			let newState = deepCopy(prevState);

			if (ret.retFrom === 'add') {
				this.pageNumber = 1;
				newState.data = [];
				this.getFrame(1, this.recordsPerFrame);
			} else if (ret.retFrom === 'edit') {
				let updatedArrayIndex =
					(this.pageNumber - 1) * this.recordsPerPage +
					ret.arrayIndex;
				newState.data[updatedArrayIndex] = ret.selectedData;
			}
			/*
			else if (ret.retFrom==="back"){
				let updatedArrayIndex = ((this.pageNumber - 1) * this.recordsPerPage) + ret.arrayIndex;
			}	
			*/
			return newState;
		});
	};

	addHandler = () => {
		this.callForm(AddCommercialTaxDetail, { ...this.props }, this.onReturn);
	};

	editHandler = (selectedData, arrayIndex) => {
		this.callForm(
			EditCommercialTaxDetail,
			{ selectedData, arrayIndex, ...this.props },
			this.onReturn,
		);
	};

	deleteHandler = (selectedData, arrayIndex) => {
		this.selectedData = selectedData;
		this.selectArrayIndex = arrayIndex;
		this.setState({ showModal: true });
	};

	viewHandler = async (selectedData, arrayIndex) => {
		this.callForm(
			EditCommercialTaxDetail,
			{ readOnly: true, selectedData, arrayIndex, ...this.props },
			this.onReturn,
		);
		/*
		let retData;
        try {
            retData = await serviceRoutines.fetch(this.session.getServerInstance('ROC'), this.session.getAccessToken(), selectedData)
            if (retData !== undefined && retData !== null) {

                this.Commercialtaxdetails =retData;
                this.data = retData;
				this.setState( { data1: this.Commercialtaxdetails } )
				this.setState({ viewModal: true });

            }
        }
        catch (e) {
            this.setError(raiseError(e.error_message));
        }
        */
	};

	onConfirm = async (control) => {
		try {
			const retData = await serviceRoutines.fetch(
				this.serverInstance,
				this.accessToken,
				this.selectedData,
			);
			if (retData !== undefined && retData !== null) {
				await serviceRoutines.delete(
					this.serverInstance,
					this.accessToken,
					retData,
				);
				let updatedArrayIndex =
					(this.pageNumber - 1) * this.recordsPerPage +
					this.selectArrayIndex;
				this.state.data.splice(updatedArrayIndex, 1);
				this.setState({ data: this.state.data });
				this.setState({ showModal: false });
			}
		} catch (e) {
			control.error = true;
			control.errorMsg = e.error_message;
		}
	};

	closeModal = () => {
		this.setState({ showModal: false });
	};
	closeViewModal = () => {
		this.setState({ viewModal: false });
	};
	async getFrame(offset, recordsPerFrame) {
		let retData;

		let params = {
			offset,
			num_recs: recordsPerFrame,
			org_id: this.org_id,
			deleted: 0,
		};

		copyFormFieldsToDataFields(mapping, this.state.data, params, fields);
		try {
			retData = await serviceRoutines.list(
				this.serverInstance,
				this.accessToken,
				params,
			);
			// if (retData.length > 0) {
			// 	let CommercialTaxDetail = retData;
			// 	// this.setState({ data: this.state.data.concat(CommercialTaxDetail) });
			// }

			if (offset > 1 && retData.length > 0) {
				this.commercial_tax_details =
					this.commercial_tax_details.concat(retData);
			} else {
				this.commercial_tax_details = retData || [];
			}
			this.setState({
				commercial_tax_details: this.commercial_tax_details,
			});
		} catch (e) {
			console.log(e);
			this.setError(raiseError(e.err_message));
		}
		this.setState({ isLoading: false });
	}
	getHeader() {
		let key = 0;
		return (
			<tr key={++key}>
				<th key={++key}>Location</th>
				<th key={++key}>Tax Identification Type</th>
				<th key={++key}>Tax Identification Number</th>
				<th key={++key}>Actions</th>
			</tr>
		);
	}

	getRow(data, index, pageNumber, arrayIndex) {
		this.pageNumber = pageNumber;
		return (
			<tr key={index}>
				<td>
					<button
						type="button"
						style={{
							color: 'blue',
							textDecoration: 'underline',
							cursor: 'pointer',
							border: 'none',
							background: 'none',
							padding: 0,
						}}
						onClick={() => this.viewHandler(data, arrayIndex)}
					>
						{refCode_location[data.location]}
					</button>
				</td>
				<td>{data.tin_type}</td>
				<td>{data.tin_number}</td>
				<td>
					<span
						onClick={() => this.editHandler(data, arrayIndex)}
						title="Edit"
						style={{ cursor: 'pointer' }}
					>
						<i
							className="bi bi-pencil-square"
							style={{ fontSize: 20 }}
						></i>
					</span>
					&nbsp;&nbsp;
					<span
						onClick={() => this.deleteHandler(data, arrayIndex)}
						title="Delete"
						style={{ cursor: 'pointer' }}
					>
						<i
							className="bi bi-trash3"
							style={{ fontSize: 20 }}
						></i>
					</span>
				</td>
			</tr>
		);
	}

	button = {
		literal: 'Add',
		func: function () {
			this.callForm(
				AddCommercialTaxDetail,
				{ ...this.props },
				this.onReturn,
			);
		},
	};

	render() {
		let buttons = [];
		let modalViewContent = (
			<div className="container pt-0 m-0 ">
				<EditCommercialTaxDetail
					selectedData={this.data}
					readOnly={true}
					noFormTag={true}
					{...this.props}
				/>
			</div>
		);
		let filterContent = (
			<div className="row">
				<div className="col-sm-6">
					<FormField
						field={fields.tin_type}
						controlProps={this.controlProps}
					/>
				</div>
				<div className="col-sm-6">
					<FormField
						field={fields.tin_number}
						controlProps={this.controlProps}
					/>
				</div>
				<div className="col-sm-6">
					<FormField
						field={fields.location}
						controlProps={this.controlProps}
					/>
				</div>
			</div>
		);
		const tableclass = 'table table-striped border-dark';
		let datablk = (
			<div className="container-fluid pt-0 m-0 pe-2">
				<div className="col-sm-12">
					<GenericTable
						pageHeading={this.getBreadcrumb()}
						pageHeadingClass="h6 text-left"
						data={this.state.commercial_tax_details}
						recordsPerPage={this.recordsPerPage}
						getRow={this.getRow}
						getHeader={this.getHeader}
						tableclass={tableclass}
						getFrame={this.getFrame}
						recordsPerFrame={this.recordsPerFrame}
						pageNumber={this.pageNumber}
						isLoading={this.state.isLoading}
						button={this.button}
						showFilterCriteria={filterContent}
						modalDialogStyle={{ marginLeft: '15em' }}
						resetFilters={(control) =>
							resetFilters({
								fields,
								setState: this.setState.bind(this),
							})
						}
					/>
				</div>

				{this.state.showModal && (
					<Modal
						title="Delete Confirmation"
						msg="Are you sure, you want to delete the Commercial Tax Details?"
						btn1="Confirm"
						btn2="Cancel"
						onClick={this.closeModal}
						onConfirm={this.onConfirm}
					/>
				)}
				{this.state.showModal && <Backdrop />}

				{this.state.viewModal && (
					<Modal_view_content
						title={this.getBreadcrumb([
							window.getLabelLiteral('VIEW'),
						])}
						btn1="Close"
						onClick={this.closeViewModal}
						modalContent={modalViewContent}
					/>
				)}
				{this.state.viewModal && <Backdrop />}
			</div>
		);

		let frm = (
			<GenericForm
				datablk={datablk}
				buttons={buttons}
				controlProps={this.controlProps}
				{...this.props}
			/>
		);

		return frm;
	}
}

export default ManageCommercialTax;
