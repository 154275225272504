import {
	deepCopy,
	GenericComponent,
	GenericForm,
	GenericTable,
	getInitialState,
	NarrowButton,
	raiseError,
	valuePresent,
} from 'WebUI_Framework';

import 'bootstrap-icons/font/bootstrap-icons.css';
import React from 'react';
import subscriberServiceRoutines from '../subscribers/SubscriberServiceRoutines';
import userServiceRtn from './UsersServiceRoutines';

class ManageUsers extends GenericComponent {
	constructor(p) {
		super(p);
		this.recordsPerFrame = window.getParam('CURSOR_FRAME_SIZE');
		this.pageNumber = 1;
		this.recordsPerPage = window.getParam('TABLE_PAGE_SIZE');
		this.user_principal = p.user_principal;
		this.user_name = p.user_name;
		this.selectArrayIndex = '';
		this.serverInstance = this.session.getServerInstance('SUBSCRIBER');
		this.accessToken = this.session.getAccessToken();
		this.logged_in_org_id = this.session.getLoggedInCompanyId();
		this.logged_in_parent_org_id =
			this.session.getLoggedInParentCompanyId();
		this.inboxes = [];
		this.user_inboxes = {};

		this.state = {
			isLoading: true,
		};
		this.state.updateCount = 0;
	}

	initialState = getInitialState();

	state = {
		data: deepCopy(this.initialState),
	};

	getInboxes = async () => {
		let retData;
		try {
			retData = await subscriberServiceRoutines.fetchParameters(
				this.session,
				this.logged_in_org_id,
			);
			this.setState({ isLoading: false });
		} catch (e) {
			this.setError(raiseError(e.error_message));
		}
		return retData.valid_inboxes;
	};

	getUserInboxes = async () => {
		let retData;
		try {
			retData = await userServiceRtn.getUserInboxes(
				this.session,
				this.user_principal,
			);
			this.setState({ isLoading: false });
		} catch (e) {
			this.setError(raiseError(e.error_message));
		}
		return retData;
	};

	componentDidMount = async () => {
		try {
			this.inboxes = await this.getInboxes();
			if (!valuePresent(this.inboxes)) this.inboxes = [];
			this.inboxes = this.inboxes.sort((a, b) => {
				const l = a.name.toUpperCase();
				const r = b.name.toUpperCase();
				if (l < r) return -1;
				else if (l > r) return 1;
				else return 0;
			});

			const user_inboxes = await this.getUserInboxes();
			for (const element of user_inboxes) {
				this.user_inboxes[element.inbox_name] = element;
			}
			for (const element of this.inboxes) {
				if (this.user_inboxes[element.name]) {
					element.record = this.user_inboxes[element.name];
					element.enabled = true;
				} else {
					element.enabled = false;
				}
			}
		} catch (e) {
			window.scrollTo({
				top: 0,
				left: 0,
				behavior: 'smooth',
			});
			console.log(e);
			this.setError(raiseError(e.error_message));
		}
	};

	getHeader = () => {
		let key = 0;
		return (
			<tr key={++key}>
				<th width={'80%'} key={++key}>
					{window.getLabelLiteral('INBOX_NAME')}
				</th>
				<th width={'20%'} key={++key}>
					Actions
				</th>
			</tr>
		);
	};

	enableHandler = async (inbox_name, arrayIndex) => {
		this.resetError();
		let userInbox = {
			user_principal: this.user_principal,
			parent_org_id: this.logged_in_parent_org_id,
			inbox_name,
		};
		try {
			this.setState({ isLoading: true });
			userInbox = await userServiceRtn.addUserInbox(
				this.session,
				userInbox,
			);
			this.inboxes[arrayIndex].record = userInbox;
			this.inboxes[arrayIndex].enabled = true;

			this.setState({
				updateCount: this.state.updateCount + 1,
				isLoading: false,
			});
		} catch (e) {
			this.setState({ isLoading: false });
			window.scrollTo({
				top: 0,
				left: 0,
				behavior: 'smooth',
			});
			console.log(e);
			this.setError(raiseError(e.error_message));
		}
	};

	disableHandler = async (inbox_name, arrayIndex) => {
		this.resetError();
		this.setState({ isLoading: true });
		try {
			await userServiceRtn.deleteUserInbox(
				this.session,
				this.inboxes[arrayIndex].record,
			);
			delete this.inboxes[arrayIndex].record;
			this.inboxes[arrayIndex].enabled = false;

			this.setState({
				updateCount: this.state.updateCount + 1,
				isLoading: false,
			});
		} catch (e) {
			this.setState({ isLoading: false });
			window.scrollTo({
				top: 0,
				left: 0,
				behavior: 'smooth',
			});
			console.log(e);
			this.setError(raiseError(e.error_message));
		}
	};

	getRow = (data, index, pageNumber, arrayIndex) => {
		let key = 0;
		return (
			<tr key={index}>
				<td width={'80%'}>{data.name}</td>
				<td width={'20%'}>
					{!data.enabled && (
						<NarrowButton
							className="btn btn-outline-dark w-40"
							onClick={() =>
								this.enableHandler(data.name, arrayIndex)
							}
							value="Enable"
							key={++key}
						/>
					)}
					{data.enabled && (
						<NarrowButton
							className="btn btn-secondary dark w-40"
							onClick={() =>
								this.disableHandler(data.name, arrayIndex)
							}
							value="Disable"
							key={++key}
						/>
					)}
				</td>
			</tr>
		);
	};

	render = () => {
		const tableclass = 'table table-striped border-dark';
		let datablk = (
			<div className="container-fluid pt-0 m-0 pe-2">
				<div className="col-sm-12">
					<GenericTable
						pageHeading={this.getBreadcrumb([
							window.getLabelLiteral('ACCESS_TO_INBOX') +
								' [' +
								this.user_principal +
								']',
						])}
						pageHeadingClass="h6 text-left"
						data={this.inboxes}
						recordsPerPage={this.recordsPerPage}
						getRow={this.getRow}
						getHeader={this.getHeader}
						tableclass={tableclass}
						recordsPerFrame={this.recordsPerFrame}
						isLoading={this.state.isLoading}
					/>
				</div>
			</div>
		);

		let frm = (
			<GenericForm
				datablk={datablk}
				buttons={[]}
				controlProps={this.controlProps}
				{...this.props}
			/>
		);
		return frm;
	};
}

export default ManageUsers;
