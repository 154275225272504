import { handleAxiosHTTPException } from 'WebUI_Framework';

let bankdetailserviceroutines = {};

bankdetailserviceroutines.list = async (
	serverInstance,
	accessToken,
	params,
) => {
	let response;
	try {
		response = await serverInstance.get(
			`/biop/subscriber/bank_detail/list`,
			{
				headers: {
					'X-Auth': accessToken,
				},
				params,
			},
		);
		return response.data;
	} catch (e) {
		return handleAxiosHTTPException(e);
	}
};

bankdetailserviceroutines.fetch = async (
	serverInstance,
	accessToken,
	selectedOrgId,
	selected_acct_identifier,
) => {
	let response;
	try {
		response = await serverInstance.get(
			`/biop/subscriber/bank_detail/fetch?org_id=${selectedOrgId}&acct_identifier=${selected_acct_identifier}`,
			{
				headers: {
					'X-Auth': accessToken,
				},
			},
		);
		return response.data;
	} catch (e) {
		return handleAxiosHTTPException(e);
	}
};

bankdetailserviceroutines.delete = async (
	serverInstance,
	accessToken,
	data,
) => {
	let response;
	try {
		response = await serverInstance.put(
			`/biop/subscriber/bank_detail/delete`,
			data,
			{
				headers: {
					'X-Auth': accessToken,
				},
			},
		);

		return response.data;
	} catch (e) {
		return handleAxiosHTTPException(e);
	}
};
bankdetailserviceroutines.add = async (serverInstance, accessToken, retObj) => {
	let response;
	try {
		response = await serverInstance.put(
			'/biop/subscriber/bank_detail/add',
			retObj,
			{
				headers: {
					'X-Auth': accessToken,
				},
			},
		);

		return response.data;
	} catch (e) {
		return handleAxiosHTTPException(e);
	}
};

bankdetailserviceroutines.modify = async (
	serverInstance,
	accessToken,
	selectedBankdata,
) => {
	let response;
	try {
		response = await serverInstance.post(
			'/biop/subscriber/bank_detail/modify',
			selectedBankdata,
			{
				headers: {
					'X-Auth': accessToken,
				},
			},
		);
		return response.data;
	} catch (e) {
		return handleAxiosHTTPException(e);
	}
};

export default bankdetailserviceroutines;
