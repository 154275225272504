import { handleAxiosHTTPException } from 'WebUI_Framework';
import { getServiceDetails } from '../common/utils';

let DeliveryChallanServiceRoutines = {};

DeliveryChallanServiceRoutines.list = async (session, params) => {
	const { serverInstance, accessToken } = getServiceDetails(session);
	let response;
	try {
		response = await serverInstance.get(
			`/biop/subscriber/delivery_challan/list`,
			{
				headers: {
					'X-Auth': accessToken,
				},
				params,
			},
		);
	} catch (e) {
		console.log(e);
		return handleAxiosHTTPException(e);
	}
	return response.data;
};

DeliveryChallanServiceRoutines.fetch = async (session, deliveryNoteId) => {
	const { serverInstance, accessToken } = getServiceDetails(session);
	let response;
	try {
		response = await serverInstance.get(
			`/biop/subscriber/delivery_challan/fetch?delivery_note_id=${deliveryNoteId}`,
			{
				headers: {
					'X-Auth': accessToken,
				},
			},
		);
		return response.data;
	} catch (e) {
		console.log(e);
		return handleAxiosHTTPException(e);
	}
};

DeliveryChallanServiceRoutines.fetchAmended = async (
	session,
	deliveryNoteId,
) => {
	const { serverInstance, accessToken } = getServiceDetails(session);
	let response;
	try {
		response = await serverInstance.get(
			`/biop/subscriber/delivery_challan/fetch_amended?delivery_note_id=${deliveryNoteId}`,
			{
				headers: {
					'X-Auth': accessToken,
				},
			},
		);
		return response.data;
	} catch (e) {
		console.log(e);
		return handleAxiosHTTPException(e);
	}
};

DeliveryChallanServiceRoutines.fetchUsingNumberAndDate = async (
	session,
	deliveryNoteNumber,
	deliveryNoteDate,
	sellerOrgId,
) => {
	const { serverInstance, accessToken } = getServiceDetails(session);
	let response;
	let params = {
		delivery_note_number: deliveryNoteNumber,
		delivery_note_date: deliveryNoteDate,
		seller_org_id: sellerOrgId,
	};
	try {
		response = await serverInstance.get(
			`/biop/subscriber/delivery_challan/fetch_using_number_and_date`,
			{
				params,
				headers: {
					'X-Auth': accessToken,
				},
			},
		);
		return response.data;
	} catch (e) {
		console.log(e);
		return handleAxiosHTTPException(e);
	}
};

DeliveryChallanServiceRoutines.fetch_sales_order = async (
	session,
	buyerOrgId,
	orderIssueDate,
	orderNumber,
) => {
	const { serverInstance, accessToken } = getServiceDetails(session);
	let response;
	let params = {};
	if (orderNumber) {
		params.order_number = orderNumber;
	}
	if (orderIssueDate) {
		params.order_issue_date = orderIssueDate;
	}
	if (buyerOrgId) {
		params.buyer_org_id = buyerOrgId;
	}

	try {
		response = await serverInstance.get(
			`/biop/subscriber/sales_order/fetch_using_number_and_date`,
			{
				params,
				headers: {
					'X-Auth': accessToken,
				},
			},
		);
		return response.data;
	} catch (e) {
		console.log(e);
		return handleAxiosHTTPException(e);
	}
};

DeliveryChallanServiceRoutines.add = async (session, deliveryNote) => {
	const { serverInstance, accessToken } = getServiceDetails(session);
	let response;
	try {
		response = await serverInstance.put(
			'/biop/subscriber/delivery_challan/add',
			deliveryNote,
			{
				headers: {
					'X-Auth': accessToken,
				},
			},
		);
		return response.data;
	} catch (e) {
		return handleAxiosHTTPException(e);
	}
};

DeliveryChallanServiceRoutines.modify = async (session, deliveryNote) => {
	const { serverInstance, accessToken } = getServiceDetails(session);
	let response;
	try {
		response = await serverInstance.put(
			'/biop/subscriber/delivery_challan/modify',
			deliveryNote,
			{
				headers: {
					'X-Auth': accessToken,
				},
			},
		);
		return response.data;
	} catch (e) {
		return handleAxiosHTTPException(e);
	}
};

DeliveryChallanServiceRoutines.amend = async (session, deliveryNote) => {
	const { serverInstance, accessToken } = getServiceDetails(session);
	let response;
	try {
		response = await serverInstance.put(
			'/biop/subscriber/delivery_challan/amend',
			deliveryNote,
			{
				headers: {
					'X-Auth': accessToken,
				},
			},
		);
		return response.data;
	} catch (e) {
		return handleAxiosHTTPException(e);
	}
};

DeliveryChallanServiceRoutines.cancelAmendMent = async (
	session,
	deliveryNote,
) => {
	const { serverInstance, accessToken } = getServiceDetails(session);
	let response;
	try {
		response = await serverInstance.put(
			'/biop/subscriber/delivery_challan/cancel_amendment',
			deliveryNote,
			{
				headers: {
					'X-Auth': accessToken,
				},
			},
		);
		return response.data;
	} catch (e) {
		return handleAxiosHTTPException(e);
	}
};

DeliveryChallanServiceRoutines.approve = async (session, deliveryNote) => {
	const { serverInstance, accessToken } = getServiceDetails(session);
	let response;
	try {
		response = await serverInstance.put(
			'/biop/subscriber/delivery_challan/approve',
			deliveryNote,
			{
				headers: {
					'X-Auth': accessToken,
				},
			},
		);
		return response.data;
	} catch (e) {
		return handleAxiosHTTPException(e);
	}
};

DeliveryChallanServiceRoutines.delete = async (session, deliveryNote) => {
	const { serverInstance, accessToken } = getServiceDetails(session);
	let response;
	try {
		response = await serverInstance.put(
			'/biop/subscriber/delivery_challan/delete',
			deliveryNote,
			{
				headers: {
					'X-Auth': accessToken,
				},
			},
		);
		return response.data;
	} catch (e) {
		return handleAxiosHTTPException(e);
	}
};

DeliveryChallanServiceRoutines.send = async (session, deliveryNote) => {
	const { serverInstance, accessToken } = getServiceDetails(session);
	let response;
	try {
		response = await serverInstance.put(
			'/biop/subscriber/delivery_challan/send',
			deliveryNote,
			{
				headers: {
					'X-Auth': accessToken,
				},
			},
		);
		return response.data;
	} catch (e) {
		return handleAxiosHTTPException(e);
	}
};

export default DeliveryChallanServiceRoutines;
