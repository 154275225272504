import React from 'react';
import {
	GenericComponent,
	GenericForm,
	FormField,
	NarrowButton,
	getInitialState,
	copyDataFieldsToFormFields,
	deepCopy,
	copyFormFieldsToDataFields,
	raiseError,
	location,
	tin_type,
	tin_number,
	Location,
	tintype,
	tinnumber,
	fieldValidations,
	Modal,
	Backdrop,
} from 'WebUI_Framework';
import serviceRoutines from './CommercialtaxdetailServiceRoutines';

const fields = {
	location: {
		id: 'location',
		name: 'location',
		label: 'Location :',
		class: Location,
		component: location,
		mandatory: true,
	},

	tin_type: {
		id: 'tin_type',
		name: 'tin_type',
		label: 'TIN type :',
		class: tintype,
		component: tin_type,
		mandatory: true,
	},

	tin_number: {
		id: 'tin_number',
		name: 'tin_number',
		label: 'TIN Number:',
		class: tinnumber,
		component: tin_number,
		mandatory: true,
	},
};

let mapping = [
	['org_id', 'org_id'],
	['location', 'location'],
	['tin_type', 'tin_type'],
	['tin_number', 'tin_number'],
	['version', 'version'],
];

class EditCommercialtaxDetail extends GenericComponent {
	constructor(props) {
		super(props);
		this.serverInstance = this.session.getServerInstance('ROC');
		this.accessToken = this.session.getAccessToken();
		this.selectedcommercialtaxdata = {};
		this.state.showModal = false;
	}

	initialState = getInitialState(fields);

	state = {
		data: deepCopy(this.initialState),
	};

	componentDidMount() {
		this.getSelectedCommercialtaxdata();
	}

	async getSelectedCommercialtaxdata() {
		let retData;
		try {
			retData = await serviceRoutines.fetch(
				this.serverInstance,
				this.accessToken,
				this.props.selectedData,
			);
			if (retData !== undefined && retData !== null) {
				this.selectedcommercialtaxdata = retData;
				let tempObj = {};
				copyDataFieldsToFormFields(
					mapping,
					this.selectedcommercialtaxdata,
					tempObj,
				);
				this.setState({ data: tempObj });
			}
		} catch (e) {
			console.log(e);
			this.setError(raiseError(e.error_message));
		}
	}

	backHandler = (retFrom) => {
		if (retFrom !== 'edit') {
			retFrom = 'back';
		}
		if (this.isChildMode())
			this.props.returnToParent({
				retFrom: retFrom,
				arrayIndex: this.props.arrayIndex,
				selectedData: this.selectedcommercialtaxdata,
			});
		else console.log('LOADED AS A PARENT');
	};

	closeModal = () => {
		this.setState({ showModal: false });
	};

	render() {
		let buttons = [];
		let key = 0;
		if (this.props.readOnly) {
			this.readOnly = true;
			this.disabled = true;
		}
		let datablk = (
			<div className="container-fluid pt-0 m-0 pe-2">
				<div className="row p-0 m-0 g-0 ">
					{!this.readOnly ? (
						<div className="col-sm-12 h6 text-left">
							{this.getBreadcrumb([
								window.getLabelLiteral('EDIT'),
							])}
						</div>
					) : (
						<div className="col-sm-12 h6 text-left">
							{this.getBreadcrumb([
								window.getLabelLiteral('VIEW'),
							])}
						</div>
					)}
					<div className="col-sm-6">
						<FormField
							field={fields.location}
							controlProps={this.controlProps}
							disabled={true}
						/>
					</div>
					<div className="col-sm-6">
						<FormField
							field={fields.tin_type}
							controlProps={this.controlProps}
							disabled={true}
						/>
					</div>
					<div className="col-sm-6">
						<FormField
							field={fields.tin_number}
							controlProps={this.controlProps}
							readOnly={this.readOnly}
						/>
					</div>

					<div className="row p-0 m-0 g-0 ">
						<div className="col-sm-6 mt-4 d-flex justify-content-right ">
							{!this.readOnly && (
								<NarrowButton
									value="Update"
									onClick={this.submitForm}
									key={(++key).toString()}
								/>
							)}

							<NarrowButton
								value="Back"
								onClick={this.backHandler}
								key={(++key).toString()}
							/>
						</div>
					</div>
				</div>
				{this.state.showModal && (
					<Modal
						title="Update Confirmation"
						msg="Are you sure, you want to update Commercial tax Detail?"
						btn1="Confirm"
						btn2="Cancel"
						onClick={this.closeModal}
						onConfirm={this.onConfirm}
					/>
				)}
				{this.state.showModal && <Backdrop />}
			</div>
		);

		let form = (
			<GenericForm
				datablk={datablk}
				buttons={buttons}
				controlProps={this.controlProps}
				{...this.props}
			/>
		);
		return form;
	}

	validateForm = () => {
		let r = fieldValidations(fields, this.state.data);
		if (!r.status) {
			this.setState({ error: r });
			return false;
		}

		return true;
	};

	submitForm = () => {
		this.resetError();
		if (!this.validateForm()) {
			return;
		}
		this.setState({ showModal: true });
	};

	onConfirm = async (control) => {
		copyFormFieldsToDataFields(
			mapping,
			this.state.data,
			this.selectedcommercialtaxdata,
			fields,
		);
		try {
			const retData = await serviceRoutines.modify(
				this.serverInstance,
				this.accessToken,
				this.selectedcommercialtaxdata,
			);
			if (retData !== undefined && retData !== null) {
				this.backHandler('edit');
			}
		} catch (e) {
			control.error = true;
			control.errorMsg = e.error_message;
		}
	};
}

export default EditCommercialtaxDetail;
