import { handleAxiosHTTPException } from 'WebUI_Framework';
import { getServiceDetails } from '../common/utils';

let rfqServiceroutines = {};

rfqServiceroutines.list = async (session, params) => {
	const { serverInstance, accessToken } = getServiceDetails(session);
	let response;
	try {
		response = await serverInstance.get(`biop/subscriber/rfq/list`, {
			headers: {
				'X-Auth': accessToken,
			},
			params,
		});
	} catch (e) {
		return handleAxiosHTTPException(e);
	}
	return response.data;
};

rfqServiceroutines.fetch = async (session, selectedRfqId) => {
	const { serverInstance, accessToken } = getServiceDetails(session);
	let response;
	try {
		response = await serverInstance.get(
			`biop/subscriber/rfq/fetch?rfq_id=${selectedRfqId}`,
			{
				headers: {
					'X-Auth': accessToken,
				},
			},
		);
	} catch (e) {
		return handleAxiosHTTPException(e);
	}
	return response.data;
};

rfqServiceroutines.fetchAmended = async (session, selectedRfqId) => {
	const { serverInstance, accessToken } = getServiceDetails(session);
	let response;
	try {
		response = await serverInstance.get(
			`biop/subscriber/rfq/fetch_for_amendment?rfq_id=${selectedRfqId}`,
			{
				headers: {
					'X-Auth': accessToken,
				},
			},
		);
	} catch (e) {
		return handleAxiosHTTPException(e);
	}
	return response.data;
};

rfqServiceroutines.fetchUsingNumberAndDate = async (
	session,
	rfqNumber,
	rfqDate,
	orgId,
) => {
	const { serverInstance, accessToken } = getServiceDetails(session);
	let response;
	let params = {
		rfq_number: rfqNumber,
		rfq_issue_date: rfqDate,
		buyer_org_id: orgId,
	};
	try {
		response = await serverInstance.get(
			`biop/subscriber/rfq/fetch_using_number_and_date`,
			{
				params,
				headers: {
					'X-Auth': accessToken,
				},
			},
		);
	} catch (e) {
		return handleAxiosHTTPException(e);
	}
	return response.data;
};

rfqServiceroutines.delete = async (session, retData) => {
	const { serverInstance, accessToken } = getServiceDetails(session);
	let response;
	try {
		response = await serverInstance.put(
			`biop/subscriber/rfq/delete`,
			retData,
			{
				headers: {
					'X-Auth': accessToken,
				},
			},
		);
	} catch (e) {
		return handleAxiosHTTPException(e);
	}
	return response.data;
};

rfqServiceroutines.modify = async (session, retObj_modify) => {
	const { serverInstance, accessToken } = getServiceDetails(session);
	let response;
	try {
		response = await serverInstance.post(
			'biop/subscriber/rfq/modify',
			retObj_modify,
			{
				headers: {
					'X-Auth': accessToken,
				},
			},
		);
	} catch (e) {
		return handleAxiosHTTPException(e);
	}
	return response.data;
};

rfqServiceroutines.add = async (session, retObj) => {
	const { serverInstance, accessToken } = getServiceDetails(session);
	let response;
	try {
		response = await serverInstance.post(
			'biop/subscriber/rfq/add',
			retObj,
			{
				headers: {
					'X-Auth': accessToken,
				},
			},
		);
	} catch (e) {
		return handleAxiosHTTPException(e);
	}
	return response.data;
};

rfqServiceroutines.approve = async (session, rfq) => {
	const { serverInstance, accessToken } = getServiceDetails(session);
	let response;
	try {
		response = await serverInstance.post(
			'/biop/subscriber/rfq/approve',
			rfq,
			{
				headers: {
					'X-Auth': accessToken,
				},
			},
		);
		return response.data;
	} catch (e) {
		return handleAxiosHTTPException(e);
	}
};

export default rfqServiceroutines;
