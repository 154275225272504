import {
	copyFormFieldsToDataFields,
	deepCopy,
	filtered_select,
	filteredselect,
	FormField,
	//GenericComponent,
	generic_checkbox,
	generic_string,
	genericcheckbox,
	GenericForm,
	genericstring,
	GenericTable,
	getInitialState,
	input_date,
	inputdate,
	raiseError,
	valuePresent,
} from 'WebUI_Framework';

import 'bootstrap-icons/font/bootstrap-icons.css';
import React from 'react';
import { BaseComponent } from '../common/BaseComponent';
import MultipleSelect from '../common/MultipleSelect';
import PartnerList from '../common/PartnerList';
import { invoice_statuses } from '../lib/constants';
import { resetFilters } from '../lib/helper';
import AddEditInvoice from './AddEditInvoice';
import receivedInvoiceServiceRoutines from './ReceivedInvoiceServiceRoutines';
import ViewReceivedInvoice from './ViewReceivedInvoice';

const fields = {
	invoice_number: {
		id: 'invoice_number',
		name: 'invoice_number',
		label: window.getLabelLiteralWithColon('INVOICE_NUMBER'),
		class: genericstring,
		component: generic_string,
		mandatory: false,
	},
	seller_org_id: {
		id: 'seller_org_id',
		name: 'seller_org_id',
		label: window.getLabelLiteralWithColon('SELLER_ORG'),
		class: filteredselect,
		component: filtered_select,
		mandatory: false,
	},
	from_date: {
		id: 'from_date',
		name: 'from_date',
		label: window.getLabelLiteralWithColon('INVOICE_DATE_FROM'),
		class: inputdate,
		component: input_date,
		mandatory: false,
	},
	to_date: {
		id: 'to_date',
		name: 'to_date',
		label: window.getLabelLiteralWithColon('INVOICE_DATE_TO'),
		class: inputdate,
		component: input_date,
		mandatory: false,
	},
	statuses: {
		id: 'statuses',
		name: 'statuses',
		label: window.getLabelLiteralWithColon('STATUS_TITLE'),
		class: genericstring,
		component: generic_string,
		mandatory: false,
	},
	status_draft: {
		id: 'status_draft',
		name: 'status_draft',
		label: window.getLabelLiteral('STATUS_DRAFT'),
		class: genericcheckbox,
		component: generic_checkbox,
		mandatory: false,
	},
	status_delted: {
		id: 'status_delted',
		name: 'status_delted',
		label: window.getLabelLiteral('STATUS_DELTED'),
		class: genericcheckbox,
		component: generic_checkbox,
		mandatory: false,
	},
	status_sent: {
		id: 'status_sent',
		name: 'status_sent',
		label: window.getLabelLiteral('STATUS_SENT'),
		class: genericcheckbox,
		component: generic_checkbox,
		mandatory: false,
	},
	status_received: {
		id: 'status_received',
		name: 'status_received',
		label: window.getLabelLiteral('STATUS_RECEIVED'),
		class: genericcheckbox,
		component: generic_checkbox,
		mandatory: false,
	},
	status_acknowledged: {
		id: 'status_acknowledged',
		name: 'status_acknowledged',
		label: window.getLabelLiteral('STATUS_ACKNOWLEDGED'),
		class: genericcheckbox,
		component: generic_checkbox,
		mandatory: false,
	},
	status_accepted: {
		id: 'status_accepted',
		name: 'status_accepted',
		label: window.getLabelLiteral('STATUS_ACCEPTED'),
		class: genericcheckbox,
		component: generic_checkbox,
		mandatory: false,
	},
	status_rejected: {
		id: 'status_rejected',
		name: 'status_rejected',
		label: window.getLabelLiteral('STATUS_REJECTED'),
		class: genericcheckbox,
		component: generic_checkbox,
		mandatory: false,
	},
	status_payment_done: {
		id: 'status_payment_done',
		name: 'status_payment_done',
		label: window.getLabelLiteral('STATUS_PAYMENT_DONE'),
		class: genericcheckbox,
		component: generic_checkbox,
		mandatory: false,
	},
};

let mapping = [
	['seller_org_id', 'seller_org_id'],
	['invoice_number', 'invoice_number'],
	['from_date', 'from_date'],
	['to_date', 'to_date'],
	['statuses', 'statuses'],
];

class ManageReceivedInvoices extends BaseComponent {
	constructor(p) {
		super(p);
		this.recordsPerFrame = window.getParam('CURSOR_FRAME_SIZE');
		this.pageNumber = 1;
		//this.recordsPerPage = 15;
		this.recordsPerPage = window.getParam('TABLE_PAGE_SIZE');
		this.state = {
			isLoading: true,
			viewFilter: false,
			data: [],
		};
		this.getRow = this.getRow.bind(this);
		this.getFrame = this.getFrame.bind(this);
		this.company_id = this.session.getLoggedInCompanyId();
		this.invoices = [];
	}

	componentDidMount() {
		this.getFrame(1, this.recordsPerFrame);
		this.setState({ data: getInitialState(fields) });
	}

	initialState = getInitialState(fields);

	state = {
		data: deepCopy(this.initialState),
	};

	reset = () => {
		let data = deepCopy(this.initialState);

		this.setState({ data: data, error: undefined });
	};

	onReturn = (ret) => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: 'smooth',
		});

		this.resetError();
		if (ret.error) {
			this.setError(ret.error);
			return;
		}

		let updatedArrayIndex =
			(this.pageNumber - 1) * this.recordsPerPage + ret.arrayIndex;
		/*eslint no-fallthrough: 0*/
		switch (ret.retFrom) {
			case 'VIEW':
				break;
			case 'ACKNOWLEDGE':
			case 'ACCEPT':
			case 'REJECT':
			case 'INITIATEGRN':
			case 'MARK_PAID':
				this.setState((prevState, props) => {
					this.invoices[updatedArrayIndex] = ret.selectData.header;
					if (valuePresent(ret.referralId)) {
						this.invoices[updatedArrayIndex].referral_id =
							ret.referralId;
					}

					let newState = deepCopy(prevState);
					newState.editCount++;
					return newState;
				});
				break;
			default:
				this.setError(
					raiseError('Invalid return from ViewReceivedInvoice'),
				);
		}
	};

	onAddEditReturn = (ret) => {
		this.resetError();
		if (ret.error) {
			this.setError(ret.error);
			return;
		}
		/*eslint no-fallthrough: 0*/
		switch (ret.retFrom) {
			case 'ADD':
				this.invoices.splice(
					(this.pageNumber - 1) * this.recordsPerPage,
					0,
					ret.selectData.header,
				);
				break;
			case 'EDIT':
			case 'CANCEL':
			case 'ACCEPT':
			case 'REJECT':
			case 'AMEND':
			case 'APPROVE':
			case 'APPROVEAMENDMENT':
			case 'VIEW':
				let updatedArrayIndex =
					(this.pageNumber - 1) * this.recordsPerPage +
					ret.arrayIndex;
				this.setState((prevState, props) => {
					this.invoices[updatedArrayIndex] = ret.selectData.header;
					if (valuePresent(ret.referralId)) {
						this.invoices[updatedArrayIndex].referral_id =
							ret.referralId;
					}

					let newState = deepCopy(prevState);
					newState.editCount++;
					return newState;
				});
				break;
			case 'BACK':
				break;
			case 'DELETE':
				this.invoices.splice(ret.arrayIndex, 1);
				this.setState({ editCount: this.state.editCount + 1 });
				break;
			default:
				this.setError(raiseError('Invalid return from AddEditInvoice'));
		}
		return;
	};

	handler = async (action, invoice_id, pageNumber, arrayIndex) => {
		this.pageNumber = pageNumber;
		this.callForm(
			ViewReceivedInvoice,
			{ action, invoice_id, arrayIndex, pageNumber, ...this.props },
			this.onReturn,
		);
	};

	crudHandler = async (action, invoiceId, pageNumber, arrayIndex) => {
		this.pageNumber = pageNumber;
		this.callForm(
			AddEditInvoice,
			{ action, invoiceId, pageNumber, arrayIndex, ...this.props },
			this.onAddEditReturn,
		);
	};

	getFrame = async (offset, recordsPerFrame) => {
		try {
			let obj = {
				offset,
				num_recs: recordsPerFrame,
				buyer_org_id: this.session.getLoggedInCompanyId(),
			};

			copyFormFieldsToDataFields(mapping, this.state.data, obj, fields);

			if (obj.statuses?.length <= 0) {
				obj.statuses = undefined;
			}

			const retData = await receivedInvoiceServiceRoutines.list(
				this.session.getServerInstance('SUBSCRIBER'),
				this.session.getAccessToken(),
				obj,
			);

			if (offset > 1 && retData.length > 0) {
				this.invoices = this.invoices.concat(retData);
			} else {
				this.invoices = retData || [];
			}

			this.setState({ isLoading: false });
		} catch (e) {
			this.setState({ isLoading: false });
			this.setError(raiseError(e.error_message));
		}
	};

	getRow = (data, index, pageNumber, arrayIndex) => {
		const i = index - 1;
		this.pageNumber = pageNumber;
		let key = 0;
		return (
			<tr key={index}>
				<td key={++key}>
					<button
						type="button"
						style={{
							color: 'blue',
							textDecoration: 'underline',
							cursor: 'pointer',
							border: 'none',
							background: 'none',
							padding: '0',
							margin: '0',
							textAlign: 'left',
						}}
						onClick={() =>
							this.handler(
								'VIEW',
								data.invoice_id,
								pageNumber,
								arrayIndex,
							)
						}
					>
						{data.invoice_number}
					</button>
				</td>
				<td key={++key}>{data.invoice_date}</td>
				<td key={++key}>{data.seller_org_name}</td>
				<td key={++key}>{data.payment_due_date}</td>
				<td key={++key}>{data.seller_contact_email_id}</td>
				<td key={++key} style={{ textAlign: 'right' }}>
					{window.formatCurrency(data.currency, data.total_amount)}
				</td>
				<td key={++key}>
					<i>{invoice_statuses[data.status].label}</i>
				</td>
				<td key={++key}>
					{data.entity_state === '0' &&
						data.deleted === false &&
						!valuePresent(data.referral_id) &&
						data.owner_org_id === this.company_id && (
							<>
								<span
									onClick={() =>
										this.crudHandler(
											'EDIT',
											data.invoice_id,
											pageNumber,
											arrayIndex,
										)
									}
									title="Edit"
									style={{ cursor: 'pointer' }}
								>
									<i
										className="bi bi-pencil-square"
										style={{ fontSize: '20' }}
									></i>
								</span>
								&nbsp;&nbsp;
								<span
									onClick={() =>
										this.crudHandler(
											'DELETE',
											data.invoice_id,
											pageNumber,
											arrayIndex,
										)
									}
									title="Delete"
									style={{ cursor: 'pointer' }}
								>
									<i
										className="bi bi-trash3"
										style={{ fontSize: '20' }}
									></i>
								</span>
								&nbsp;&nbsp;
								<span
									onClick={() =>
										this.crudHandler(
											'APPROVE',
											data.invoice_id,
											pageNumber,
											arrayIndex,
										)
									}
									title="Approve"
									style={{ cursor: 'pointer' }}
								>
									<i
										className="bi bi-check2"
										style={{ fontSize: '20' }}
									></i>
								</span>
								&nbsp;&nbsp;
							</>
						)}
					{data.status === '3' && !valuePresent(data.referral_id) && (
						<>
							{!valuePresent(data.receipt_note_number) && (
								<>
									<span
										onClick={() =>
											this.handler(
												'INITIATEGRN',
												data.invoice_id,
												pageNumber,
												arrayIndex,
											)
										}
										title="Process Goods Receipt"
										style={{ cursor: 'pointer' }}
									>
										<i
											className="bi bi-box-arrow-in-left"
											style={{ fontSize: 20 }}
										></i>
									</span>
									&nbsp;&nbsp;
								</>
							)}
							<span
								onClick={() =>
									this.handler(
										'ACKNOWLEDGE',
										data.invoice_id,
										pageNumber,
										arrayIndex,
									)
								}
								title="Acknowledge"
								style={{ cursor: 'pointer' }}
							>
								<i
									className="bi bi-hand-thumbs-up"
									style={{
										fontSize: 20,
										transform: 'scaleX(-1)',
										display: 'inline-block',
									}}
								></i>
							</span>
							&nbsp;&nbsp;
							<span
								onClick={() =>
									this.handler(
										'REJECT',
										data.invoice_id,
										pageNumber,
										arrayIndex,
									)
								}
								title="Reject"
								style={{ cursor: 'pointer' }}
							>
								<i
									className="bi bi-hand-thumbs-down"
									style={{
										fontSize: 20,
										transform: 'scaleX(-1)',
										display: 'inline-block',
									}}
								></i>
							</span>
							&nbsp;&nbsp;
						</>
					)}
					{data.status === '4' && !valuePresent(data.referral_id) && (
						<>
							{data.entity_state !== '2' && (
								<>
									{!valuePresent(
										data.receipt_note_number,
									) && (
										<>
											<span
												onClick={() =>
													this.handler(
														'INITIATEGRN',
														data.invoice_id,
														pageNumber,
														arrayIndex,
													)
												}
												title="Process Goods Receipt"
												style={{ cursor: 'pointer' }}
											>
												<i
													className="bi bi-box-arrow-in-left"
													style={{ fontSize: 20 }}
												></i>
											</span>
											&nbsp;&nbsp;
										</>
									)}
									<span
										onClick={() =>
											this.handler(
												'ACCEPT',
												data.invoice_id,
												pageNumber,
												arrayIndex,
											)
										}
										title="Accept"
										style={{ cursor: 'pointer' }}
									>
										<i
											className="bi bi-check2-all"
											style={{ fontSize: 20 }}
										></i>
									</span>
									&nbsp;&nbsp;
								</>
							)}
							{data.seller_org_id === data.owner_org_id && (
								<>
									<span
										onClick={() =>
											this.handler(
												'REJECT',
												data.invoice_id,
												pageNumber,
												arrayIndex,
											)
										}
										title="Reject"
										style={{ cursor: 'pointer' }}
									>
										<i
											className="bi bi-hand-thumbs-down"
											style={{
												fontSize: 20,
												transform: 'scaleX(-1)',
												display: 'inline-block',
											}}
										></i>
									</span>
									&nbsp;&nbsp;
								</>
							)}
							{data.seller_org_id !== data.owner_org_id && (
								<>
									<span
										onClick={() =>
											this.crudHandler(
												'AMEND',
												data.invoice_id,
												pageNumber,
												arrayIndex,
											)
										}
										title="Amend"
										style={{ cursor: 'pointer' }}
									>
										<i
											className="bi bi-pen"
											style={{ fontSize: '20' }}
										></i>
									</span>
									&nbsp;&nbsp;
								</>
							)}
							{data.seller_org_id !== data.owner_org_id &&
								data.entity_state === '2' && (
									<>
										<span
											onClick={() =>
												this.crudHandler(
													'APPROVEAMENDMENT',
													data.invoice_id,
													pageNumber,
													arrayIndex,
												)
											}
											title="Approve"
											style={{ cursor: 'pointer' }}
										>
											<i
												className="bi bi-check2"
												style={{ fontSize: '20' }}
											></i>
										</span>
										&nbsp;&nbsp;
										<span
											onClick={() =>
												this.crudHandler(
													'CANCEL',
													data.invoice_id,
													pageNumber,
													arrayIndex,
												)
											}
											title={window.getLabelLiteral(
												'CANCEL',
											)}
											style={{ cursor: 'pointer' }}
										>
											<i
												className="bi bi-x-square"
												style={{ fontSize: 20 }}
											></i>
										</span>
									</>
								)}
						</>
					)}
					{data.status === '5' && !valuePresent(data.referral_id) && (
						<>
							<span
								onClick={() =>
									this.handler(
										'MARK_PAID',
										data.invoice_id,
										pageNumber,
										arrayIndex,
									)
								}
								title="Mark Payment Done"
								style={{ cursor: 'pointer' }}
							>
								<i
									className="bi bi-check2-circle"
									style={{ fontSize: 20 }}
								></i>
							</span>
							&nbsp;&nbsp;
						</>
					)}
					{valuePresent(data.referral_id) && (
						<>
							<span
								onClick={() =>
									this.withdrawReferral(
										data,
										i,
										'referral_id',
									)
								}
								title="Withdraw"
								style={{ cursor: 'pointer' }}
							>
								<i
									className="bi bi-skip-backward"
									style={{ fontSize: '20' }}
								></i>
							</span>
							&nbsp;&nbsp;
						</>
					)}
				</td>
			</tr>
		);
	};

	getHeader = () => {
		let key = 0;
		return (
			<tr key={++key}>
				<th key={++key}>Invoice No.</th>
				<th key={++key}>Invoice Date</th>
				<th key={++key}>Seller</th>
				<th key={++key}>Payment Due Date</th>
				<th key={++key}>Seller Contact</th>
				<th key={++key} style={{ textAlign: 'right' }}>
					Amount(tax excl.)
				</th>
				<th key={++key}>Status</th>
				<th key={++key}></th>
			</tr>
		);
	};

	table_buttons = [
		{
			literal: 'Add',
			func: () => {
				const ucp = this.session.getUserCompanyProps(
					this.session.getLoggedInCompanyId(),
					this.session.getLoggedInParentCompanyId(),
				);
				if (!ucp.org_is_subscriber) {
					if (ucp.partner_relationship_type === 'S') {
						this.setError(
							raiseError(
								window.parseLiteral(
									'CANNOT_CREATE_INVOICE',
									this.session.getLoggedInCompanyName(),
								),
							),
						);
						return false;
					}
				}
				this.crudHandler('ADD', null, this.pageNumber, 0);
			},
		},
	];

	getCompanyList = async (control, searchText) => {
		let retData;
		try {
			retData =
				await receivedInvoiceServiceRoutines.fetch_first_level_network(
					this.session.getServerInstance('SUBSCRIBER'),
					this.session.getAccessToken(),
					this.session.getLoggedInCompanyId(),
					null,
					null,
					searchText,
				);
			return retData;
		} catch (e) {
			control.error = true;
			if (e.error_message !== null && e.error_message !== undefined) {
				control.errorMsg = e.error_message;
			} else {
				control.errorMsg = e.message;
			}
		}
	};

	formatDataList = (array) => {
		let formatted_array = [];
		array.map((value, index) => {
			let s = value.org_name;
			formatted_array.push(s);
			return null;
		});
		return formatted_array;
	};

	selectBuyerId = (index, list) => {
		let data = deepCopy(this.state.data);
		data.buyer_org_id = list[index].org_id;
		data.buyer_org_name = list[index].org_name;
		this.setState({ data });
	};

	clearSelection = () => {
		let data = deepCopy(this.state.data);
		data.buyer_org_id = '';
		data.buyer_org_name = '';
		this.setState({ data });
	};

	handleChange = (new_value) => {
		let data = deepCopy(this.state.data);
		data.statuses = new_value;
		this.setState({ data });
	};

	render = () => {
		const selectOption = (org_id, org_name) => {
			this.state.data.seller_org_id = org_id;
			this.setState({ data: this.state.data });
		};
		let buttons = [];
		let filterContent = (
			<div className="row">
				<div className="col-sm-6">
					<FormField
						field={fields.invoice_number}
						controlProps={this.controlProps}
					/>
				</div>
				<div className="col-sm-6">
					<PartnerList
						field={fields.seller_org_id}
						keyName={'seller_org_id'}
						descName={'seller_org_name'}
						selectOption={selectOption}
						controlProps={this.controlProps}
						relationship="S"
					/>
				</div>
				<div className="col-sm-6">
					<FormField
						field={fields.from_date}
						controlProps={this.controlProps}
					/>
				</div>
				<div className="col-sm-6">
					<FormField
						field={fields.to_date}
						controlProps={this.controlProps}
					/>
				</div>
				<div className="col-sm-6">
					<MultipleSelect
						label="Invoice Status:"
						fields={fields}
						options={Object.values(invoice_statuses)}
						controlProps={this.controlProps}
						values={this.state.data}
						fieldName={'statuses'}
					/>
				</div>
			</div>
		);

		const tableclass = 'table table-striped border-dark';
		let datablk = (
			<div className="container-fluid pt-0 m-0 pe-2">
				<div className="col-sm-12">
					<GenericTable
						pageHeading={this.getBreadcrumb()}
						pageHeadingClass="h6 text-left"
						data={this.invoices}
						recordsPerPage={this.recordsPerPage}
						getRow={this.getRow}
						getHeader={this.getHeader}
						tableclass={tableclass}
						getFrame={this.getFrame}
						recordsPerFrame={this.recordsPerFrame}
						pageNumber={this.pageNumber}
						isLoading={this.state.isLoading}
						button={this.table_buttons}
						showFilterCriteria={filterContent}
						modalDialogStyle={{ marginLeft: '15em' }}
						resetFilters={(control) =>
							resetFilters({
								fields,
								setState: this.setState.bind(this),
							})
						}
					/>
				</div>
			</div>
		);

		let frm = (
			<GenericForm
				datablk={datablk}
				buttons={buttons}
				controlProps={this.controlProps}
				{...this.props}
			/>
		);

		return frm;
	};
}

export default ManageReceivedInvoices;
