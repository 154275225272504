import {
	copyFormFieldsToDataFields,
	deepCopy,
	filtered_select,
	filteredselect,
	FormField,
	generic_select,
	generic_string,
	GenericComponent,
	GenericForm,
	genericselect,
	genericstring,
	GenericTable,
	getInitialState,
	input_date,
	inputdate,
	raiseError,
	valuePresent,
} from 'WebUI_Framework';
import React from 'react';
import AddEditDrCrNote from '../debit_credit_notes/AddEditDrCrNote';
import AddEditReceiptNote from './AddEditReceiptNote';
import receiptNoteService from './ReceiptNoteServiceRoutines';
import { debitNoteFromReceiptNote } from './utils';

import PartnerList from '../common/PartnerList';
import { resetFilters } from '../lib/helper';
const fields = {
	order_number: {
		id: 'order_number',
		name: 'order_number',
		label: window.getLabelLiteralWithColon('ORDER_NUMBER'),
		class: genericstring,
		component: generic_string,
		mandatory: false,
	},
	order_issue_date: {
		id: 'order_issue_date',
		name: 'order_issue_date',
		label: window.getLabelLiteralWithColon('ORDER_ISSUE_DATE'),
		class: inputdate,
		component: input_date,
		mandatory: false,
	},
	receipt_note_number: {
		id: 'receipt_note_number',
		name: 'receipt_note_number',
		label: window.getLabelLiteralWithColon('RECEIPT_NUMBER'),
		class: genericstring,
		component: generic_string,
		mandatory: false,
	},
	buyer_org_id: {
		id: 'buyer_org_id',
		name: 'buyer_org_id',
		label: window.getLabelLiteralWithColon('BUYER_ORG'),
		class: filteredselect,
		component: filtered_select,
		mandatory: false,
	},
	seller_org_id: {
		id: 'seller_org_id',
		name: 'seller_org_id',
		label: window.getLabelLiteralWithColon('SELLER_ORG'),
		class: filteredselect,
		component: filtered_select,
		mandatory: false,
	},
	from_date: {
		id: 'from_date',
		name: 'from_date',
		label: window.getLabelLiteralWithColon('DOCUMENT_DATE_FROM'),
		class: inputdate,
		component: input_date,
		mandatory: false,
	},
	to_date: {
		id: 'to_date',
		name: 'to_date',
		label: window.getLabelLiteralWithColon('DOCUMENT_DATE_TO'),
		class: inputdate,
		component: input_date,
		mandatory: false,
	},
	receipt_note_status: {
		id: 'receipt_note_status',
		name: 'receipt_note_status',
		label: window.getLabelLiteralWithColon('RECEIPT_STATUS'),
		class: genericselect,
		component: generic_select,
		mandatory: false,
	},
};

let mapping = [
	['order_number', 'order_number'],
	['order_issue_date', 'order_issue_date'],
	['receipt_note_number', 'receipt_note_number'],
	['buyer_org_id', 'buyer_org_id'],
	['seller_org_id', 'seller_org_id'],
	['from_date', 'from_date'],
	['to_date', 'to_date'],
	['receipt_note_status', 'receipt_note_status'],
];
class ManageReceiptNotes extends GenericComponent {
	constructor(p) {
		super(p);
		this.recordsPerFrame = window.getParam('CURSOR_FRAME_SIZE');
		this.pageNumber = 1;
		//this.recordsPerPage = 7;
		this.recordsPerPage = window.getParam('TABLE_PAGE_SIZE');
		this.subscriberService = this.session.getServerInstance('SUBSCRIBER');
		this.accessToken = this.session.getAccessToken();
		this.getRow = this.getRow.bind(this);
		this.getFrame = this.getFrame.bind(this);

		this.receipt_notes = []; /* Data of main page */
		this.state.isLoading = true; /* Loading animation for table */
		this.state.editCount = 0;
	}

	initialState = getInitialState(fields);

	state = {
		data: deepCopy(this.initialState),
	};

	async getFrame(offset, recordsPerFrame) {
		this.resetError();
		this.setState({ isLoading: true });
		try {
			let params = {
				offset,
				num_recs: recordsPerFrame,
				deleted: 0,
			};

			copyFormFieldsToDataFields(
				mapping,
				this.state.data,
				params,
				fields,
			);

			const retData = await receiptNoteService.list(
				this.subscriberService,
				this.accessToken,
				params,
			);
			if (offset > 1 && retData.length > 0) {
				this.receipt_notes = this.receipt_notes.concat(retData);
			} else {
				this.receipt_notes = retData || [];
			}
		} catch (e) {
			console.log(e);
			this.setError(raiseError(e.error_message));
		}
		this.setState({ isLoading: false });
	}

	onReturn = (ret) => {
		this.resetError();
		if (ret.error) {
			this.setError(ret.error);
			return;
		}
		/*eslint no-fallthrough: 0*/
		switch (ret.retFrom) {
			case 'ADD':
				this.receipt_notes.splice(
					(this.pageNumber - 1) * this.recordsPerPage,
					0,
					ret.selectData.header,
				);
			case 'EDIT':
			case 'CANCEL':
			case 'ACCEPT':
			case 'REJECT':
			case 'AMEND':
			case 'APPROVE':
			case 'VIEW':
				let updatedArrayIndex =
					(this.pageNumber - 1) * this.recordsPerPage +
					ret.arrayIndex;
				this.setState((prevState, props) => {
					this.receipt_notes[updatedArrayIndex] =
						ret.selectData.header;

					let newState = deepCopy(prevState);
					newState.editCount++;
					return newState;
				});
				break;
			case 'BACK':
				break;
			case 'DELETE':
				this.receipt_notes.splice(ret.arrayIndex, 1);
				this.setState({ editCount: this.state.editCount + 1 });
				break;
			default:
				this.setError(
					raiseError(
						'Invalid return from AddEditReceiptNote or AddEditDrCrNote',
					),
				);
		}
		return;
	};

	handler = async (action, receiptNoteId, arrayIndex, pageNumber) => {
		this.pageNumber = pageNumber;
		this.callForm(
			AddEditReceiptNote,
			{ action, receiptNoteId, arrayIndex, pageNumber, ...this.props },
			this.onReturn,
		);
	};

	drNoteReturn = (ret) => {
		this.resetError();
		if (ret.error) {
			this.setError(ret.error);
			return;
		}
		switch (ret.retFrom) {
			case 'INIT_DR_NOTE':
				let updatedArrayIndex =
					(this.pageNumber - 1) * this.recordsPerPage +
					ret.arrayIndex;
				this.setState((prevState, props) => {
					this.receipt_notes[updatedArrayIndex].dr_note_number =
						ret.selectData.header.document_number;
					this.receipt_notes[updatedArrayIndex].dr_note_date =
						ret.selectData.header.document_date;

					prevState.editCount++;
					return prevState;
				});
				break;
			case 'BACK':
			case 'VIEWWITHNUMBER':
				break;
			default:
				this.setError(
					raiseError('Invalid return from AddEditDrCrNote'),
				);
		}
	};

	drNoteHandler = async (
		receiptNoteId,
		arrayIndex,
		pageNumber,
		documentNumber,
		documentDate,
		drOrgId,
	) => {
		if (valuePresent(documentNumber)) {
			const ownerOrgId = this.session.getLoggedInCompanyId();
			this.callForm(
				AddEditDrCrNote,
				{
					action: 'VIEWWITHNUMBER',
					receiptNoteId,
					arrayIndex,
					pageNumber,
					type: 'D',
					documentNumber,
					documentDate,
					ownerOrgId,
					...this.props,
				},
				this.drNoteReturn,
			);
		} else {
			try {
				const drNote = await debitNoteFromReceiptNote(
					this.session,
					receiptNoteId,
				);

				this.callForm(
					AddEditDrCrNote,
					{
						action: 'INIT_DR_NOTE',
						receiptNoteId,
						arrayIndex,
						pageNumber,
						drNote,
						type: 'D',
						...this.props,
					},
					this.drNoteReturn,
				);
			} catch (e) {
				console.log(e);
				this.setError(raiseError(e.error_message));
			}
		}
	};

	pageChange = (pageNumber) => {
		this.pageNumber = pageNumber;
	};

	getRow = (row, index, pageNumber, i) => {
		let status = window.getGenericRefCode('receipt_note_statuses');
		let stat;
		if (row.entity_state === '0')
			stat = window.getLabelLiteral('STATUS_DRAFT');
		else if (row.entity_state === '2')
			stat = window.getLabelLiteral('STATUS_BEING_MODIFIED');
		else {
			console.log('status', status);
			if (status && status[row.receipt_note_status]) {
				stat = status[row.receipt_note_status];
			} else {
				this.setError(
					raiseError(
						'Invalid status code [' + row.receipt_note_status + ']',
					),
				);
				stat = 'ERROR';
			}
		}

		let tableRow = (
			<tr key={i}>
				<td>{row.receipt_note_date}</td>
				<td>
					{' '}
					<button
						type="button"
						style={{
							color: 'blue',
							textDecoration: 'underline',
							cursor: 'pointer',
							border: 'none',
							background: 'none',
							padding: 0,
						}}
						onClick={() =>
							this.handler(
								'VIEW',
								row.receipt_note_id,
								i,
								pageNumber,
							)
						}
					>
						{row.receipt_note_number}
					</button>
				</td>
				<td>{row.seller_org_name}</td>
				<td>
					{
						window.getGenericRefCode('deliveryDocumentTypes')[
							row.delivery_document_type
						]
					}
				</td>
				{(row.delivery_document_type === '1' ||
					row.delivery_document_type === '2') && (
					<>
						<td>{row.delivery_note_date}</td>
						<td>{row.delivery_note_number}</td>
					</>
				)}
				{row.delivery_document_type === '3' && (
					<>
						<td>{row.invoice_date}</td>
						<td>{row.invoice_number}</td>
					</>
				)}
				{(!valuePresent(row.delivery_document_type) ||
					row.delivery_document_type === '') && (
					<>
						<td />
						<td />
					</>
				)}
				<td>{row.description}</td>
				<td>{stat}</td>
				<td>
					{row.deleted !== true && row.entity_state === '0' && (
						<>
							<span
								onClick={() =>
									this.handler(
										'APPROVE',
										row.receipt_note_id,
										i,
										pageNumber,
									)
								}
								title={window.getLabelLiteral('APPROVE')}
								style={{ cursor: 'pointer' }}
							>
								<i
									className="bi bi-check2-square"
									style={{ fontSize: 20 }}
								></i>
							</span>
							&nbsp;&nbsp;
							<span
								onClick={() =>
									this.handler(
										'EDIT',
										row.receipt_note_id,
										i,
										pageNumber,
									)
								}
								title={window.getLabelLiteral('EDIT')}
								style={{ cursor: 'pointer' }}
							>
								<i
									className="bi bi-pencil-square"
									style={{ fontSize: 20 }}
								></i>
							</span>
							&nbsp;&nbsp;
							<span
								onClick={() =>
									this.handler(
										'DELETE',
										row.receipt_note_id,
										i,
										pageNumber,
									)
								}
								title={window.getLabelLiteral('DELETE')}
								style={{ cursor: 'pointer' }}
							>
								<i
									className="bi bi-trash3"
									style={{ fontSize: 20 }}
								></i>
							</span>
							&nbsp;&nbsp;
						</>
					)}
					{row.deleted !== true &&
						row.entity_state !== '0' &&
						row.receipt_note_status !== '5' &&
						row.delivery_document_type !== '3' &&
						this.session.getLoggedInCompanyId() ===
							row.owner_org_id && (
							<>
								<span
									onClick={() =>
										this.handler(
											'AMEND',
											row.receipt_note_id,
											i,
											pageNumber,
										)
									}
									title={window.getLabelLiteral('AMEND')}
									style={{ cursor: 'pointer' }}
								>
									<i
										className="bi bi-pen"
										style={{ fontSize: 20 }}
									></i>
								</span>
								&nbsp;&nbsp;
							</>
						)}
					{row.deleted !== true &&
						row.entity_state === '2' &&
						row.receipt_note_status === '3' && (
							<>
								<span
									onClick={() =>
										this.handler(
											'CANCEL',
											row.receipt_note_id,
											i,
											pageNumber,
										)
									}
									title={window.getLabelLiteral('CANCEL')}
									style={{ cursor: 'pointer' }}
								>
									<i
										className="bi bi-x-square"
										style={{ fontSize: 20 }}
									></i>
								</span>
								&nbsp;&nbsp;
							</>
						)}
					{row.deleted !== true &&
						row.entity_state !== '0' &&
						row.receipt_note_status === '3' && (
							<>
								<span
									onClick={() =>
										this.handler(
											'ACCEPT',
											row.receipt_note_id,
											i,
											pageNumber,
										)
									}
									title={window.getLabelLiteral('ACCEPT')}
									style={{ cursor: 'pointer' }}
								>
									<i
										className="bi bi-hand-thumbs-up"
										style={{ fontSize: 20 }}
									></i>
								</span>
								&nbsp;&nbsp;
								<span
									onClick={() =>
										this.handler(
											'REJECT',
											row.receipt_note_id,
											i,
											pageNumber,
										)
									}
									title={window.getLabelLiteral('REJECT')}
									style={{ cursor: 'pointer' }}
								>
									<i
										className="bi bi-hand-thumbs-down"
										style={{ fontSize: 20 }}
									></i>
								</span>
								&nbsp;&nbsp;
							</>
						)}
					{row.deleted !== true &&
						row.entity_state === '2' &&
						row.receipt_note_status === '4' && (
							<>
								<span
									onClick={() =>
										this.handler(
											'CANCEL',
											row.receipt_note_id,
											i,
											pageNumber,
										)
									}
									title={window.getLabelLiteral('CANCEL')}
									style={{ cursor: 'pointer' }}
								>
									<i
										className="bi bi-x-square"
										style={{ fontSize: 20 }}
									></i>
								</span>
								&nbsp;&nbsp;
								<span
									onClick={() =>
										this.handler(
											'ACCEPT',
											row.receipt_note_id,
											i,
											pageNumber,
										)
									}
									title={window.getLabelLiteral('ACCEPT')}
									style={{ cursor: 'pointer' }}
								>
									<i
										className="bi bi-hand-thumbs-up"
										style={{ fontSize: 20 }}
									></i>
								</span>
								&nbsp;&nbsp;
							</>
						)}
					{row.deleted !== true &&
						row.entity_state === '1' &&
						valuePresent(row.invoice_number) &&
						row.receipt_note_status === '4' && (
							<>
								<span
									onClick={() =>
										this.drNoteHandler(
											row.receipt_note_id,
											i,
											pageNumber,
											row.dr_note_number,
											row.dr_note_date,
											row.seller_org_id,
										)
									}
									title={window.getLabelLiteral(
										'INIT_DR_NOTE',
									)}
									style={{ cursor: 'pointer' }}
								>
									<i
										className="bi bi-journal-minus"
										style={{ fontSize: 20 }}
									></i>
								</span>
								&nbsp;&nbsp;
							</>
						)}
				</td>
			</tr>
		);

		return tableRow;
	};

	getHeader() {
		let key = 0;
		return (
			<>
				<tr key={++key}>
					<th key={++key}>{window.getLabelLiteral('DATE')}</th>
					<th key={++key}>
						{window.getLabelLiteral('RECEIPT_NOTE_NUMBER')}
					</th>
					<th key={++key}>
						{window.getLabelLiteral('SELLER_COMPANY_NAME')}
					</th>
					<th key={++key}>
						{window.getLabelLiteral('DELIVERY_DOCUMENT_TYPE')}
					</th>
					<th key={++key}>
						{window.getLabelLiteral('DOCUMENT_DATE')}
					</th>
					<th key={++key}>
						{window.getLabelLiteral('DOCUMENT_NUMBER')}
					</th>
					<th key={++key}>{window.getLabelLiteral('DESCRIPTION')}</th>
					<th key={++key}>
						{window.getLabelLiteral('STATUS_TITLE')}
					</th>
				</tr>
			</>
		);
	}

	componentDidMount() {
		this.getFrame(1, this.recordsPerFrame);
	}

	buttons = [
		{
			literal: 'Add',
			func: () => {
				const ucp = this.session.getUserCompanyProps(
					this.session.getLoggedInCompanyId(),
					this.session.getLoggedInParentCompanyId(),
				);
				if (!ucp.org_is_subscriber) {
					if (ucp.partner_relationship_type === 'S') {
						this.setError(
							raiseError(
								window.parseLiteral(
									'CANNOT_CREATE_RELEASE_NOTE',
									this.session.getLoggedInCompanyName(),
								),
							),
						);
						return false;
					}
				}
				this.handler('ADD', null, 0, this.pageNumber);
			},
		},
	];

	render() {
		const selectOption = (org_id, org_name, key) => {
			this.state.data[key] = org_id;
			this.setState({ data: this.state.data });
		};
		let filterContent = (
			<div className="row">
				<div className="col-sm-6">
					<FormField
						field={fields.order_number}
						controlProps={this.controlProps}
					/>
				</div>

				<div className="col-sm-6">
					<FormField
						field={fields.order_issue_date}
						controlProps={this.controlProps}
					/>
				</div>
				<div className="col-sm-6">
					<FormField
						field={fields.receipt_note_number}
						controlProps={this.controlProps}
					/>
				</div>

				<div className="col-sm-6">
					<PartnerList
						field={fields.buyer_org_id}
						keyName={'buyer_org_id'}
						descName={'buyer_org_name'}
						selectOption={(org_id, org_name) =>
							selectOption(org_id, org_name, 'buyer_org_id')
						}
						controlProps={this.controlProps}
						relationship="D"
					/>
				</div>
				<div className="col-sm-6">
					<PartnerList
						field={fields.seller_org_id}
						keyName={'seller_org_id'}
						descName={'seller_org_name'}
						selectOption={(org_id, org_name) =>
							selectOption(org_id, org_name, 'seller_org_id')
						}
						controlProps={this.controlProps}
						relationship="S"
					/>
				</div>
				<div className="col-sm-6">
					<FormField
						field={fields.receipt_note_status}
						controlProps={this.controlProps}
						refType={'receipt_note_statuses'}
					/>
				</div>
				<div className="col-sm-6">
					<FormField
						field={fields.from_date}
						controlProps={this.controlProps}
					/>
				</div>
				<div className="col-sm-6">
					<FormField
						field={fields.to_date}
						controlProps={this.controlProps}
					/>
				</div>
			</div>
		);
		let datablk = (
			<div className="container-fluid pt-0 m-0 pe-2">
				<div className="col-sm-12">
					<GenericTable
						pageHeading={this.getBreadcrumb()}
						pageHeadingClass="h6 text-left"
						data={this.receipt_notes}
						recordsPerPage={this.recordsPerPage}
						getRow={this.getRow}
						getHeader={this.getHeader}
						tableclass="table table-striped border-dark"
						getFrame={this.getFrame}
						pageChange={this.pageChange}
						recordsPerFrame={this.recordsPerFrame}
						button={this.buttons}
						pageNumber={this.pageNumber}
						isLoading={this.state.isLoading}
						showFilterCriteria={filterContent}
						modalDialogStyle={{ marginLeft: '15em' }}
						resetFilters={(control) =>
							resetFilters({
								fields,
								setState: this.setState.bind(this),
							})
						}
					/>
				</div>
			</div>
		);

		let frm = (
			<GenericForm
				datablk={datablk}
				buttons={[]}
				controlProps={this.controlProps}
				{...this.props}
			/>
		);
		return frm;
	}
}

export default ManageReceiptNotes;
