import React from 'react';
import {
	copyFormFieldsToDataFields,
	deepCopy,
	filtered_select,
	filteredselect,
	FormField,
	generic_string,
	GenericComponent,
	GenericForm,
	genericstring,
	GenericTable,
	getInitialState,
	input_date,
	inputdate,
	raiseError,
	valuePresent,
} from 'WebUI_Framework';

import PartnerList from '../common/PartnerList';
import { orderFromQuotation } from '../common/utils';
import { resetFilters } from '../lib/helper';
import {
	default as AddEditPurchaseOrder,
	default as ViewOrder,
} from '../orders/AddEditPurchaseOrder';
import ViewSentRFQ from '../rfq/AddEditRFQ';
import rfqServiceroutines from '../rfq/rfqServiceRoutines';
import AddEditReceivedQuotation from './AddEditReceivedQuotation';
import receivedQuotationServiceRoutines from './receivedQuotationServiceRoutines';

const fields = {
	quotation_number: {
		id: 'quotation_number',
		name: 'quotation_number',
		label: window.getLabelLiteralWithColon('QUOTATION_NUMBER'),
		class: genericstring,
		component: generic_string,
		mandatory: false,
	},
	rfq_number: {
		id: 'rfq_number',
		name: 'rfq_number',
		label: window.getLabelLiteralWithColon('RFQ_NUMBER'),
		class: genericstring,
		component: generic_string,
		mandatory: false,
	},
	rfq_issue_date: {
		id: 'rfq_issue_date',
		name: 'rfq_issue_date',
		label: window.getLabelLiteralWithColon('RFQ_DATE'),
		class: inputdate,
		component: input_date,
		mandatory: false,
	},
	seller_org_id: {
		id: 'seller_org_id',
		name: 'seller_org_id',
		label: window.getLabelLiteralWithColon('SELLER_ORG'),
		class: filteredselect,
		component: filtered_select,
		mandatory: false,
	},
	from_date: {
		id: 'from_date',
		name: 'from_date',
		label: window.getLabelLiteralWithColon('QUOTATION_DATE_FROM'),
		class: inputdate,
		component: input_date,
		mandatory: false,
	},
	to_date: {
		id: 'to_date',
		name: 'to_date',
		label: window.getLabelLiteralWithColon('QUOTATION_DATE_TO'),
		class: inputdate,
		component: input_date,
		mandatory: false,
	},
};

let mapping = [
	['seller_org_id', 'seller_org_id'],
	['quotation_number', 'quotation_number'],
	['rfq_issue_date', 'rfq_issue_date'],
	['rfq_number', 'rfq_number'],
	['from_date', 'from_date'],
	['to_date', 'to_date'],
];
class ManageReceivedQuotations extends GenericComponent {
	constructor(p) {
		super(p);
		this.recordsPerFrame = window.getParam('CURSOR_FRAME_SIZE');
		this.pageNumber = 1;
		//this.recordsPerPage = 15;
		this.recordsPerPage = window.getParam('TABLE_PAGE_SIZE');
		this.subscriberServer = this.session.getServerInstance('SUBSCRIBER');
		this.accessToken = this.session.getAccessToken();
		this.receivedQuotations = [];
	}

	initialState = getInitialState(fields);

	state = {
		data: deepCopy(this.initialState),
	};

	componentDidMount() {
		this.getFrame(1, this.recordsPerFrame);
	}

	getFrame = async (offset, recordsPerFrame) => {
		this.resetError();
		try {
			let params = {
				offset,
				num_recs: recordsPerFrame,
				buyer_org_id: this.session.getLoggedInCompanyId(),
			};

			copyFormFieldsToDataFields(
				mapping,
				this.state.data,
				params,
				fields,
			);

			let retData = await receivedQuotationServiceRoutines.list(
				this.session,
				params,
			);
			if (offset > 1 && retData.length > 0) {
				this.receivedQuotations =
					this.receivedQuotations.concat(retData);
			} else {
				this.receivedQuotations = retData || [];
			}
		} catch (e) {
			this.setError(raiseError(e.error_message));
		}
		this.setState({ isLoading: false });
	};

	onReturnFromOrder = (ret) => {
		this.resetError();
		if (ret.error) {
			this.setError(ret.error);
			return;
		}
		switch (ret.retFrom) {
			/*eslint no-fallthrough: 0*/
			case 'ACCEPTQUOTATION':
			case 'EDITORDER':
				let updatedArrayIndex =
					(this.pageNumber - 1) * this.recordsPerPage +
					ret.arrayIndex;
				this.setState((prevState, props) => {
					const recOrderHeader = ret.selectData.header;
					this.receivedQuotations[updatedArrayIndex].order_state =
						recOrderHeader.entity_state;
					this.receivedQuotations[updatedArrayIndex].order_id =
						recOrderHeader.order_id;
					this.receivedQuotations[
						updatedArrayIndex
					].order_issue_date = recOrderHeader.order_issue_date;
					this.receivedQuotations[updatedArrayIndex].order_number =
						recOrderHeader.order_number;

					let newState = deepCopy(prevState);
					newState.editCount++;
					return newState;
				});
			case 'BACK':
				break;
			default:
				this.setError(
					raiseError('Invalid return from AddEditPurchaseOrder'),
				);
		}
		return;
	};

	onReturn = (ret) => {
		this.resetError();
		if (ret.error) {
			this.setError(ret.error);
			return;
		}
		/*eslint no-fallthrough: 0*/
		switch (ret.retFrom) {
			case 'REJECT':
				let updatedArrayIndex =
					(this.pageNumber - 1) * this.recordsPerPage +
					ret.arrayIndex;
				this.setState((prevState, props) => {
					const recQuotHeader = ret.selectData.header;
					this.receivedQuotations[updatedArrayIndex].status =
						recQuotHeader.status;

					let newState = deepCopy(prevState);
					newState.editCount++;
					return newState;
				});
				break;
			case 'VIEW':
			case 'VIEWRFQ':
			case 'BACK':
				break;
			default:
				this.setError(
					raiseError('Invalid return from AddEditReceivedQuotation'),
				);
		}
		return;
	};

	order_handler = async (orderId, pageNumber, arrayIndex) => {
		this.pageNumber = pageNumber;
		const action = 'VIEWORDER';
		this.callForm(
			ViewOrder,
			{ action, orderId, arrayIndex, pageNumber, ...this.props },
			this.onReturn,
		);
	};

	rfq_handler = async (
		action,
		rfqNumber,
		rfqIssueDate,
		buyerOrgId,
		pageNumber,
		arrayIndex,
	) => {
		this.pageNumber = pageNumber;
		this.callForm(
			ViewSentRFQ,
			{
				action,
				rfqNumber,
				rfqIssueDate,
				buyerOrgId,
				arrayIndex,
				pageNumber,
				...this.props,
			},
			this.onReturn,
		);
	};

	quotation_handler = async (
		action,
		quotationId,
		pageNumber,
		arrayIndex,
		qArrayIndex,
	) => {
		this.pageNumber = pageNumber;
		this.callForm(
			AddEditReceivedQuotation,
			{
				action,
				quotationId,
				arrayIndex,
				qArrayIndex,
				pageNumber,
				...this.props,
			},
			this.onReturn,
		);
	};

	handle_accept = async (
		rfqIssueDate,
		rfqNumber,
		quotationId,
		orderId,
		pageNumber,
		arrayIndex,
		qArrayIndex,
	) => {
		let quotation = await receivedQuotationServiceRoutines.fetch(
			this.session,
			quotationId,
		);
		let rfq = await rfqServiceroutines.fetchUsingNumberAndDate(
			this.session,
			rfqNumber,
			rfqIssueDate,
			this.session.getLoggedInCompanyId(),
		);
		let order;
		const action = !valuePresent(orderId) ? 'ACCEPTQUOTATION' : 'EDITORDER';
		if (action === 'ACCEPTQUOTATION') {
			quotation = await receivedQuotationServiceRoutines.fetch(
				this.session,
				quotationId,
			);
			rfq = await rfqServiceroutines.fetchUsingNumberAndDate(
				this.session,
				rfqNumber,
				rfqIssueDate,
				this.session.getLoggedInCompanyId(),
			);
			order = orderFromQuotation(quotation, rfq);
		}
		this.pageNumber = pageNumber;
		this.callForm(
			AddEditPurchaseOrder,
			{
				action,
				order,
				orderId,
				arrayIndex,
				qArrayIndex,
				pageNumber,
				...this.props,
			},
			this.onReturnFromOrder,
		);
	};

	pageChange = (pageNumber) => {
		this.pageNumber = pageNumber;
	};

	getHeader() {
		let key = 0;
		return (
			<tr key={++key}>
				<th style={{ width: '8%' }} key={++key}>
					Seller
				</th>
				<th style={{}} key={++key}>
					RFQ Date
				</th>
				<th style={{}} key={++key}>
					RFQ Number.
				</th>
				<th style={{}} key={++key}>
					Quotation Date
				</th>
				<th style={{}} key={++key}>
					Quotation Number
				</th>
				<th style={{ width: '8%' }} key={++key}>
					Description
				</th>
				<th style={{}} key={++key}>
					Valid From
				</th>
				<th style={{}} key={++key}>
					Valid Till
				</th>
				<th style={{}} key={++key}>
					Order Date
				</th>
				<th style={{}} key={++key}>
					Order Number
				</th>
			</tr>
		);
	}

	getRow = (data, index, pageNumber, arrayIndex) => {
		this.pageNumber = pageNumber;

		let key = 0;
		return (
			<React.Fragment key={index}>
				<tr key={key++}>
					<td>{data.seller_org_name}</td>
					<td>{data.rfq_issue_date}</td>
					<td>
						<button
							type="button"
							style={{
								color: 'blue',
								textDecoration: 'underline',
								cursor: 'pointer',
								border: 'none',
								background: 'none',
								padding: 0,
							}}
							onClick={() =>
								this.rfq_handler(
									'VIEWRFQ',
									data.rfq_number,
									data.rfq_issue_date,
									data.buyer_org_id,
									pageNumber,
									arrayIndex,
								)
							}
						>
							{data.rfq_number}
						</button>
					</td>
					<td>{data.quotation_issue_date}</td>
					<td>
						<button
							type="button"
							style={{
								color: 'blue',
								textDecoration: 'underline',
								cursor: 'pointer',
								border: 'none',
								background: 'none',
								padding: 0,
							}}
							onClick={() =>
								this.quotation_handler(
									'VIEW',
									data.quotation_id,
									pageNumber,
									arrayIndex,
								)
							}
						>
							{data.quotation_number}
						</button>
					</td>
					<td>{data.description}</td>
					<td>{data.quotation_valid_from_date}</td>
					<td>{data.quotation_valid_to_date}</td>
					<td>{data.order_issue_date}</td>
					<td>
						<button
							type="button"
							style={{
								color: 'blue',
								textDecoration: 'underline',
								cursor: 'pointer',
								border: 'none',
								background: 'none',
								padding: 0,
							}}
							onClick={() =>
								this.order_handler(
									data.order_id,
									pageNumber,
									arrayIndex,
								)
							}
						>
							{data.order_number}
						</button>
					</td>
					{data.status === '1' && (
						<td>
							{(!valuePresent(data.order_state) ||
								data.order_state === '0') && (
								<span
									onClick={() =>
										this.handle_accept(
											data.rfq_issue_date,
											data.rfq_number,
											data.quotation_id,
											data.order_id,
											pageNumber,
											arrayIndex,
										)
									}
									title="Edit"
									style={{ cursor: 'pointer' }}
								>
									<i className="bi bi-hand-thumbs-up"></i>
								</span>
							)}
							{!valuePresent(data.order_id) && (
								<>
									&nbsp;&nbsp;
									<span
										onClick={() =>
											this.quotation_handler(
												'REJECT',
												data.quotation_id,
												pageNumber,
												arrayIndex,
											)
										}
										title="Edit"
										style={{ cursor: 'pointer' }}
									>
										<i className="bi bi-hand-thumbs-down"></i>
									</span>
								</>
							)}
						</td>
					)}
					{data.status !== '1' && <td></td>}
				</tr>
			</React.Fragment>
		);
	};

	render = () => {
		const selectOption = (org_id, org_name) => {
			this.state.data.seller_org_id = org_id;
			this.setState({ data: this.state.data });
		};
		let filterContent = (
			<div className="row">
				<div className="col-sm-6">
					<FormField
						field={fields.rfq_number}
						controlProps={this.controlProps}
					/>
				</div>
				<div className="col-sm-6">
					<FormField
						field={fields.rfq_issue_date}
						controlProps={this.controlProps}
					/>
				</div>

				<div className="col-sm-6">
					<FormField
						field={fields.quotation_number}
						controlProps={this.controlProps}
					/>
				</div>
				<div className="col-sm-6">
					<PartnerList
						field={fields.seller_org_id}
						keyName={'seller_org_id'}
						descName={'seller_org_name'}
						selectOption={selectOption}
						controlProps={this.controlProps}
						relationship="S"
					/>
				</div>
				<div className="col-sm-6">
					<FormField
						field={fields.from_date}
						controlProps={this.controlProps}
					/>
				</div>
				<div className="col-sm-6">
					<FormField
						field={fields.to_date}
						controlProps={this.controlProps}
					/>
				</div>
			</div>
		);
		const tableclass = 'table table-striped border-dark';
		let datablk = (
			<div className="container-fluid pt-0 m-0 ">
				<div className="col-sm-12">
					<GenericTable
						pageHeading={this.getBreadcrumb()}
						pageHeadingClass="h6 text-left"
						data={this.receivedQuotations}
						recordsPerPage={this.recordsPerPage}
						getRow={this.getRow}
						getHeader={this.getHeader}
						tableclass={tableclass}
						getFrame={this.getFrame}
						recordsPerFrame={this.recordsPerFrame}
						pageNumber={this.pageNumber}
						isLoading={this.state.isLoading}
						pageChange={this.pageChange}
						showFilterCriteria={filterContent}
						modalDialogStyle={{ marginLeft: '15em' }}
						resetFilters={(control) =>
							resetFilters({
								fields,
								setState: this.setState.bind(this),
							})
						}
					/>
				</div>
			</div>
		);

		let frm = (
			<GenericForm
				datablk={datablk}
				buttons={[]}
				controlProps={this.controlProps}
				{...this.props}
			/>
		);
		return frm;
	};
}

export default ManageReceivedQuotations;
