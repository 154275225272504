import {
	copyFormFieldsToDataFields,
	deepCopy,
	filtered_select,
	filteredselect,
	formatAmount,
	FormField,
	generic_checkbox,
	generic_string,
	genericcheckbox,
	//GenericComponent
	GenericForm,
	genericstring,
	GenericTable,
	getInitialState,
	input_date,
	inputdate,
	raiseError,
	valuePresent,
} from 'WebUI_Framework';
import React from 'react';
import { BaseComponent } from '../common/BaseComponent';
import MultipleSelect from '../common/MultipleSelect';
import PartnerList from '../common/PartnerList';
import { invoice_statuses } from '../lib/constants';
import { resetFilters } from '../lib/helper';
import AddEditIssuedInvoice from './AddEditIssuedInvoice';
import issuedInvoiceServiceRoutines from './IssuedInvoiceServiceRoutines';

const fields = {
	invoice_number: {
		id: 'invoice_number',
		name: 'invoice_number',
		label: window.getLabelLiteralWithColon('INVOICE_NUMBER'),
		class: genericstring,
		component: generic_string,
		mandatory: false,
	},
	buyer_org_id: {
		id: 'buyer_org_id',
		name: 'buyer_org_id',
		label: window.getLabelLiteralWithColon('BUYER_ORG'),
		class: filteredselect,
		component: filtered_select,
		mandatory: false,
	},
	from_date: {
		id: 'from_date',
		name: 'from_date',
		label: window.getLabelLiteralWithColon('INVOICE_DATE_FROM'),
		class: inputdate,
		component: input_date,
		mandatory: false,
	},
	to_date: {
		id: 'to_date',
		name: 'to_date',
		label: window.getLabelLiteralWithColon('INVOICE_DATE_TO'),
		class: inputdate,
		component: input_date,
		mandatory: false,
	},
	statuses: {
		id: 'statuses',
		name: 'statuses',
		label: window.getLabelLiteralWithColon('STATUS_TITLE'),
		class: genericstring,
		component: generic_string,
		mandatory: false,
	},
	status_draft: {
		id: 'status_draft',
		name: 'status_draft',
		label: window.getLabelLiteral('STATUS_DRAFT'),
		class: genericcheckbox,
		component: generic_checkbox,
		mandatory: false,
	},
	status_delted: {
		id: 'status_delted',
		name: 'status_delted',
		label: window.getLabelLiteral('STATUS_DELTED'),
		class: genericcheckbox,
		component: generic_checkbox,
		mandatory: false,
	},
	status_sent: {
		id: 'status_sent',
		name: 'status_sent',
		label: window.getLabelLiteral('STATUS_SENT'),
		class: genericcheckbox,
		component: generic_checkbox,
		mandatory: false,
	},
	status_received: {
		id: 'status_received',
		name: 'status_received',
		label: window.getLabelLiteral('STATUS_RECEIVED'),
		class: genericcheckbox,
		component: generic_checkbox,
		mandatory: false,
	},
	status_acknowledged: {
		id: 'status_acknowledged',
		name: 'status_acknowledged',
		label: window.getLabelLiteral('STATUS_ACKNOWLEDGED'),
		class: genericcheckbox,
		component: generic_checkbox,
		mandatory: false,
	},
	status_accepted: {
		id: 'status_accepted',
		name: 'status_accepted',
		label: window.getLabelLiteral('STATUS_ACCEPTED'),
		class: genericcheckbox,
		component: generic_checkbox,
		mandatory: false,
	},
	status_rejected: {
		id: 'status_rejected',
		name: 'status_rejected',
		label: window.getLabelLiteral('STATUS_REJECTED'),
		class: genericcheckbox,
		component: generic_checkbox,
		mandatory: false,
	},
	status_payment_done: {
		id: 'status_payment_done',
		name: 'status_payment_done',
		label: window.getLabelLiteral('STATUS_PAYMENT_DONE'),
		class: genericcheckbox,
		component: generic_checkbox,
		mandatory: false,
	},
};

let mapping = [
	['invoice_number', 'invoice_number'],
	['invoice_date', 'invoice_date'],
	['to_date', 'to_date'],
	['buyer_org_id', 'buyer_org_id'],
	['statuses', 'statuses'],
];
class ManageIssuedInvoices extends BaseComponent {
	constructor(p) {
		super(p);
		this.recordsPerFrame = window.getParam('CURSOR_FRAME_SIZE');
		this.pageNumber = 1;
		this.max_invoice_serial_num = 0;
		//this.recordsPerPage = 15;
		this.recordsPerPage = window.getParam('TABLE_PAGE_SIZE');
		this.subscriberServer = this.session.getServerInstance('SUBSCRIBER');
		this.accessToken = this.session.getAccessToken();
		this.invoices = [];
	}

	initialState = getInitialState(fields);

	state = {
		data: deepCopy(this.initialState),
	};

	componentDidMount() {
		this.getFrame(1, this.recordsPerFrame);
	}

	getFrame = async (offset, recordsPerFrame) => {
		this.resetError();
		try {
			let payload = {
				offset,
				num_recs: recordsPerFrame,
				seller_org_id: this.session.getLoggedInCompanyId(),
			};

			copyFormFieldsToDataFields(
				mapping,
				this.state.data,
				payload,
				fields,
			);

			if (payload.statuses?.length <= 0) {
				payload.statuses = undefined;
			}
			let retData = await issuedInvoiceServiceRoutines.list(
				this.session,
				payload,
			);

			if (offset > 1 && retData.length > 0) {
				this.invoices = this.invoices.concat(retData);
			} else {
				this.invoices = retData;
			}
		} catch (e) {
			this.setError(raiseError(e.error_message));
		}
		this.setState({ isLoading: false });
	};

	onReturn = (ret) => {
		this.resetError();
		if (ret.error) {
			this.setError(ret.error);
			return;
		}
		/*eslint no-fallthrough: 0*/
		switch (ret.retFrom) {
			case 'ADD':
				this.invoices.splice(
					(this.pageNumber - 1) * this.recordsPerPage,
					0,
					ret.selectData.header,
				);
				break;
			case 'EDIT':
			case 'CANCEL':
			case 'ACCEPT':
			case 'REJECT':
			case 'AMEND':
			case 'APPROVE':
			case 'APPROVEAMENDMENT':
			case 'VIEW':
				let updatedArrayIndex =
					(this.pageNumber - 1) * this.recordsPerPage +
					ret.arrayIndex;
				this.setState((prevState, props) => {
					this.invoices[updatedArrayIndex] = ret.selectData.header;
					if (valuePresent(ret.referralId)) {
						this.invoices[updatedArrayIndex].referral_id =
							ret.referralId;
					}

					let newState = deepCopy(prevState);
					newState.editCount++;
					return newState;
				});
				break;
			case 'BACK':
				break;
			case 'DELETE':
				this.invoices.splice(ret.arrayIndex, 1);
				this.setState({ editCount: this.state.editCount + 1 });
				break;
			default:
				this.setError(
					raiseError('Invalid return from AddEditIssuedInvoice'),
				);
		}
		return;
	};

	handler = async (action, invoiceId, pageNumber, arrayIndex) => {
		this.pageNumber = pageNumber;
		this.callForm(
			AddEditIssuedInvoice,
			{ action, invoiceId, arrayIndex, pageNumber, ...this.props },
			this.onReturn,
		);
	};

	table_buttons = [
		{
			literal: 'Add',
			func: () => {
				const ucp = this.session.getUserCompanyProps(
					this.session.getLoggedInCompanyId(),
					this.session.getLoggedInParentCompanyId(),
				);
				if (!ucp.org_is_subscriber) {
					if (ucp.partner_relationship_type === 'D') {
						this.setError(
							raiseError(
								window.parseLiteral(
									'CANNOT_CREATE_INVOICE',
									this.session.getLoggedInCompanyName(),
								),
							),
						);
						return false;
					}
				}
				this.handler('ADD', null, this.pageNumber, 0);
			},
		},
	];

	pageChange = (pageNumber) => {
		this.pageNumber = pageNumber;
	};

	getRow = (data, index, pageNumber, arrayIndex) => {
		const i = index - 1;

		this.pageNumber = pageNumber;
		let status_string = 'Unknown';
		if (data?.referral_id) {
			status_string = window.getLabelLiteral('PENDING_APPROVAL');
		} else if (data?.status) {
			status_string = invoice_statuses[data.status].label;
		}
		return (
			<tr key={index}>
				<td>
					<button
						type="button"
						style={{
							color: 'blue',
							textDecoration: 'underline',
							cursor: 'pointer',
							border: 'none',
							background: 'none',
							padding: 0,
							margin: 0,
							textAlign: 'left',
						}}
						onClick={() =>
							this.handler(
								'VIEW',
								data.invoice_id,
								pageNumber,
								arrayIndex,
							)
						}
					>
						{data.invoice_number}
					</button>
				</td>
				<td>{data.invoice_date}</td>
				<td>{data.buyer_org_name}</td>
				<td>{data.payment_due_date}</td>
				<td>{data.buyer_contact_email_id}</td>
				<td style={{ textAlign: 'right' }}>
					{formatAmount(data.total_amount, data.currency)}
				</td>
				<td>
					<i>{status_string}</i>
				</td>
				<td>
					{data.entity_state === '0' &&
						data.deleted === false &&
						!valuePresent(data.referral_id) && (
							<>
								<span
									onClick={() =>
										this.handler(
											'EDIT',
											data.invoice_id,
											pageNumber,
											arrayIndex,
										)
									}
									title="Edit"
									style={{ cursor: 'pointer' }}
								>
									<i
										className="bi bi-pencil-square"
										style={{ fontSize: '20' }}
									></i>
								</span>
								&nbsp;&nbsp;
								<span
									onClick={() =>
										this.handler(
											'DELETE',
											data.invoice_id,
											pageNumber,
											arrayIndex,
										)
									}
									title="Delete"
									style={{ cursor: 'pointer' }}
								>
									<i
										className="bi bi-trash3"
										style={{ fontSize: '20' }}
									></i>
								</span>
								&nbsp;&nbsp;
								<span
									onClick={() =>
										this.handler(
											'APPROVE',
											data.invoice_id,
											pageNumber,
											arrayIndex,
										)
									}
									title="Approve"
									style={{ cursor: 'pointer' }}
								>
									<i
										className="bi bi-check2"
										style={{ fontSize: '20' }}
									></i>
								</span>
								&nbsp;&nbsp;
							</>
						)}
					{data.entity_state === '0' &&
						data.deleted === false &&
						valuePresent(data.referral_id) && (
							<>
								<span
									onClick={() =>
										this.withdrawReferral(
											data,
											i,
											'referral_id',
										)
									}
									title="Withdraw"
									style={{ cursor: 'pointer' }}
								>
									<i
										className="bi bi-skip-backward"
										style={{ fontSize: '20' }}
									></i>
								</span>
								&nbsp;&nbsp;
							</>
						)}
					{data.status === '6' && !valuePresent(data.referral_id) && (
						<>
							<span
								onClick={() =>
									this.handler(
										'AMEND',
										data.invoice_id,
										pageNumber,
										arrayIndex,
									)
								}
								title="Amend"
								style={{ cursor: 'pointer' }}
							>
								<i
									className="bi bi-pen"
									style={{ fontSize: '20' }}
								></i>
							</span>
							&nbsp;&nbsp;
						</>
					)}
					{data.status === '6' &&
						data.entity_state === '2' &&
						!valuePresent(data.referral_id) && (
							<>
								<span
									onClick={() =>
										this.handler(
											'APPROVEAMENDMENT',
											data.invoice_id,
											pageNumber,
											arrayIndex,
										)
									}
									title="Approve"
									style={{ cursor: 'pointer' }}
								>
									<i
										className="bi bi-check2"
										style={{ fontSize: '20' }}
									></i>
								</span>
								&nbsp;&nbsp;
							</>
						)}
					{data.status === '6' &&
						data.entity_state === '2' &&
						valuePresent(data.referral_id) && (
							<>
								<span
									onClick={() =>
										this.withdrawReferral(
											data,
											i,
											'referral_id',
										)
									}
									title="Withdraw"
									style={{ cursor: 'pointer' }}
								>
									<i
										className="bi bi-skip-backward"
										style={{ fontSize: '20' }}
									></i>
								</span>
								&nbsp;&nbsp;
							</>
						)}
				</td>
			</tr>
		);
	};

	getHeader() {
		let key = 0;
		return (
			<tr key={++key}>
				<th key={++key}>Invoice No.</th>
				<th key={++key}>Invoice Date</th>
				<th key={++key}>Buyer</th>
				<th key={++key}>Payment Due Date</th>
				<th key={++key}>Buyer Contact</th>
				<th key={++key} style={{ textAlign: 'right' }}>
					Amount(excl. tax)
				</th>
				<th key={++key}>Status</th>
				<th key={++key}></th>
			</tr>
		);
	}

	getCompanyList = async (control, searchText) => {
		let retData;
		try {
			retData =
				await issuedInvoiceServiceRoutines.fetch_first_level_network(
					this.session.getServerInstance('SUBSCRIBER'),
					this.session.getAccessToken(),
					this.session.getLoggedInCompanyId(),
					null,
					null,
					searchText,
				);
			return retData;
		} catch (e) {
			control.error = true;
			if (e.error_message !== null && e.error_message !== undefined) {
				control.errorMsg = e.error_message;
			} else {
				control.errorMsg = e.message;
			}
		}
	};

	render = () => {
		const selectOptionForSenderOrgId = (org_id, org_name) => {
			this.state.data.buyer_org_id = org_id;
			this.setState({ data: this.state.data });
		};
		// let buttons = [];
		const filterBlock = (
			<div className="row">
				<div className="col-sm-6">
					<FormField
						field={fields.invoice_number}
						controlProps={this.controlProps}
					/>
				</div>
				<div className="col-sm-6">
					<PartnerList
						field={fields.buyer_org_id}
						keyName={'org_id'}
						descName={'org_name'}
						selectOption={selectOptionForSenderOrgId}
						controlProps={this.controlProps}
						relationship="D"
					/>
				</div>
				<div className="col-sm-6">
					<FormField
						field={fields.from_date}
						controlProps={this.controlProps}
					/>
				</div>

				<div className="col-sm-6">
					<FormField
						field={fields.to_date}
						controlProps={this.controlProps}
					/>
				</div>
				<div className="col-sm-6">
					<MultipleSelect
						label="Invoice Status:"
						fields={fields}
						options={Object.values(invoice_statuses)}
						controlProps={this.controlProps}
						values={this.state.data}
						fieldName={'statuses'}
					/>
				</div>
			</div>
		);

		const tableclass = 'table table-striped border-dark';
		let datablk = (
			<div className="container-fluid pt-0 m-0 pe-2">
				<div className="col-sm-12">
					<GenericTable
						pageHeading={this.getBreadcrumb()}
						pageHeadingClass="h6 text-left"
						data={this.invoices}
						recordsPerPage={this.recordsPerPage}
						getRow={this.getRow}
						getHeader={this.getHeader}
						tableclass={tableclass}
						getFrame={this.getFrame}
						recordsPerFrame={this.recordsPerFrame}
						pageNumber={this.pageNumber}
						isLoading={this.state.isLoading}
						button={this.table_buttons}
						pageChange={this.pageChange}
						showFilterCriteria={filterBlock}
						modalDialogStyle={{ marginLeft: '15em' }}
						resetFilters={(control) =>
							resetFilters({
								fields,
								setState: this.setState.bind(this),
							})
						}
					/>
				</div>
			</div>
		);

		let frm = (
			<GenericForm
				datablk={datablk}
				buttons={[]}
				controlProps={this.controlProps}
				{...this.props}
			/>
		);
		return frm;
	};
}

export default ManageIssuedInvoices;
