import React from 'react';
import { FormField } from 'WebUI_Framework';
import FieldLabel from './FieldLabel';

const MultipleSelect = ({
	label = '',
	fields = {},
	options = [],
	controlProps,
	values = {},
	fieldName = 'multi-select-value',
	...rest
}) => {
	/**
	 * Description
	 * The function takes in two parameters, one is the event and the other is the value.
	 * The event is used to check if the checkbox is checked or not.
	 * If the checkbox is checked, the function appends the value to the values object.
	 * If the checkbox is not checked, the function removes the value from the values object.
	 *
	 * @param {Event} e click event that is fired when the checkbox is clicked
	 * @param {string} value values to be passed inside object are `key` and `value`
	 * @returns {void}
	 */
	const onChange = (e, value) => {
		// is the checkbox checked or not.
		let isChecked = e.target?.checked;

		if (isChecked) {
			// append the selected key to the value if it's not already there.
			if (values[fieldName]) {
				values[fieldName]?.push(value);
			} else {
				// assing an array type field value to the specified key
				values[fieldName] = [value];
			}
		} else {
			// if the field is already there when the checkbox is unchecked then remove it from the list
			if (values[fieldName]?.includes(value)) {
				let contents = values[fieldName]?.filter(
					(item) => item !== value,
				);
				values[fieldName] = contents;
			}
		}
		controlProps?.cInstance?.setState({ data: values });
	};

	return (
		<div className="">
			{label && <FieldLabel title={label} htmlFor={fieldName} />}
			<div className="container row p-0">
				{options.map(({ key, value }, index) => {
					return (
						<div
							className="col-sm-6"
							key={`MultiSelect-${fieldName}-${index}`}
						>
							<FormField
								field={fields[key]}
								controlProps={controlProps}
                                style={{pading:"0"}}
								onChange={(e) => onChange(e, value)}
								{...rest}
							/>
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default MultipleSelect;
