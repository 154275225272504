import { handleAxiosHTTPException } from 'WebUI_Framework';
import { getServiceDetails } from '../common/utils';

let partnersserviceroutine = {};

partnersserviceroutine.list = async (serverInstance, accessToken, params) => {
	let response;
	try {
		response = await serverInstance.get(
			`/biop/registrar/company/listing_for_linkage`,
			{
				headers: {
					'X-Auth': accessToken,
				},
				params,
			},
		);

		return response.data;
	} catch (e) {
		return handleAxiosHTTPException(e);
	}
};

partnersserviceroutine.fetch_partnership_rec = async (
	serverInstance,
	accessToken,
	selectOrgId,
	parentOrgId,
) => {
	let response;
	try {
		response = await serverInstance.get(
			`/biop/registrar/partner/fetch?org_id=${selectOrgId}&parent_org_id=${parentOrgId}`,
			{
				headers: {
					'X-Auth': accessToken,
				},
			},
		);

		return response.data;
	} catch (e) {
		return handleAxiosHTTPException(e);
	}
};

partnersserviceroutine.add_partnership_rec = async (
	serverInstance,
	accessToken,
	data,
) => {
	let response;
	try {
		response = await serverInstance.post(
			`/biop/registrar/partner/add`,
			data,
			{
				headers: {
					'X-Auth': accessToken,
				},
			},
		);

		return response.data;
	} catch (e) {
		return handleAxiosHTTPException(e);
	}
};

partnersserviceroutine.modify_partnership_rec = async (
	serverInstance,
	accessToken,
	data,
) => {
	let response;
	try {
		response = await serverInstance.put(
			`/biop/registrar/partner/modify`,
			data,
			{
				headers: {
					'X-Auth': accessToken,
				},
			},
		);

		return response.data;
	} catch (e) {
		return handleAxiosHTTPException(e);
	}
};

partnersserviceroutine.fetch = async (
	serverInstance,
	accessToken,
	selectOrgId,
) => {
	let response;
	try {
		response = await serverInstance.get(
			`/biop/registrar/company/fetch?org_id=${selectOrgId}`,
			{
				headers: {
					'X-Auth': accessToken,
				},
			},
		);

		return response.data;
	} catch (e) {
		return handleAxiosHTTPException(e);
	}
};

partnersserviceroutine.delete = async (serverInstance, accessToken, data) => {
	let response;
	try {
		response = await serverInstance.put(
			`/biop/registrar/company/delete`,
			data,
			{
				headers: {
					'X-Auth': accessToken,
				},
			},
		);

		return response.data;
	} catch (e) {
		return handleAxiosHTTPException(e);
	}
};

partnersserviceroutine.modify = async (
	serverInstance,
	accessToken,
	selectedOrgData,
) => {
	let response;
	try {
		response = await serverInstance.post(
			'/biop/registrar/company/modify',
			selectedOrgData,
			{
				headers: {
					'X-Auth': accessToken,
				},
			},
		);

		return response.data;
	} catch (e) {
		return handleAxiosHTTPException(e);
	}
};

partnersserviceroutine.add = async (serverInstance, accessToken, retObj) => {
	let response;
	try {
		response = await serverInstance.post(
			'/biop/registrar/company/add',
			retObj,
			{
				headers: {
					'X-Auth': accessToken,
				},
			},
		);

		return response.data;
	} catch (e) {
		return handleAxiosHTTPException(e);
	}
};

partnersserviceroutine.list_pending_request = async (
	serverInstance,
	accessToken,
	offset,
	recordsPerFrame,
	senderOrgId,
) => {
	let response;
	try {
		response = await serverInstance.get(
			'/biop/subscriber/relationship_request/list',
			{
				headers: {
					'X-Auth': accessToken,
				},
				params: {
					offset: offset,
					num_recs: recordsPerFrame,
					sender_org_id: senderOrgId,
					type: '1',
					status: '1',
				},
			},
		);

		return response.data;
	} catch (e) {
		return handleAxiosHTTPException(e);
	}
};

partnersserviceroutine.send_relationship_request = async (
	serverInstance,
	accessToken,
	obj,
) => {
	let response;
	try {
		response = await serverInstance.post(
			'/biop/subscriber/relationship_request/send',
			obj,
			{
				headers: {
					'X-Auth': accessToken,
				},
			},
		);

		return response.data;
	} catch (e) {
		return handleAxiosHTTPException(e);
	}
};

partnersserviceroutine.send_relationship_amendment_request = async (
	serverInstance,
	accessToken,
	obj,
) => {
	let response;
	try {
		response = await serverInstance.post(
			'/biop/subscriber/relationship_request/amend',
			obj,
			{
				headers: {
					'X-Auth': accessToken,
				},
			},
		);

		return response.data;
	} catch (e) {
		return handleAxiosHTTPException(e);
	}
};

partnersserviceroutine.fetchParameters = async (session, orgId) => {
	const { serverInstance, accessToken } = getServiceDetails(session);
	const qp = {
		org_id: orgId,
		parent_org_id: session.getLoggedInParentCompanyId(),
	};
	let response;
	try {
		response = await serverInstance.get(
			`/biop/subscriber/partner_parameters_manager/fetch`,
			{
				headers: {
					'X-Auth': accessToken,
				},
				params: qp,
			},
		);
	} catch (e) {
		return handleAxiosHTTPException(e);
	}
	return response.data;
};

partnersserviceroutine.modifyParameters = async (
	session,
	partnerParameters,
) => {
	const { serverInstance, accessToken } = getServiceDetails(session);
	let response;
	try {
		response = await serverInstance.post(
			`/biop/subscriber/partner_parameters_manager/modify`,
			partnerParameters,
			{
				headers: {
					'X-Auth': accessToken,
				},
			},
		);
	} catch (e) {
		return handleAxiosHTTPException(e);
	}
	return response.data;
};

export default partnersserviceroutine;
