import React from 'react';

const FieldLabel = ({ title, htmlFor, style = {}, ...rest }) => {
	return (
		<div className="d-flex ps-4">
			<label
				htmlFor={htmlFor}
				className="col-form-label-sm text-secondary fw-bolder"
				style={{ fontSize: '0.9rem', ...style }}
			>
				{title}
			</label>
		</div>
	);
};

export default FieldLabel;
