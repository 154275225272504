import {
	copyFormFieldsToDataFields,
	deepCopy,
	filtered_select,
	filteredselect,
	FormField,
	generic_select,
	generic_string,
	GenericComponent,
	GenericForm,
	genericselect,
	genericstring,
	GenericTable,
	getInitialState,
	input_date,
	inputdate,
	raiseError,
} from 'WebUI_Framework';
import React from 'react';
import PartnerList from '../common/PartnerList';
import { resetFilters } from '../lib/helper';
import AddEditDrCrNote from './AddEditDrCrNote';
import DrCrNoteServiceRoutines from './DrCrNoteServiceRoutines';

const fields = {
	document_number: {
		id: 'document_number',
		name: 'document_number',
		label: window.getLabelLiteralWithColon('DOCUMENT_NUMBER'),
		class: genericstring,
		component: generic_string,
		mandatory: false,
	},
	invoice_number: {
		id: 'invoice_number',
		name: 'invoice_number',
		label: window.getLabelLiteralWithColon('INVOICE_NUMBER'),
		class: genericstring,
		component: generic_string,
		mandatory: false,
	},
	from_date: {
		id: 'from_date',
		name: 'from_date',
		label: window.getLabelLiteralWithColon('DOCUMENT_DATE_FROM'),
		class: inputdate,
		component: input_date,
		mandatory: false,
	},
	to_date: {
		id: 'to_date',
		name: 'to_date',
		label: window.getLabelLiteralWithColon('DOCUMENT_DATE_TO'),
		class: inputdate,
		component: input_date,
		mandatory: false,
	},
	status: {
		id: 'status',
		name: 'status',
		label: window.getLabelLiteralWithColon('STATUS_TITLE'),
		class: genericselect,
		component: generic_select,
		mandatory: false,
	},
	cr_org_id: {
		id: 'cr_org_id',
		name: 'cr_org_id',
		label: window.getLabelLiteralWithColon('CREDIT_ORG'),
		class: filteredselect,
		component: filtered_select,
		mandatory: false,
	},
	dr_org_id: {
		id: 'dr_org_id',
		name: 'dr_org_id',
		label: window.getLabelLiteralWithColon('DEBIT_ORG'),
		class: filteredselect,
		component: filtered_select,
		mandatory: false,
	},
};

let mapping = [
	['document_number', 'document_number'],
	['invoice_number', 'invoice_number'],
	['from_date', 'from_date'],
	['to_date', 'to_date'],
	['status', 'status'],
	['cr_org_id', 'cr_org_id'],
	['dr_org_id', 'dr_org_id'],
];
class ManageDebitCreditNotes extends GenericComponent {
	constructor(props) {
		super(props);
		this.recordsPerFrame = window.getParam('CURSOR_FRAME_SIZE');
		this.pageNumber = 1;
		//this.recordsPerPage = 7;
		this.recordsPerPage = window.getParam('TABLE_PAGE_SIZE');
		this.subscriberService = this.session.getServerInstance('SUBSCRIBER');
		this.accessToken = this.session.getAccessToken();
		this.props = props;
		this.dr_cr_notes = []; /* Data of main page */
		this.state.isLoading = true; /* Loading animation for table */
		this.state.editCount = 0;
		this.getHeader = this.getHeader.bind(this);
		this.getRow = this.getRow.bind(this);
		this.getFrame = this.getFrame.bind(this);
	}

	initialState = getInitialState(fields);

	state = {
		data: deepCopy(this.initialState),
	};

	getFrame = async (offset, recordsPerFrame) => {
		this.resetError();
		this.setState({ isLoading: true });
		try {
			let params = {
				offset,
				num_recs: recordsPerFrame,
			};

			copyFormFieldsToDataFields(
				mapping,
				this.state.data,
				params,
				fields,
			);

			const retData = await DrCrNoteServiceRoutines.list(
				this.subscriberService,
				this.accessToken,
				this.session.getLoggedInCompanyId(),
				this.props.type,
				params,
			);

			if (offset > 1 && retData.length > 0) {
				this.dr_cr_notes = this.dr_cr_notes.concat(retData);
			} else {
				this.dr_cr_notes = retData || [];
			}
		} catch (e) {
			console.log(e);
			this.setError(raiseError(e.error_message));
		}
		this.setState({ isLoading: false });
	};

	onReturn = (ret) => {
		this.resetError();
		if (ret.error) {
			this.setError(ret.error);
			return;
		}
		/*eslint no-fallthrough: 0*/
		switch (ret.retFrom) {
			case 'ADD':
				this.dr_cr_notes.splice(
					(this.pageNumber - 1) * this.recordsPerPage,
					0,
					ret.selectData.header,
				);
			case 'EDIT':
			case 'ACCEPT':
			case 'REJECT':
			case 'APPROVE':
			case 'VIEW':
				let updatedArrayIndex =
					(this.pageNumber - 1) * this.recordsPerPage +
					ret.arrayIndex;
				this.setState((prevState, props) => {
					this.dr_cr_notes[updatedArrayIndex] = ret.selectData.header;

					let newState = deepCopy(prevState);
					newState.editCount++;
					return newState;
				});
				break;
			case 'BACK':
				break;
			case 'DELETE':
				this.dr_cr_notes.splice(ret.arrayIndex, 1);
				this.setState({ editCount: this.state.editCount + 1 });
				break;
			default:
				this.setError(
					raiseError('Invalid return from AddEditDrCrNote'),
				);
		}
		return;
	};

	handler = async (
		action,
		documentId,
		arrayIndex,
		pageNumber,
		owner_org_id,
	) => {
		this.pageNumber = pageNumber;
		this.callForm(
			AddEditDrCrNote,
			{
				action,
				documentId,
				arrayIndex,
				pageNumber,
				owner_org_id,
				...this.props,
			},
			this.onReturn,
		);
	};

	getRow = (row, index, pageNumber, arrayIndex) => {
		let dr_cr_statuses = window.getGenericRefCode('dr_cr_statuses') || [];
		let stat = dr_cr_statuses[row.status] ?? 'ERROR';
		let i = index - 1;
		if (stat === 'ERROR') {
			this.setError(
				raiseError('Invalid status code [' + row.status + ']'),
			);
		}

		let tableRow = (
			<tr key={i}>
				<td>{row.document_date}</td>
				<td>{row.owner_org_name}</td>
				<td>
					{' '}
					<button
						type="button"
						style={{
							color: 'blue',
							textDecoration: 'underline',
							cursor: 'pointer',
							border: 'none',
							background: 'none',
							padding: 0,
						}}
						onClick={() =>
							this.handler(
								'VIEW',
								row.document_id,
								i,
								pageNumber,
								row.owner_org_id,
							)
						}
					>
						{row.document_number}
					</button>
				</td>
				{this.props.type === 'C' && <td>{row.cr_org_name}</td>}
				{this.props.type === 'D' && <td>{row.dr_org_name}</td>}
				<td>{row.invoice_number}</td>
				<td>{row.currency}</td>
				<td>{stat}</td>
				<td>
					{row.deleted !== true && row.entity_state === '0' && (
						<>
							<span
								onClick={() =>
									this.handler(
										'APPROVE',
										row.document_id,
										i,
										pageNumber,
										row.owner_org_id,
									)
								}
								title={window.getLabelLiteral('APPROVE')}
								style={{ cursor: 'pointer' }}
							>
								<i
									className="bi bi-check2-square"
									style={{ fontSize: 20 }}
								></i>
							</span>
							&nbsp;&nbsp;
							<span
								onClick={() =>
									this.handler(
										'EDIT',
										row.document_id,
										i,
										pageNumber,
										row.owner_org_id,
									)
								}
								title={window.getLabelLiteral('EDIT')}
								style={{ cursor: 'pointer' }}
							>
								<i
									className="bi bi-pencil-square"
									style={{ fontSize: 20 }}
								></i>
							</span>
							&nbsp;&nbsp;
							<span
								onClick={() =>
									this.handler(
										'DELETE',
										row.document_id,
										i,
										pageNumber,
									)
								}
								title={window.getLabelLiteral('DELETE')}
								style={{ cursor: 'pointer' }}
							>
								<i
									className="bi bi-trash3"
									style={{ fontSize: 20 }}
								></i>
							</span>
							&nbsp;&nbsp;
						</>
					)}
					{row.deleted !== true && row.status === '3' && (
						<>
							<span
								onClick={() =>
									this.handler(
										'REJECT',
										row.document_id,
										i,
										pageNumber,
										row.owner_org_id,
									)
								}
								title={window.getLabelLiteral('REJECT')}
								style={{ cursor: 'pointer' }}
							>
								<i
									className="bi bi-hand-thumbs-down"
									style={{ fontSize: 20 }}
								></i>
							</span>
							&nbsp;&nbsp;
							<span
								onClick={() =>
									this.handler(
										'ACCEPT',
										row.document_id,
										i,
										pageNumber,
										row.owner_org_id,
									)
								}
								title={window.getLabelLiteral('ACCEPT')}
								style={{ cursor: 'pointer' }}
							>
								<i
									className="bi bi-hand-thumbs-up"
									style={{ fontSize: 20 }}
								></i>
							</span>
						</>
					)}
				</td>
			</tr>
		);

		return tableRow;
	};

	getHeader() {
		let key = 0;
		return (
			<>
				<tr key={++key}>
					<th key={++key}>{window.getLabelLiteral('DATE')}</th>
					<th key={++key}>{window.getLabelLiteral('OWNER_ORG')}</th>
					<th key={++key}>
						{this.props.type === 'D'
							? window.getLabelLiteral('DR_NOTE_NUMBER')
							: window.getLabelLiteral('CR_NOTE_NUMBER')}
					</th>
					{this.props.type === 'C' && (
						<th key={++key}>
							{window.getLabelLiteral('CREDITED_COMPANY_NAME')}
						</th>
					)}
					{this.props.type === 'D' && (
						<th key={++key}>
							{window.getLabelLiteral('DEBITED_COMPANY_NAME')}
						</th>
					)}
					<th key={++key}>
						{window.getLabelLiteral('INVOICE_NUMBER')}
					</th>
					<th key={++key}>{window.getLabelLiteral('CURRENCY')}</th>
					<th key={++key}>
						{window.getLabelLiteral('STATUS_TITLE')}
					</th>
					<th key={++key}>{window.getLabelLiteral('ACTIONS')}</th>
				</tr>
			</>
		);
	}

	componentDidMount() {
		this.getFrame(1, this.recordsPerFrame);
	}

	buttons = [
		{
			literal: 'Add',
			func: () => {
				this.callForm(
					AddEditDrCrNote,
					{ action: 'ADD', ...this.props },
					this.onReturn,
				);
			},
		},
	];

	render() {
		const selectOptionForCrediter = (org_id, org_name) => {
			this.state.data.cr_org_id = org_id;
			this.setState({ data: this.state.data });
		};

		const selectOptionForDebiter = (org_id, org_name) => {
			this.state.data.dr_org_id = org_id;
			this.setState({ data: this.state.data });
		};
		const filterBlock = (
			<div className="row">
				<div className="col-sm-6">
					<FormField
						field={fields.document_number}
						controlProps={this.controlProps}
					/>
				</div>
				<div className="col-sm-6">
					<FormField
						field={fields.invoice_number}
						controlProps={this.controlProps}
					/>
				</div>

				<div className="col-sm-6">
					<FormField
						field={fields.from_date}
						controlProps={this.controlProps}
					/>
				</div>
				<div className="col-sm-6">
					<FormField
						field={fields.to_date}
						controlProps={this.controlProps}
					/>
				</div>

				<div className="col-sm-6">
					<FormField
						field={fields.status}
						controlProps={this.controlProps}
						refType={'dr_cr_statuses'}
					/>
				</div>
				{this.props.type ? (
					<div className="col-sm-6">
						{this.props.type === 'C' ? (
							<PartnerList
								field={fields.cr_org_id}
								keyName={'cr_org_id'}
								descName={'cr_org_name'}
								selectOption={selectOptionForCrediter}
								controlProps={this.controlProps}
								relationship="B"
							/>
						) : null}
						{this.props.type === 'D' ? (
							<PartnerList
								field={fields.dr_org_id}
								keyName={'dr_org_id'}
								descName={'dr_org_name'}
								selectOption={selectOptionForDebiter}
								controlProps={this.controlProps}
								relationship="B"
							/>
						) : null}
					</div>
				) : null}
			</div>
		);
		let datablk = (
			<div className="container-fluid pt-0 m-0 pe-2">
				<div className="col-sm-12">
					<GenericTable
						pageHeading={this.getBreadcrumb()}
						pageHeadingClass="h6 text-left"
						data={this.dr_cr_notes}
						recordsPerPage={this.recordsPerPage}
						getRow={this.getRow}
						getHeader={this.getHeader}
						tableclass="table table-striped border-dark"
						getFrame={this.getFrame}
						recordsPerFrame={this.recordsPerFrame}
						button={this.buttons}
						pageNumber={this.pageNumber}
						isLoading={this.state.isLoading}
						showFilterCriteria={filterBlock}
						modalDialogStyle={{ marginLeft: '15em' }}
						resetFilters={(control) =>
							resetFilters({
								fields,
								setState: this.setState.bind(this),
							})
						}
					/>
				</div>
			</div>
		);

		let frm = (
			<GenericForm
				datablk={datablk}
				buttons={[]}
				controlProps={this.controlProps}
				{...this.props}
			/>
		);
		return frm;
	}
}

export default ManageDebitCreditNotes;
