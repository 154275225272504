import {
	Backdrop,
	deepCopy,
	GenericComponent,
	GenericForm,
	GenericTable,
	Modal,
	Modal_view_content,
	NarrowButton,
	raiseError,
} from 'WebUI_Framework';

import 'bootstrap-icons/font/bootstrap-icons.css';
import React from 'react';
import EditPartners from '../partners/EditPartners';
import serviceRoutines from './UsersServiceRoutines';

class ManageCompanies extends GenericComponent {
	constructor(props) {
		super(props);
		this.recordsPerFrame = window.getParam('CURSOR_FRAME_SIZE');
		//this.recordsPerPage = 7;
		this.recordsPerPage = window.getParam('TABLE_PAGE_SIZE');
		this.pageNumber = 1;
		this.selectedOrgId = 0;
		this.selectedVersion = 1;
		this.tableclass = 'table table-striped border-dark';
		this.state = {
			isLoading: true,
			data: [],
			showModalUnlink: false,
			showModalLink: false,
			showModalDisable: false,
			showModalEnable: false,
			viewModal: false,
		};
		this.org_id = '';
		this.state.data1 = [];
		this.serverInstance = this.session.getServerInstance('ROC');
		this.accessToken = this.session.getAccessToken();
		this.getRow = this.getRow.bind(this);
		this.getFrame = this.getFrame.bind(this);
		this.closeModal = this.closeModal.bind(this);
		this.backHandler = this.backHandler.bind(this);
		this.button.func = this.button.func.bind(this);
	}

	componentDidMount = async () => {
		try {
			this.getFrame(1, this.recordsPerFrame);
			this.userProfile = await serviceRoutines.fetch(
				this.serverInstance,
				this.accessToken,
				this.props.email_id,
			);
		} catch (e) {
			window.scrollTo({
				top: 0,
				left: 0,
				behavior: 'smooth',
			});
			console.log(this.rule_set);
			console.log(e);
			this.setError(raiseError(e.error_message));
		}
	};

	compare = (a, b) => {
		if (a.is_linked && !b.is_linked) {
			return -1;
		} else if (!a.is_linked && b.is_linked) {
			return 1;
		} else {
			return a.org_name.localeCompare(b.org_name);
		}
	};

	getFrame = async (offset, recordsPerFrame) => {
		let firstLevelNetworks;
		let linkedCompanies;
		try {
			firstLevelNetworks =
				await serviceRoutines.fetch_first_level_network(
					this.serverInstance,
					this.accessToken,
					this.session.getLoggedInParentCompanyId(),
					offset,
					recordsPerFrame,
					'',
					false,
				);
			linkedCompanies = await serviceRoutines.list_linked_companies(
				this.serverInstance,
				this.accessToken,
				this.props.email_id,
				offset,
				recordsPerFrame,
			);
		} catch (e) {
			console.log(e);
			this.setError(raiseError(e.error_message));
		}

		const updatedFirstLevelNetwork = firstLevelNetworks.map((obj) => ({
			...obj,
			is_linked: linkedCompanies.some(
				(linkedObj) => linkedObj.org_id === obj.org_id,
			),
			disabled: linkedCompanies.some((linkedObj) =>
				linkedObj.org_id === obj.org_id && linkedObj.disabled === true
					? true
					: false,
			),
		}));

		this.setState({ isLoading: false });
		if (updatedFirstLevelNetwork.length > 0) {
			const sortedData = updatedFirstLevelNetwork.sort(this.compare);
			this.setState({ data: this.state.data.concat(sortedData) });
		}
	};

	viewHandler = async (org_id) => {
		let retData;
		try {
			retData = await serviceRoutines.fetchCompany(
				this.session.getServerInstance('ROC'),
				this.session.getAccessToken(),
				org_id,
			);
			if (retData !== undefined && retData !== null) {
				this.org_id = retData.org_id;
				this.setState({ data1: retData });
				this.setState({ viewModal: true });
			}
		} catch (e) {
			this.setError(raiseError(e.error_message));
		}
	};

	getRow = (data, index, pageNumber, arrayIndex) => {
		let key = 0;
		this.pageNumber = pageNumber;
		return (
			<tr key={index}>
				<td>
					<button
						type="button"
						style={{
							color: 'blue',
							textDecoration: 'underline',
							cursor: 'pointer',
							border: 'none',
							background: 'none',
							padding: 0,
						}}
						onClick={() => this.viewHandler(data.org_id)}
					>
						{data.org_name}
					</button>
				</td>
				<td>{data.address_line_1}</td>
				<td>{data.contact_email_id}</td>
				<td>{data.contact_phone_number}</td>
				{data.is_linked &&
					this.userProfile.default_company_id === data.org_id &&
					data.disabled === false && (
						<td>
							<NarrowButton
								className="btn btn-secondary w-80"
								onClick={() =>
									this.disableHandler(data.org_id, arrayIndex)
								}
								value="Disable"
								key={++key}
							/>
						</td>
					)}
				{data.is_linked &&
					this.userProfile.default_company_id === data.org_id &&
					data.disabled === true && (
						<td>
							<NarrowButton
								className="btn btn-outline-dark w-80"
								onClick={() =>
									this.enableHandler(data.org_id, arrayIndex)
								}
								value="Enable"
								key={++key}
							/>
						</td>
					)}
				{data.is_linked &&
					this.userProfile.default_company_id !== data.org_id && (
						<td>
							<NarrowButton
								className="btn btn-secondary w-80"
								onClick={() =>
									this.unLinkHandler(data.org_id, arrayIndex)
								}
								value="Unlink"
								key={++key}
							/>
						</td>
					)}
				{!data.is_linked && (
					<td>
						<NarrowButton
							className="btn btn-outline-dark w-80"
							onClick={() =>
								this.linkHandler(data.org_id, arrayIndex)
							}
							value="Link"
							key={++key}
						/>
					</td>
				)}
			</tr>
		);
	};

	getHeader = () => {
		let key = 0;
		return (
			<tr key={++key}>
				<th key={++key}>Company Name</th>
				<th key={++key}>Address</th>
				<th key={++key}>Contact Email_id</th>
				<th key={++key}>Contact Phone Number</th>
				<th key={++key}>Action</th>
			</tr>
		);
	};

	onEnable = async (control) => {
		let retData;
		try {
			retData = await serviceRoutines.fetch(
				this.serverInstance,
				this.accessToken,
				this.props.email_id,
			);
			if (retData !== undefined && retData !== null) {
				await serviceRoutines.enable_access_to_default_company(
					this.serverInstance,
					this.accessToken,
					retData,
				);
				let data = deepCopy(this.state.data);
				let updatedArrayIndex =
					(this.pageNumber - 1) * this.recordsPerPage +
					this.selectArrayIndex;
				data[updatedArrayIndex].disabled = false;
				this.setState({ data: data });
			}
			this.setState({ showModalEnable: false });
		} catch (e) {
			console.log(e);
			control.error = true;
			control.errorMsg = e.error_message;
		}
	};

	onDisable = async (control) => {
		let retData;
		try {
			retData = await serviceRoutines.fetch(
				this.serverInstance,
				this.accessToken,
				this.props.email_id,
			);
			if (retData !== undefined && retData !== null) {
				await serviceRoutines.disable_access_to_default_company(
					this.serverInstance,
					this.accessToken,
					retData,
				);
				let data = deepCopy(this.state.data);
				let updatedArrayIndex =
					(this.pageNumber - 1) * this.recordsPerPage +
					this.selectArrayIndex;
				data[updatedArrayIndex].disabled = true;
				this.setState({ data: data });
			}
			this.setState({ showModalDisable: false });
		} catch (e) {
			console.log(e);
			control.error = true;
			control.errorMsg = e.error_message;
		}
	};

	onUnlink = async (control) => {
		let InputObj = {};
		InputObj.user_principal = this.props.email_id;
		InputObj.org_id = this.selectedOrgId;

		let retData;
		try {
			retData = await serviceRoutines.link_fetch(
				this.serverInstance,
				this.accessToken,
				InputObj.user_principal,
				InputObj.org_id,
			);
			if (retData !== undefined && retData !== null) {
				retData = await serviceRoutines.link_delete(
					this.serverInstance,
					this.accessToken,
					retData,
				);
				let data = deepCopy(this.state.data);
				let updatedArrayIndex =
					(this.pageNumber - 1) * this.recordsPerPage +
					this.selectArrayIndex;
				data[updatedArrayIndex].is_linked = false;
				this.setState({ data: data });
			}
			this.setState({ showModalUnlink: false });
		} catch (e) {
			console.log(e);
			control.error = true;
			control.errorMsg = e.error_message;
		}
	};

	onLink = async (control) => {
		let InputObj = {};
		InputObj.user_principal = this.props.email_id;
		InputObj.org_id = this.selectedOrgId;
		let retData;
		try {
			retData = await serviceRoutines.addunlinked(
				this.serverInstance,
				this.accessToken,
				InputObj,
			);
			let data = deepCopy(this.state.data);
			let updatedArrayIndex =
				(this.pageNumber - 1) * this.recordsPerPage +
				this.selectArrayIndex;
			data[updatedArrayIndex].is_linked = true;
			this.setState({ data: data, showModalLink: false });
			return retData;
		} catch (e) {
			this.setError(raiseError(e.error_message));
			control.error = true;
			control.errorMsg = e.error_message;
		}
	};

	closeModal = () => {
		this.setState({ showModalEnable: false });
		this.setState({ showModalDisable: false });
		this.setState({ showModalUnlink: false });
		this.setState({ showModalLink: false });
		this.setState({ viewModal: false });
	};

	backHandler = () => {
		if (this.isChildMode()) this.props.returnToParent({ retFrom: 'back' });
		else console.log('LOADED AS A PARENT');
	};

	onReturn = (ret) => {
		this.setState((prevState, props) => {
			let newState = deepCopy(prevState);
			newState.data = [];
			this.pageNumber = 1;
			this.getFrame(1, this.recordsPerFrame);
			return newState;
		});
	};

	enableHandler = (org_id, arrayIndex) => {
		this.setState({ showModalEnable: true });
		this.selectedOrgId = org_id;
		this.selectArrayIndex = arrayIndex;
	};

	disableHandler = (org_id, arrayIndex) => {
		this.setState({ showModalDisable: true });
		this.selectedOrgId = org_id;
		this.selectArrayIndex = arrayIndex;
	};

	unLinkHandler = (org_id, arrayIndex) => {
		this.setState({ showModalUnlink: true });
		this.selectedOrgId = org_id;
		this.selectArrayIndex = arrayIndex;
	};

	linkHandler = (org_id, arrayIndex) => {
		this.setState({ showModalLink: true });
		this.selectedOrgId = org_id;
		this.selectArrayIndex = arrayIndex;
	};

	button = {
		literal: 'Back',
		func: function () {
			this.backHandler();
		},
	};

	render = () => {
		let buttons = [];
		let modalViewContent = (
			<div className="container pt-0 m-0 ">
				<EditPartners
					orgId={this.org_id}
					readOnly={true}
					noFormTag={true}
					{...this.props}
				/>
			</div>
		);
		let datablk = (
			<>
				<div className="col-sm-12 pe-2">
					<GenericTable
						pageHeading={this.getBreadcrumb([
							window.getLabelLiteral('ACCESS_TO_COMPANY') +
								` [${this.props.email_id}]`,
						])}
						pageHeadingClass="h6 text-left"
						data={this.state.data}
						recordsPerPage={this.recordsPerPage}
						getRow={this.getRow}
						getHeader={this.getHeader}
						tableclass={this.tableclass}
						getFrame={this.getFrame}
						recordsPerFrame={this.recordsPerFrame}
						pageNumber={this.pageNumber}
						isLoading={this.state.isLoading}
					/>
					{this.state.showModalEnable && (
						<Modal
							title="Unlink Confirmation"
							msg="Are you sure you want to Enable access ?"
							btn1="Confirm"
							btn2="Cancel"
							onClick={this.closeModal}
							onConfirm={this.onEnable}
						/>
					)}
					{this.state.showModalEnable && <Backdrop />}
					{this.state.showModalDisable && (
						<Modal
							title="Unlink Confirmation"
							msg="Are you sure you want to Disable access ?"
							btn1="Confirm"
							btn2="Cancel"
							onClick={this.closeModal}
							onConfirm={this.onDisable}
						/>
					)}
					{this.state.showModalDisable && <Backdrop />}
					{this.state.showModalUnlink && (
						<Modal
							title="Unlink Confirmation"
							msg="Are you sure you want to remove this link?"
							btn1="Confirm"
							btn2="Cancel"
							onClick={this.closeModal}
							onConfirm={() => {
								this.onUnlink();
							}}
						/>
					)}
					{this.state.showModalUnlink && <Backdrop />}
					{this.state.showModalLink && (
						<Modal
							title="Link Confirmation"
							msg="Are you sure you want to add this link?"
							btn1="Confirm"
							btn2="Cancel"
							onClick={this.closeModal}
							onConfirm={() => {
								this.onLink();
							}}
						/>
					)}
					{this.state.showModalLink && <Backdrop />}
					{this.state.viewModal && (
						<Modal_view_content
							title={this.getBreadcrumb([
								'Company linkages',
								'View',
							])}
							btn1="Close"
							onClick={this.closeModal}
							modalContent={modalViewContent}
						/>
					)}
					{this.state.viewModal && <Backdrop />}
				</div>
			</>
		);

		let frm = (
			<GenericForm
				datablk={datablk}
				buttons={buttons}
				controlProps={this.controlProps}
				{...this.props}
			/>
		);

		return frm;
	};
}

export default ManageCompanies;
