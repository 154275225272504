import { valuePresent } from 'WebUI_Framework'
import receiptNoteServiceRoutines from "./ReceiptNoteServiceRoutines";
import commonServiceRoutines from "../common/CommonServiceRoutines";

const header_fields = [
    "invoice_number",
    "invoice_date",
    "seller_org_id",
    "seller_org_name",
    "receipt_note_number",
    "receipt_note_date",
    "currency",
];

const detail_fields = [
    "unit_of_measure",
    "rate_per_unit",
    "seller_item_id",
    "buyer_item_id",
    "seller_item_description",
    "buyer_item_description",
    "currency",
];

const tax_fields = [
    "tax_type",
    "tax_percentage"
];

const getDeliveredQuantity = (sellerItemId, invoice) => {
    let deliveredQuantity = 0;
    for (const invoiceLine of invoice.details) {
        if (invoiceLine.seller_item_id === sellerItemId) {
            deliveredQuantity += Number(invoiceLine.quantity);
        }
    }

    return deliveredQuantity;
}

const getRNItemRemarks = (sellerItemId, receiptNote) => {
    let remarks = "";
    for (const receiptNoteLine of receiptNote.details) {
        if (receiptNoteLine.seller_item_id === sellerItemId) {
            remarks = receiptNoteLine.receipt_note_remarks;
            if (valuePresent(remarks) && remarks !== "") return remarks;
        }
    }

    return remarks;
}

const getAcceptedQuantity = (sellerItemId, receiptNote) => {
    let acceptedQuantity = 0;
    for (const receiptNoteLine of receiptNote.details) {
        if (receiptNoteLine.seller_item_id === sellerItemId) {
            acceptedQuantity += Number(receiptNoteLine.accepted_quantity);
        }
    }

    return acceptedQuantity;
}

const debitNoteFromReceiptNote = async (session, receiptNoteId) => {
    const drNote = {
        header : {
            cr_org_id : session.getLoggedInCompanyId(),
            cr_org_name : session.getLoggedInCompanyName()
        },
        details : []
    };

    const receiptNote = await receiptNoteServiceRoutines.fetch(session, receiptNoteId);
    if (valuePresent(receiptNote.header.invoice_number)) {
        for (const name of header_fields) {
            drNote.header[name] = receiptNote.header[name];
        }
        drNote.header.dr_org_id = receiptNote.header.seller_org_id;
        drNote.header.dr_org_name = receiptNote.header.seller_org_name;
        drNote.header.document_date = session.getCurrentDate();

        const processedItems = {};
        const invoice = (await commonServiceRoutines.fetchInvoice(session, receiptNote.header.invoice_number,
            receiptNote.header.invoice_date, receiptNote.header.seller_org_id)).invoice;
        if (valuePresent(invoice)) {
            let i = 0;
            for (const line_item of invoice.details) {
                if (line_item.deleted) continue;
                if (valuePresent(processedItems[line_item.seller_item_id])) continue;
                processedItems[line_item.seller_item_id] = 1;
                const shortfall = getDeliveredQuantity(line_item.seller_item_id, invoice) -
                                    getAcceptedQuantity(line_item.seller_item_id, receiptNote);
                if (shortfall <= 0) continue;
                const item = {};
                for (const name of detail_fields) {
                    if (valuePresent(line_item[name]))
                        item[name] = line_item[name];
                }
                item.quantity = (shortfall).toString();
                item.reason = getRNItemRemarks(line_item.seller_item_id, receiptNote);
                if (valuePresent(line_item.tax_details)) {
                    item.tax_details = [];
                    let j = 0;
                    for (const tax_line of line_item.tax_details) {
                        const tax_item = {};
                        for (const name of tax_fields) {
                            tax_item[name] = tax_line[name];
                        }
                        item.tax_details[j++] = tax_item;
                    }
                }
                drNote.details[i++] = item;
            }
        }
    }

    return drNote;
}

export {
    debitNoteFromReceiptNote
}
