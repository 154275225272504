import { handleAxiosHTTPException } from 'WebUI_Framework';
import { getServiceDetails } from '../common/utils';

let DrCrNoteServiceRoutines = {};

DrCrNoteServiceRoutines.list = async (
	serverInstance,
	accessToken,
	org_id,
	dr_cr_ind,
	params,
) => {
	let response;
	let method;
	if (dr_cr_ind === 'D') {
		params.cr_org_id = org_id;
		method = 'list_dr_notes';
	} else if (dr_cr_ind === 'C') {
		params.dr_org_id = org_id;
		method = 'list_cr_notes';
	}
	params.deleted = 0;
	try {
		response = await serverInstance.get(
			`/biop/subscriber/dr_cr_note/${method}`,
			{
				headers: {
					'X-Auth': accessToken,
				},
				params,
			},
		);
	} catch (e) {
		console.log(e);
		return handleAxiosHTTPException(e);
	}
	return response.data;
};

DrCrNoteServiceRoutines.fetch = async (session, documentId) => {
	const { serverInstance, accessToken } = getServiceDetails(session);
	let response;
	try {
		response = await serverInstance.get(
			`/biop/subscriber/dr_cr_note/fetch?document_id=${documentId}`,
			{
				headers: {
					'X-Auth': accessToken,
				},
			},
		);
		return response.data;
	} catch (e) {
		console.log(e);
		return handleAxiosHTTPException(e);
	}
};

DrCrNoteServiceRoutines.fetchRcvd = async (session, documentId) => {
	const { serverInstance, accessToken } = getServiceDetails(session);
	let response;
	try {
		response = await serverInstance.get(
			`/biop/subscriber/rcvd_dr_cr_note/fetch?document_id=${documentId}`,
			{
				headers: {
					'X-Auth': accessToken,
				},
			},
		);
		console.log(response.data);
		return response.data;
	} catch (e) {
		console.log(e);
		return handleAxiosHTTPException(e);
	}
};

DrCrNoteServiceRoutines.fetchUsingNumberAndDate = async (
	session,
	documentNumber,
	documentDate,
	ownerOrgId,
) => {
	const { serverInstance, accessToken } = getServiceDetails(session);
	let response;
	let params = {
		document_number: documentNumber,
		document_date: documentDate,
		owner_org_id: ownerOrgId,
	};
	try {
		response = await serverInstance.get(
			`/biop/subscriber/dr_cr_note/fetch_using_number_and_date`,
			{
				params,
				headers: {
					'X-Auth': accessToken,
				},
			},
		);
		return response.data;
	} catch (e) {
		console.log(e);
		return handleAxiosHTTPException(e);
	}
};

DrCrNoteServiceRoutines.add = async (session, drCrNote) => {
	const { serverInstance, accessToken } = getServiceDetails(session);
	let response;
	try {
		response = await serverInstance.post(
			'/biop/subscriber/dr_cr_note/add',
			drCrNote,
			{
				headers: {
					'X-Auth': accessToken,
				},
			},
		);
		return response.data;
	} catch (e) {
		return handleAxiosHTTPException(e);
	}
};

DrCrNoteServiceRoutines.modify = async (session, drCrNote) => {
	const { serverInstance, accessToken } = getServiceDetails(session);
	let response;
	try {
		response = await serverInstance.put(
			'/biop/subscriber/dr_cr_note/modify',
			drCrNote,
			{
				headers: {
					'X-Auth': accessToken,
				},
			},
		);
		return response.data;
	} catch (e) {
		return handleAxiosHTTPException(e);
	}
};

DrCrNoteServiceRoutines.approve = async (session, drCrNote) => {
	const { serverInstance, accessToken } = getServiceDetails(session);
	let response;
	try {
		response = await serverInstance.put(
			'/biop/subscriber/dr_cr_note/approve',
			drCrNote,
			{
				headers: {
					'X-Auth': accessToken,
				},
			},
		);
		return response.data;
	} catch (e) {
		return handleAxiosHTTPException(e);
	}
};

DrCrNoteServiceRoutines.delete = async (session, drCrNote) => {
	const { serverInstance, accessToken } = getServiceDetails(session);
	let response;
	try {
		response = await serverInstance.put(
			'/biop/subscriber/dr_cr_note/delete',
			drCrNote,
			{
				headers: {
					'X-Auth': accessToken,
				},
			},
		);
		return response.data;
	} catch (e) {
		return handleAxiosHTTPException(e);
	}
};

DrCrNoteServiceRoutines.reject = async (session, drCrNoteHeader) => {
	const { serverInstance, accessToken } = getServiceDetails(session);
	let response;
	try {
		response = await serverInstance.put(
			'/biop/subscriber/rcvd_dr_cr_note/reject',
			drCrNoteHeader,
			{
				headers: {
					'X-Auth': accessToken,
				},
			},
		);
		return response.data;
	} catch (e) {
		return handleAxiosHTTPException(e);
	}
};

DrCrNoteServiceRoutines.accept = async (session, drCrNoteHeader) => {
	const { serverInstance, accessToken } = getServiceDetails(session);
	let response;
	try {
		response = await serverInstance.put(
			'/biop/subscriber/rcvd_dr_cr_note/accept',
			drCrNoteHeader,
			{
				headers: {
					'X-Auth': accessToken,
				},
			},
		);
		return response.data;
	} catch (e) {
		return handleAxiosHTTPException(e);
	}
};

export default DrCrNoteServiceRoutines;
