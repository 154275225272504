import {
	Backdrop,
	copyFormFieldsToDataFields,
	deepCopy,
	FormField,
	GenericComponent,
	GenericForm,
	GenericTable,
	getInitialState,
	Modal_view_content,
	raiseError,
} from 'WebUI_Framework';

import 'bootstrap-icons/font/bootstrap-icons.css';
import React from 'react';
import { resetFilters } from '../lib/helper';
import { generic_string, genericstring } from 'WebUI_Framework';
import AddUser from './AddUser';
import EditUser from './EditUser';
import ManageCompanies from './ManageCompanies';
import ManageUserInboxes from './ManageUserInboxes';
import userServiceRtn from './UsersServiceRoutines';

let refcode_country = window.getRefCode('00001');

// import 'bootstrap-icons/font/bootstrap-icons.css';

const fields = {
	user_name: {
		id: 'user_name',
		name: 'user_name',
		label: window.getLabelLiteralWithColon('USER_NAME'),
		class: genericstring,
		component: generic_string,
		mandatory: false,
	},
	email_id: {
		id: 'email_id',
		name: 'email_id',
		label: window.getLabelLiteralWithColon('EMAIL_ID'),
		class: genericstring,
		component: generic_string,
		mandatory: false,
	},
};

let mapping = [
	['user_name', 'user_name'],
	['email_id', 'email_id'],
];
class ManageUsers extends GenericComponent {
	constructor(p) {
		super(p);
		this.recordsPerFrame = window.getParam('CURSOR_FRAME_SIZE');
		this.pageNumber = 1;
		this.recordsPerPage = window.getParam('TABLE_PAGE_SIZE');
		this.select_email_id = '';
		this.selectArrayIndex = '';
		this.logged_in_org_id = this.session.getLoggedInCompanyId();
		this.logged_in_parent_org_id =
			this.session.getLoggedInParentCompanyId();
		// this.state = {
		// 	// data: [],
		// 	showModal: false,
		// 	isLoading: true
		// }
		this.users = [];
		this.state.showModal = false;
		this.state.isLoading = true;
		this.serverInstance = this.session.getServerInstance('ROC');
		this.accessToken = this.session.getAccessToken();
		this.getRow = this.getRow.bind(this);
		this.getFrame = this.getFrame.bind(this);
		this.button.func = this.button.func.bind(this);
		this.email_id = null;
	}

	// initialState = getInitialState(fields);

	// state = {
	// 	data: deepCopy(this.initialState),
	// };
	componentDidMount() {
		this.getFrame(1, this.recordsPerFrame);
	}

	onReturn = (ret) => {
		this.setState((prevState, props) => {
			let newState = deepCopy(prevState);

			if (ret.retFrom === 'add') {
				this.pageNumber = 1;
				newState.data = [];
				this.getFrame(1, this.recordsPerFrame);
			} else if (ret.retFrom === 'edit') {
				let updatedArrayIndex =
					(this.pageNumber - 1) * this.recordsPerPage +
					ret.arrayIndex;
				newState.data[updatedArrayIndex] = ret.selectData;
			}
			return newState;
		});
	};

	viewHandler = async (email_id, arrayIndex) => {
		this.callForm(
			EditUser,
			{ readOnly: true, email_id, arrayIndex, ...this.props },
			this.onReturn,
		);
		/*
		try {
			let retData = await userServiceRtn.fetch(this.session.getServerInstance('ROC'), this.session.getAccessToken(), email_id)
			if (retData !== undefined && retData !== null) {	
				this.email_id = retData.email_id;
				this.setState({data1: retData})
				this.setState({ viewModal: true });
			}
		}
		catch (e) {
			this.setError(raiseError(e.error_message));
		}
        */
	};

	editHandler = (email_id, arrayIndex) => {
		this.callForm(
			EditUser,
			{ email_id, arrayIndex, ...this.props },
			this.onReturn,
		);
	};

	linkHandler = (email_id, user_name) => {
		this.callForm(
			ManageCompanies,
			{ email_id, user_name, ...this.props },
			this.onReturn,
		);
	};

	generatePassword = async (email_id) => {
		try {
			await userServiceRtn.genPw(this.session, email_id);
			alert(window.getLiterals('PASSWORD_GENERATED') + email_id);
		} catch (e) {
			this.setError(raiseError(e.error_message));
		}
	};

	manageInboxesHandler = async (data, arrayIndex) => {
		this.callForm(
			ManageUserInboxes,
			{
				user_principal: data.email_id,
				user_name: data.user_name,
				arrayIndex,
				...this.props,
			},
			this.onReturn,
		);
	};

	closeViewModal = () => {
		this.setState({ viewModal: false });
	};

	async getFrame(offset, recordsPerFrame) {
		let retData;

		let params = {
			org_id: this.logged_in_org_id,
			offset,
			num_recs: recordsPerFrame,
			deleted: 0,
		};

		copyFormFieldsToDataFields(mapping, this.state.data, params, fields);

		try {
			retData = await userServiceRtn.list_for_org(this.session, params);
			this.setState({ isLoading: false });
			// if (retData.length > 0) {
			// 	let users = retData;
			// 	this.setState({ data: this.state.data.concat(users) });
			// }
			if (offset > 1 && retData.length > 0) {
				this.users = this.users.concat(retData);
			} else {
				this.users = retData || [];
			}
		} catch (e) {
			this.setError(raiseError(e.error_message));
		}
	}

	getRow(data, index, pageNumber, arrayIndex) {
		this.pageNumber = pageNumber;
		return (
			<tr key={index}>
				<td>
					{' '}
					<button
						type="button"
						style={{
							color: 'blue',
							textDecoration: 'underline',
							cursor: 'pointer',
							border: 'none',
							background: 'none',
							padding: 0,
						}}
						onClick={() =>
							this.viewHandler(data.email_id, arrayIndex)
						}
					>
						{data.user_name}
					</button>
				</td>
				<td>{data.email_id}</td>
				<td>{data.cell_number}</td>
				<td>{data.city}</td>
				<td>{refcode_country[data.cntry]}</td>
				<td>
					<span
						onClick={() =>
							this.editHandler(data.email_id, arrayIndex)
						}
						title="Edit"
						style={{ cursor: 'pointer' }}
					>
						<i
							className="bi bi-pencil-square"
							style={{ fontSize: 20 }}
						></i>
					</span>
					&nbsp;&nbsp;
					<span
						onClick={() =>
							this.linkHandler(data.email_id, data.user_name)
						}
						title="Manage Companies"
						style={{ cursor: 'pointer' }}
					>
						<i
							className="bi bi-link-45deg"
							style={{ fontSize: 20 }}
						></i>
					</span>
					&nbsp;&nbsp;
					<span
						onClick={() => this.generatePassword(data.email_id)}
						title="Generate password"
						style={{ cursor: 'pointer' }}
					>
						<i className="bi bi-key" style={{ fontSize: 20 }}></i>
					</span>
					{data.org_id && (
						<>
							&nbsp;&nbsp;
							<span
								onClick={() =>
									this.manageInboxesHandler(data, arrayIndex)
								}
								title="Manage inboxes"
								style={{ cursor: 'pointer' }}
							>
								<i
									className="bi bi-inboxes"
									style={{ fontSize: 20 }}
								></i>
							</span>
						</>
					)}
				</td>
			</tr>
		);
	}

	getHeader() {
		let key = 0;
		return (
			<tr key={++key}>
				<th key={++key}>User Name</th>
				<th key={++key}>Email</th>
				<th key={++key}>Cell Number</th>
				<th key={++key}>City</th>
				<th key={++key}>Country</th>
				<th key={++key}>Actions</th>
			</tr>
		);
	}

	initialState = getInitialState(fields);

	state = {
		data: deepCopy(this.initialState),
	};

	button = {
		literal: 'Add',
		func: function () {
			this.callForm(AddUser, { ...this.props }, this.onReturn);
		},
	};

	render() {
		let buttons = [];
		let modalContent = (
			<div className="container pt-0 m-0 ">
				<EditUser
					calledFrom={'view'}
					email_id={this.email_id}
					readOnly={true}
					noFormTag={true}
					{...this.props}
				/>
			</div>
		);

		const selectOption = (org_id, org_name) => {
			this.state.data.email_id = org_id;
			this.setState({ data: this.state.data });
		};
		let filterContent = (
			<div className="row">
				<div className="col-sm-6">
					<FormField
						field={fields.user_name}
						controlProps={this.controlProps}
					/>
				</div>
				<div className="col-sm-6">
					<FormField
						field={fields.email_id}
						controlProps={this.controlProps}
					/>
				</div>
				{/* <div className="col-sm-6">
					<FormField
						field={fields.from_date}
						controlProps={this.controlProps}
					/>
				</div>
				<div className="col-sm-6">
					<FormField
						field={fields.to_date}
						controlProps={this.controlProps}
					/>
				</div>
				<div className="col-sm-6">
					<MultipleSelect
						label="Invoice Status:"
						fields={fields}
						options={Object.values(invoice_statuses)}
						controlProps={this.controlProps}
						values={this.state.data}
						fieldName={'statuses'}
					/>
				</div> */}
			</div>
		);
		const tableclass = 'table table-striped border-dark';
		let datablk = (
			<div className="container-fluid pt-0 m-0 pe-2">
				<div className="col-sm-12">
					<GenericTable
						pageHeading={this.getBreadcrumb()}
						pageHeadingClass="h6 text-left"
						data={this.users}
						recordsPerPage={this.recordsPerPage}
						getRow={this.getRow}
						getHeader={this.getHeader}
						tableclass={tableclass}
						getFrame={this.getFrame}
						recordsPerFrame={this.recordsPerFrame}
						pageNumber={this.pageNumber}
						isLoading={this.state.isLoading}
						button={this.button}
						showFilterCriteria={filterContent}
						modalDialogStyle={{ marginLeft: '15em' }}
						resetFilters={(control) =>
							resetFilters({
								fields,
								setState: this.setState.bind(this),
							})
						}
					/>
				</div>

				{this.state.viewModal && (
					<Modal_view_content
						title={this.getBreadcrumb([
							window.getLabelLiteral('VIEW'),
						])}
						btn1="Close"
						onClick={this.closeViewModal}
						modalContent={modalContent}
					/>
				)}
				{this.state.viewModal && <Backdrop />}
			</div>
		);

		let frm = (
			<GenericForm
				datablk={datablk}
				buttons={buttons}
				controlProps={this.controlProps}
				{...this.props}
			/>
		);
		return frm;
	}
}

export default ManageUsers;
