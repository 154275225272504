import { handleAxiosHTTPException } from 'WebUI_Framework';

let commercialtaxdetailserviceroutines = {};

commercialtaxdetailserviceroutines.list = async (
	serverInstance,
	accessToken,
	params,
) => {
	let response;
	try {
		response = await serverInstance.get(
			`/biop/registrar/commercial_tax_detail/list`,
			{
				headers: {
					'X-Auth': accessToken,
				},
				params,
			},
		);
		return response.data;
	} catch (e) {
		return handleAxiosHTTPException(e);
	}
};

commercialtaxdetailserviceroutines.fetch = async (
	serverInstance,
	accessToken,
	selectedData,
) => {
	let response;
	try {
		response = await serverInstance.get(
			`/biop/registrar/commercial_tax_detail/fetch?org_id=${selectedData.org_id}&location=${selectedData.location}&tin_type=${selectedData.tin_type}`,
			{
				headers: {
					'X-Auth': accessToken,
				},
			},
		);
		return response.data;
	} catch (e) {
		return handleAxiosHTTPException(e);
	}
};

commercialtaxdetailserviceroutines.delete = async (
	serverInstance,
	accessToken,
	retData,
) => {
	let response;
	try {
		response = await serverInstance.put(
			`/biop/registrar/commercial_tax_detail/delete`,
			retData,
			{
				headers: {
					'X-Auth': accessToken,
				},
			},
		);
		return response.data;
	} catch (e) {
		return handleAxiosHTTPException(e);
	}
};

commercialtaxdetailserviceroutines.add = async (
	serverInstance,
	accessToken,
	retObj,
) => {
	let response;
	try {
		response = await serverInstance.post(
			'/biop/registrar/commercial_tax_detail/add',
			retObj,
			{
				headers: {
					'X-Auth': accessToken,
				},
			},
		);
		return response.data;
	} catch (e) {
		return handleAxiosHTTPException(e);
	}
};
commercialtaxdetailserviceroutines.modify = async (
	serverInstance,
	accessToken,
	selectedcommercialtaxdata,
) => {
	let response;
	try {
		response = await serverInstance.post(
			'/biop/registrar/commercial_tax_detail/modify',
			selectedcommercialtaxdata,
			{
				headers: {
					'X-Auth': accessToken,
				},
			},
		);
		return response.data;
	} catch (e) {
		return handleAxiosHTTPException(e);
	}
};
export default commercialtaxdetailserviceroutines;
