import React from 'react';
import {
	copyFormFieldsToDataFields,
	deepCopy,
	filtered_select,
	filteredselect,
	FormField,
	generic_select,
	generic_string,
	GenericComponent,
	GenericForm,
	genericselect,
	genericstring,
	GenericTable,
	getInitialState,
	input_date,
	inputdate,
	NarrowButton,
	raiseError,
} from 'WebUI_Framework';
import AddEditQuotation from './AddEditQuotation';
import quotationServiceRoutines from './quotationServiceRoutines';
import ViewReceivedRFQ from './ViewReceivedRFQ';

import PartnerList from '../common/PartnerList';
import { resetFilters } from '../lib/helper';
const fields = {
	rfq_number: {
		id: 'rfq_number',
		name: 'rfq_number',
		label: window.getLabelLiteralWithColon('RFQ_NUMBER'),
		class: genericstring,
		component: generic_string,
		mandatory: false,
	},
	buyer_org_id: {
		id: 'buyer_org_id',
		name: 'buyer_org_id',
		label: window.getLabelLiteralWithColon('BUYER_ORG'),
		class: filteredselect,
		component: filtered_select,
		mandatory: false,
	},
	from_date: {
		id: 'from_date',
		name: 'from_date',
		label: window.getLabelLiteralWithColon('RFQ_DATE_FROM'),
		class: inputdate,
		component: input_date,
		mandatory: false,
	},
	to_date: {
		id: 'to_date',
		name: 'to_date',
		label: window.getLabelLiteralWithColon('RFQ_DATE_TO'),
		class: inputdate,
		component: input_date,
		mandatory: false,
	},
	rrfq_status: {
		id: 'rrfq_status',
		name: 'rrfq_status',
		label: window.getLabelLiteralWithColon('RFQ_STATUS'),
		class: genericselect,
		component: generic_select,
		mandatory: false,
	},
};

let mapping = [
	['rfq_number', 'rfq_number'],
	['buyer_org_id', 'buyer_org_id'],
	['from_date', 'from_date'],
	['to_date', 'to_date'],
	['rrfq_status', 'rrfq_status'],
];
class ManageRFQs extends GenericComponent {
	constructor(p) {
		super(p);
		this.recordsPerFrame = window.getParam('CURSOR_FRAME_SIZE');
		this.pageNumber = 1;
		//this.recordsPerPage = 15;
		this.recordsPerPage = window.getParam('TABLE_PAGE_SIZE');
		this.subscriberServer = this.session.getServerInstance('SUBSCRIBER');
		this.accessToken = this.session.getAccessToken();
		this.getRow = this.getRow.bind(this);
		this.getFrame = this.getFrame.bind(this);

		this.state.isLoading = true; /* Loading animation for table */
		this.state.editCount = 0;
		this.rfqs = [];
	}

	initialState = getInitialState(fields);

	state = {
		data: deepCopy(this.initialState),
	};

	componentDidMount() {
		this.getFrame(1, this.recordsPerFrame);
	}

	getFrame = async (offset, recordsPerFrame) => {
		this.resetError();
		try {
			let params = {
				offset,
				num_recs: recordsPerFrame,
				// deleted: 0,
				seller_org_id: this.session.getLoggedInCompanyId(),
			};

			copyFormFieldsToDataFields(
				mapping,
				this.state.data,
				params,
				fields,
			);
			let retData = await quotationServiceRoutines.list(
				this.session,
				params,
			);
			if (offset > 1 && retData.length > 0) {
				this.rfqs = this.rfqs.concat(retData);
			} else {
				this.rfqs = retData || [];
			}
		} catch (e) {
			this.setError(raiseError(e.error_message));
		}
		this.setState({ isLoading: false });
	};

	onReturn = (ret) => {
		this.resetError();
		if (ret.error) {
			this.setError(ret.error);
			return;
		}
		/*eslint no-fallthrough: 0*/
		switch (ret.retFrom) {
			case 'ADD':
				{
					let updatedArrayIndex =
						(this.pageNumber - 1) * this.recordsPerPage +
						ret.arrayIndex;
					this.setState((prevState, props) => {
						let q = this.rfqs[updatedArrayIndex].quotations;
						const ret_qutation_header = ret.selectData.header;
						let qh = {
							quotation_id: ret_qutation_header.quotation_id,
							quotation_number:
								ret_qutation_header.quotation_number,
							quotation_issue_date:
								ret_qutation_header.quotation_issue_date,
							quotation_valid_from_date:
								ret_qutation_header.quotation_valid_from_date,
							quotation_valid_to_date:
								ret_qutation_header.quotation_valid_to_date,
							buyer_org_id: ret_qutation_header.buyer_org_id,
							quotation_status:
								ret_qutation_header.quotation_status,
							buyer_org_name: ret_qutation_header.buyer_org_name,
							entity_state: ret_qutation_header.entity_state,
							description: ret_qutation_header.description,
						};
						q[q.length] = qh;

						let newState = deepCopy(prevState);
						newState.editCount++;
						return newState;
					});
				}
				break;
			case 'EDIT':
			case 'APPROVE':
				let updatedArrayIndex =
					(this.pageNumber - 1) * this.recordsPerPage +
					ret.arrayIndex;
				this.setState((prevState, props) => {
					let qa = this.rfqs[updatedArrayIndex].quotations;

					const ret_qutation_header = ret.selectData.header;
					let qh = {
						quotation_id: ret_qutation_header.quotation_id,
						quotation_number: ret_qutation_header.quotation_number,
						quotation_issue_date:
							ret_qutation_header.quotation_issue_date,
						quotation_valid_from_date:
							ret_qutation_header.quotation_valid_from_date,
						quotation_valid_to_date:
							ret_qutation_header.quotation_valid_to_date,
						quotation_status: ret_qutation_header.quotation_status,
						buyer_org_id: ret_qutation_header.buyer_org_id,
						buyer_org_name: ret_qutation_header.buyer_org_name,
						entity_state: ret_qutation_header.entity_state,
						description: ret_qutation_header.description,
					};
					qa[ret.qArrayIndex] = qh;

					let newState = deepCopy(prevState);
					newState.editCount++;
					return newState;
				});
				break;
			case 'VIEW':
			case 'BACK':
				break;
			case 'DELETE':
				{
					let updatedArrayIndex =
						(this.pageNumber - 1) * this.recordsPerPage +
						ret.arrayIndex;
					this.rfqs[updatedArrayIndex].quotations.splice(
						ret.qArrayIndex,
						1,
					);
					this.setState({ editCount: this.state.editCount + 1 });
				}
				break;
			default:
				this.setError(
					raiseError('Invalid return from AddEditQuotation'),
				);
		}
		return;
	};

	rrfq_handler = async (action, rrfqId, pageNumber, arrayIndex) => {
		this.pageNumber = pageNumber;
		this.callForm(
			ViewReceivedRFQ,
			{ action, rrfqId, arrayIndex, pageNumber, ...this.props },
			this.onReturn,
		);
	};

	handler = async (
		action,
		quotationId,
		rfqId,
		rrfqId,
		pageNumber,
		arrayIndex,
		qArrayIndex,
	) => {
		this.pageNumber = pageNumber;
		const receivedRFQ = await quotationServiceRoutines.fetchReceivedRFQ(
			this.session,
			rrfqId,
		);
		this.callForm(
			AddEditQuotation,
			{
				action,
				quotationId,
				rfqId,
				receivedRFQ,
				arrayIndex,
				qArrayIndex,
				pageNumber,
				...this.props,
			},
			this.onReturn,
		);
	};

	addQuotation = async (rfq_id, rrfq_id, arrayIndex) => {
		const ucp = this.session.getUserCompanyProps(
			this.session.getLoggedInCompanyId(),
			this.session.getLoggedInParentCompanyId(),
		);
		if (!ucp.org_is_subscriber) {
			if (ucp.partner_relationship_type === 'D') {
				this.setError(
					raiseError(
						window.parseLiteral(
							'CANNOT_CREATE_QUOTATION',
							this.session.getLoggedInCompanyName(),
						),
					),
				);
				return false;
			}
		}
		this.handler(
			'ADD',
			null,
			rfq_id,
			rrfq_id,
			this.pageNumber,
			arrayIndex,
			0,
		);
	};

	table_buttons = [
		// { literal: 'Filter', func: () => {} },
		{
			literal: 'Add',
			func: () => {},
		},
	];

	pageChange = (pageNumber) => {
		this.pageNumber = pageNumber;
	};

	getHeader() {
		let key = 0;
		return (
			<tr key={++key}>
				<th key={++key}>RFQ No.</th>
				<th key={++key}>RFQ Date</th>
				<th key={++key}>Buyer</th>
				<th key={++key}>Description</th>
				<th key={++key}>Valid From</th>
				<th key={++key}>Valid Till</th>
				<th key={++key}></th>
				<th key={++key}></th>
			</tr>
		);
	}

	get_status_desc = (status) => {
		let desc;
		switch (status) {
			case '1':
				desc = 'DRAFT';
				break;
			case '2':
				desc = 'SENT';
				break;
			case '3':
				desc = 'ACCEPTED';
				break;
			case '4':
				desc = 'REJECTED';
				break;
			default:
				desc = 'UNKNOWN';
				break;
		}
		return <>{desc}</>;
	};

	getRow = (data, index, pageNumber, arrayIndex) => {
		this.pageNumber = pageNumber;

		let key = 0;
		let ik = 0;
		return (
			<React.Fragment key={index}>
				<tr key={key++}>
					<td>
						<button
							type="button"
							style={{
								color: 'blue',
								textDecoration: 'underline',
								cursor: 'pointer',
								border: 'none',
								background: 'none',
								padding: 0,
							}}
							onClick={() =>
								this.rrfq_handler(
									'VIEW',
									data.rrfq_id,
									pageNumber,
									arrayIndex,
								)
							}
						>
							{data.rfq_number}
						</button>
					</td>
					<td>{data.rfq_issue_date}</td>
					<td>{data.buyer_org_name}</td>
					<td>{data.description}</td>
					<td>{data.rfq_valid_from_date}</td>
					<td>{data.rfq_valid_to_date}</td>
					<td />
					<td>
						{data.quotations.length === 0 && (
							<span>
								<NarrowButton
									value="Respond"
									onClick={() => {
										return this.addQuotation(
											data.rfq_id,
											data.rrfq_id,
											arrayIndex,
										);
									}}
								/>
							</span>
						)}
					</td>
				</tr>
				{data.quotations?.length > 0 && (
					<tr key={key++}>
						<td colSpan="1" />
						<td className="m-0 p-0" colSpan="6">
							<table className="table m-0">
								<thead>
									<tr>
										<th>Quotation number</th>
										<th>Quotation date</th>
										<th>Valid from</th>
										<th>Valid till</th>
										<th>Quotation description</th>
										<th>Status</th>
										<th />
									</tr>
								</thead>
								<tbody>
									{data.quotations?.map(
										(quotation, qArrayIndex) => {
											return (
												<tr key={ik++}>
													<td key={ik++}>
														<button
															type="button"
															style={{
																color: 'blue',
																textDecoration:
																	'underline',
																cursor: 'pointer',
																border: 'none',
																background:
																	'none',
																padding: 0,
															}}
															onClick={() =>
																this.handler(
																	'VIEW',
																	quotation.quotation_id,
																	data.rrfq_id,
																	data.rrfq_id,
																	pageNumber,
																	arrayIndex,
																	qArrayIndex,
																)
															}
														>
															{
																quotation.quotation_number
															}
														</button>
													</td>
													<td key={ik++}>
														{
															quotation.quotation_issue_date
														}
													</td>
													<td key={ik++}>
														{
															quotation.quotation_valid_from_date
														}
													</td>
													<td key={ik++}>
														{
															quotation.quotation_valid_to_date
														}
													</td>
													<td key={ik++}>
														{quotation.description}
													</td>
													<td key={ik++}>
														{this.get_status_desc(
															quotation.quotation_status,
														)}
													</td>
													<td>
														{quotation.entity_state ===
															'0' && (
															<>
																<span
																	onClick={() =>
																		this.handler(
																			'EDIT',
																			quotation.quotation_id,
																			data.rfq_id,
																			data.rrfq_id,
																			pageNumber,
																			arrayIndex,
																			qArrayIndex,
																		)
																	}
																	title="Edit"
																	style={{
																		cursor: 'pointer',
																	}}
																>
																	<i
																		className="bi bi-pencil-square"
																		style={{
																			fontSize:
																				'20',
																		}}
																	></i>
																</span>
																&nbsp;&nbsp;
																<span
																	onClick={() =>
																		this.handler(
																			'DELETE',
																			quotation.quotation_id,
																			data.rfq_id,
																			data.rrfq_id,
																			pageNumber,
																			arrayIndex,
																			qArrayIndex,
																		)
																	}
																	title="Delete"
																	style={{
																		cursor: 'pointer',
																	}}
																>
																	<i
																		className="bi bi-trash3"
																		style={{
																			fontSize:
																				'20',
																		}}
																	></i>
																</span>
																&nbsp;&nbsp;
																<span
																	onClick={() =>
																		this.handler(
																			'APPROVE',
																			quotation.quotation_id,
																			data.rfq_id,
																			data.rrfq_id,
																			pageNumber,
																			arrayIndex,
																			qArrayIndex,
																		)
																	}
																	title="Approve"
																	style={{
																		cursor: 'pointer',
																	}}
																>
																	<i
																		className="bi bi-check2"
																		style={{
																			fontSize:
																				'20',
																		}}
																	></i>
																</span>
																&nbsp;&nbsp;
															</>
														)}
													</td>
												</tr>
											);
										},
									)}
								</tbody>
							</table>
						</td>
						<td colSpan="1" />
					</tr>
				)}
			</React.Fragment>
		);
	};

	render = () => {
		const selectOption = (org_id, org_name, key) => {
			this.state.data[key] = org_id;
			this.setState({ data: this.state.data });
		};
		let filterContent = (
			<div className="row">
				<div className="col-sm-6">
					<FormField
						field={fields.rfq_number}
						controlProps={this.controlProps}
					/>
				</div>

				<div className="col-sm-6">
					<PartnerList
						field={fields.buyer_org_id}
						keyName={'buyer_org_id'}
						descName={'buyer_org_name'}
						selectOption={(org_id, org_name) =>
							selectOption(org_id, org_name, 'buyer_org_id')
						}
						controlProps={this.controlProps}
						relationship="D"
					/>
				</div>
				<div className="col-sm-6">
					<FormField
						field={fields.from_date}
						controlProps={this.controlProps}
					/>
				</div>

				<div className="col-sm-6">
					<FormField
						field={fields.to_date}
						controlProps={this.controlProps}
					/>
				</div>
				{/* <div className="col-sm-6">
					<FormField
						field={fields.rrfq_status}
						controlProps={this.controlProps}
						refType={'rrfq_statuses'}
					/>
				</div> */}
			</div>
		);
		const tableclass = 'table table-striped border-dark';
		let datablk = (
			<div className="container-fluid pt-0 m-0 ">
				<div className="col-sm-12">
					<GenericTable
						pageHeading={this.getBreadcrumb()}
						pageHeadingClass="h6 text-left"
						data={this.rfqs}
						recordsPerPage={this.recordsPerPage}
						getRow={this.getRow}
						getHeader={this.getHeader}
						tableclass={tableclass}
						getFrame={this.getFrame}
						recordsPerFrame={this.recordsPerFrame}
						pageNumber={this.pageNumber}
						isLoading={this.state.isLoading}
						pageChange={this.pageChange}
						showFilterCriteria={filterContent}
						modalDialogStyle={{ marginLeft: '15em' }}
						resetFilters={(control) =>
							resetFilters({
								fields,
								setState: this.setState.bind(this),
							})
						}
					/>
				</div>
			</div>
		);

		let frm = (
			<GenericForm
				datablk={datablk}
				buttons={[]}
				controlProps={this.controlProps}
				{...this.props}
			/>
		);
		return frm;
	};
}

export default ManageRFQs;
