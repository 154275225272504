export const invoice_statuses = {
	['0']: {
		key: 'status_draft',
		value: '0',
		label: window?.getLabelLiteral('STATUS_DRAFT'),
	},
	['1']: {
		key: 'status_delted',
		value: '1',
		label: window?.getLabelLiteral('STATUS_DELTED'),
	},
	['2']: {
		key: 'status_sent',
		value: '2',
		label: window?.getLabelLiteral('STATUS_SENT'),
	},
	['3']: {
		key: 'status_received',
		value: '3',
		label: window?.getLabelLiteral('STATUS_RECEIVED'),
	},
	['4']: {
		key: 'status_acknowledged',
		value: '4',
		label: window?.getLabelLiteral('STATUS_ACKNOWLEDGED'),
	},
	['5']: {
		key: 'status_accepted',
		value: '5',
		label: window?.getLabelLiteral('STATUS_ACCEPTED'),
	},
	['6']: {
		key: 'status_rejected',
		value: '6',
		label: window?.getLabelLiteral('STATUS_REJECTED'),
	},
	['7']: {
		key: 'status_payment_done',
		value: '7',
		label: window?.getLabelLiteral('STATUS_PAYMENT_DONE'),
	},
};
