import React from 'react';
import {
	GenericComponent,
	GenericForm,
	FormField,
	NarrowButton,
	getInitialState,
	deepCopy,
	raiseError,
	location,
	tin_type,
	tin_number,
	Location,
	tintype,
	tinnumber,
	fieldValidations,
	copyFormFieldsToDataFields,
} from 'WebUI_Framework';
import serviceRoutines from './CommercialtaxdetailServiceRoutines';

const fields = {
	location: {
		id: 'location',
		name: 'location',
		label: 'Location:',
		class: Location,
		component: location,
		mandatory: true,
	},

	tin_type: {
		id: 'tin_type',
		name: 'tin_type',
		label: 'TIN type:',
		class: tintype,
		component: tin_type,
		mandatory: true,
	},

	tin_number: {
		id: 'tin_number',
		name: 'tin_number',
		label: 'TIN Number:',
		class: tinnumber,
		component: tin_number,
		mandatory: true,
	},
};

let mapping = [
	['org_id', 'org_id'],
	['location', 'location'],
	['tin_type', 'tin_type'],
	['tin_number', 'tin_number'],
	['version', 'version'],
];

class AddCommercialTaxDetail extends GenericComponent {
	constructor(props) {
		super(props);
		this.serverInstance = this.session.getServerInstance('ROC');
		this.accessToken = this.session.getAccessToken();
	}

	initialState = getInitialState(fields);

	state = {
		data: deepCopy(this.initialState),
	};

	reset = () => {
		// This is overrding the reset method in the base class

		let data = deepCopy(this.initialState);

		this.setState({ data: data, error: undefined });
	};

	backHandler = () => {
		if (this.isChildMode()) this.props.returnToParent({ retFrom: 'add' });
		else console.log('LOADED AS A PARENT');
	};

	render() {
		let buttons = [];
		let key = 0;

		let datablk = (
			<div className="container-fluid pt-0 m-0 pe-2">
				<div className="row p-0 m-0 g-0 ">
					<div className="col-sm-12 h6 text-left">
						{this.getBreadcrumb([window.getLabelLiteral('ADD')])}
					</div>
					<div className="col-sm-6">
						<FormField
							field={fields.location}
							controlProps={this.controlProps}
						/>
					</div>
					<div className="col-sm-6">
						<FormField
							field={fields.tin_type}
							controlProps={this.controlProps}
						/>
					</div>
					<div className="col-sm-6">
						<FormField
							field={fields.tin_number}
							controlProps={this.controlProps}
						/>
					</div>

					<div className="row p-0 m-0 g-0 ">
						<div className="col-sm-6 mt-4 d-flex justify-content-right ">
							<NarrowButton
								value="Submit"
								onClick={this.submitForm}
								key={(++key).toString()}
							/>

							<NarrowButton
								value="Clear"
								onClick={this.reset}
								key={(++key).toString()}
							/>

							<NarrowButton
								value="Back"
								onClick={this.backHandler}
								key={(++key).toString()}
							/>
						</div>
					</div>
				</div>
			</div>
		);

		let form = (
			<GenericForm
				datablk={datablk}
				buttons={buttons}
				controlProps={this.controlProps}
				{...this.props}
			/>
		);
		return form;
	}

	validateForm = () => {
		let r = fieldValidations(fields, this.state.data);
		if (!r.status) {
			this.setState({ error: r });
			return false;
		}

		return true;
	};

	submitForm = async () => {
		this.resetError();
		if (!this.validateForm()) {
			return;
		}
		let retObj = {};
		this.state.data.version = '1';
		this.state.data.org_id = this.session.getLoggedInCompanyId();
		console.log(this.state.data);
		copyFormFieldsToDataFields(mapping, this.state.data, retObj, fields);
		let retData;
		try {
			retData = await serviceRoutines.add(
				this.serverInstance,
				this.accessToken,
				retObj,
			);
			if (retData !== undefined && retData !== null) {
				this.backHandler();
			}
		} catch (e) {
			console.log(e);
			this.setError(raiseError(e.error_message));
		}
	};
}
export default AddCommercialTaxDetail;
