import {
	Backdrop,
	copyFormFieldsToDataFields,
	deepCopy,
	FormField,
	generic_select,
	generic_string,
	GenericComponent,
	GenericForm,
	genericselect,
	genericstring,
	GenericTable,
	getInitialState,
	Modal,
	Modal_generic_content,
	raiseError,
} from 'WebUI_Framework';
import 'bootstrap-icons/font/bootstrap-icons.css';
import React from 'react';
import { resetFilters } from '../lib/helper';
import AddPartners from './AddPartners';
import EditPartnerParameters from './EditPartnerParameters';
import EditPartners from './EditPartners';
import partnerServiceRoutines from './PartnerServiceRoutines';

let partners_type = window.getGenericRefCode('PartnersType');
let partners_status_type = window.getGenericRefCode('PartnerStatusType');

const fields = {
	org_name: {
		id: 'org_name',
		name: 'org_name',
		label: window.getLabelLiteralWithColon('ORG_NAME'),
		class: genericstring,
		component: generic_string,
		mandatory: false,
	},
	contact_number: {
		id: 'contact_number',
		name: 'contact_number',
		label: window.getLabelLiteralWithColon('CONTACT_NUMBER'),
		class: genericstring,
		component: generic_string,
		mandatory: false,
	},
	company_email: {
		id: 'company_email',
		name: 'company_email',
		label: window.getLabelLiteralWithColon('COMPANY_EMAIL'),
		class: genericstring,
		component: generic_string,
		mandatory: false,
	},
	relationship_type: {
		id: 'relationship_type',
		name: 'relationship_type',
		label: window.getLabelLiteralWithColon('RELATIONSHIP_TYPE'),
		class: genericselect,
		component: generic_select,
		mandatory: false,
	},
};

let mapping = [
	['org_name', 'org_name'],
	['contact_number', 'contact_number'],
	['company_email', 'company_email'],
	['relationship_type', 'relationship_type'],
];
class ManagePartners extends GenericComponent {
	constructor(p) {
		super(p);
		this.recordsPerFrame = window.getParam('CURSOR_FRAME_SIZE');
		this.pageNumber = 1;
		this.selectOrgId = '';
		this.selectArrayIndex = '';
		this.selectedPartnerRelation = '';
		this.modalContent = <></>;
		//this.recordsPerPage = 7;
		this.recordsPerPage = window.getParam('TABLE_PAGE_SIZE');

		this.serverInstance = this.session.getServerInstance('ROC');
		this.accessToken = this.session.getAccessToken();
		this.getRow = this.getRow.bind(this);
		this.getFrame = this.getFrame.bind(this);
		this.closeModal1 = this.closeModal1.bind(this);
		this.onConfirm1 = this.onConfirm1.bind(this);
		this.addNew.func = this.addNew.func.bind(this);
		this.org_id = [];
		this.link_request = false;
		// this.state.data = [];
		// this.state.data1 = [];
		this.state.showModal1 = false;
		this.state.showModal2 = false;
		this.state.showModal3 = false;
		this.state.isLoading = true;
		this.partners = [];
		this.state.data = {};
	}

	componentDidMount() {
		this.getFrame(1, this.recordsPerFrame);
		this.initialState = getInitialState(fields);
		this.setState({ data: this.initialState });
	}

	initialState = getInitialState(fields);

	state = {
		data: deepCopy(this.initialState),
	};

	onReturn = (ret) => {
		this.setState((prevState, props) => {
			let newState = deepCopy(prevState);

			if (ret.retFrom === 'add') {
				this.pageNumber = 1;
				newState.data = [];
				this.getFrame(1, this.recordsPerFrame);
			} else if (ret.retFrom === 'edit') {
				let updatedArrayIndex =
					(this.pageNumber - 1) * this.recordsPerPage +
					ret.arrayIndex;
				for (const property in ret.selectData) {
					console.log(
						property,
						newState.data[updatedArrayIndex][property],
						ret.selectData[property],
					);
					newState.data[updatedArrayIndex][property] =
						ret.selectData[property];
				}
			}
			/*
			else if (ret.retFrom === "back") {
				let updatedArrayIndex = ((this.pageNumber - 1) * this.recordsPerPage) + ret.arrayIndex;
			}
			*/
			return newState;
		});
	};

	addHandler = () => {
		this.callForm(AddPartners, { ...this.props }, this.onReturn);
	};

	editHandler = (orgId, arrayIndex) => {
		this.callForm(
			EditPartners,
			{ orgId, arrayIndex, ...this.props },
			this.onReturn,
		);
	};

	linkPartner = (orgId, arrayIndex, link_request) => {
		this.selectOrgId = orgId;
		this.selectArrayIndex = arrayIndex;
		if (link_request === true) {
			this.link_request = true;
		}
		this.setState({ showModal2: true });
	};

	closeModal2 = async () => {
		this.setState({ showModal2: false });
		this.selectedPartnerRelation = '';
		this.modalContent = <></>;
		this.link_request = false;
	};

	onConfirm2 = async (modalControl) => {
		if (
			!this.selectedPartnerRelation ||
			this.selectedPartnerRelation === ''
		) {
			modalControl.errorMsg = window.getLiterals('OPTION_NOT_SELECTED');
			modalControl.error = true;
			return;
		}
		try {
			if (this.link_request) {
				let inp_data = {};
				inp_data.receiver_org_id = this.selectOrgId;
				inp_data.sender_org_id =
					this.session.getLoggedInParentCompanyId();
				inp_data.relationship_type = this.selectedPartnerRelation;
				inp_data.type = '1';

				await partnerServiceRoutines.send_relationship_request(
					this.session.getServerInstance('SUBSCRIBER'),
					this.accessToken,
					inp_data,
				);

				let arrayIndex =
					(this.pageNumber - 1) * this.recordsPerPage +
					this.selectArrayIndex;
				let data = deepCopy(this.partners);
				data[arrayIndex].partner_relationship_type =
					'P' + this.selectedPartnerRelation;
				this.setState({ data: data });
			} else {
				let inp_data = {};
				inp_data.org_id = this.selectOrgId;
				inp_data.parent_org_id =
					this.session.getLoggedInParentCompanyId();
				inp_data.partner_relationship_type =
					this.selectedPartnerRelation;

				await partnerServiceRoutines.add_partnership_rec(
					this.serverInstance,
					this.accessToken,
					inp_data,
				);

				let arrayIndex =
					(this.pageNumber - 1) * this.recordsPerPage +
					this.selectArrayIndex;
				let data = deepCopy(this.partners);
				data[arrayIndex].partner_relationship_type =
					this.selectedPartnerRelation;
				this.setState({ data: data });
			}
		} catch (e) {
			console.log(e);
			this.setError(raiseError(e.error_message));
		}
		this.selectedPartnerRelation = '';
		this.modalContent = <></>;
		this.link_request = false;
		this.setState({ showModal2: false });
	};

	editPartner = (orgId, partnerRelation, arrayIndex, link_request) => {
		let onRadioChange = (event) => {
			this.selectedPartnerRelation = event.target.value;
		};
		let key = 0;

		this.selectOrgId = orgId;
		this.selectArrayIndex = arrayIndex;
		this.selectedPartnerRelation = partnerRelation;
		if (link_request === true) {
			this.link_request = true;
		}
		let filteredTypes = {};
		if (this.selectedPartnerRelation !== 'B') {
			for (const property in partners_type) {
				if (
					property === this.selectedPartnerRelation ||
					property === 'B'
				) {
					filteredTypes[property] = partners_type[property];
				}
			}
		} else {
			filteredTypes['B'] = partners_type['B'];
		}
		this.modalContent = (
			<div>
				{Object.entries(filteredTypes).map(([code, desc]) => {
					return (
						<div key={++key}>
							<input
								type="radio"
								value={code}
								id={desc}
								name="partnersType"
								key={(++key).toString()}
								defaultChecked={
									this.selectedPartnerRelation === code
								}
								onChange={onRadioChange}
							/>
							<label htmlFor={desc}> &nbsp;&nbsp; {desc} </label>
						</div>
					);
				})}
			</div>
		);
		this.setState({ showModal3: true });
	};

	managePartnerParameters = (
		orgId,
		partnerRelation,
		arrayIndex,
		link_request,
	) => {
		this.callForm(
			EditPartnerParameters,
			{ action: 'VIEW', orgId, arrayIndex, ...this.props },
			this.onReturn,
		);
	};

	closeModal3 = async () => {
		this.selectedPartnerRelation = '';
		this.modalContent = <></>;
		this.link_request = false;
		this.setState({ showModal3: false });
	};

	viewHandler = async (org_id) => {
		let retData;

		try {
			retData = await partnerServiceRoutines.fetch(
				this.session.getServerInstance('ROC'),
				this.session.getAccessToken(),
				org_id,
			);
			if (retData !== undefined && retData !== null) {
				let orgId = retData.org_id;
				let readOnly = true;
				this.callForm(
					EditPartners,
					{ orgId, readOnly, ...this.props },
					this.onReturn,
				);
			}
		} catch (e) {
			this.setError(raiseError(e.error_message));
		}
	};

	onConfirm3 = async (modalControl) => {
		let retData;
		this.setState({ showModal3: false });
		try {
			retData = await partnerServiceRoutines.fetch_partnership_rec(
				this.serverInstance,
				this.accessToken,
				this.selectOrgId,
				this.session.getLoggedInParentCompanyId(),
			);
			if (
				retData.partner_relationship_type !==
				this.selectedPartnerRelation
			) {
				retData.partner_relationship_type =
					this.selectedPartnerRelation;
				if (!this.link_request) {
					try {
						await partnerServiceRoutines.modify_partnership_rec(
							this.serverInstance,
							this.accessToken,
							retData,
						);
						let arrayIndex =
							(this.pageNumber - 1) * this.recordsPerPage +
							this.selectArrayIndex;
						let data = deepCopy(this.partners);
						data[arrayIndex].partner_relationship_type =
							this.selectedPartnerRelation;
						this.setState({ data: data });
					} catch (e) {
						console.log(e);
						this.setError(raiseError(e.error_message));
					}
				} else {
					let inp_data = {};
					inp_data.receiver_org_id = this.selectOrgId;
					inp_data.sender_org_id =
						this.session.getLoggedInParentCompanyId();
					inp_data.relationship_type = this.selectedPartnerRelation;
					inp_data.type = '1';
					try {
						await partnerServiceRoutines.send_relationship_amendment_request(
							this.session.getServerInstance('SUBSCRIBER'),
							this.accessToken,
							inp_data,
						);
						let arrayIndex =
							(this.pageNumber - 1) * this.recordsPerPage +
							this.selectArrayIndex;
						let data = deepCopy(this.partners);
						data[arrayIndex].partner_relationship_type =
							this.selectedPartnerRelation;
						this.setState({ data: data });
					} catch (e) {
						console.log(e);
						this.setError(raiseError(e.error_message));
					}
				}
			} else {
				this.resetError();
				this.setError(raiseError('No change in relationship'));
			}
		} catch (e) {
			console.log(e);
			this.resetError();
			this.setError(raiseError(e.error_message));
		}
		this.selectedPartnerRelation = '';
		this.modalContent = <></>;
		this.link_request = false;
	};

	deleteHandler = (orgId, arrayIndex) => {
		this.selectOrgId = orgId;
		this.selectArrayIndex = arrayIndex;
		this.setState({ showModal1: true });
	};

	closeModal1 = () => {
		this.setState({ showModal1: false });
	};

	onConfirm1 = async (control) => {
		try {
			const retData = await partnerServiceRoutines.fetch(
				this.serverInstance,
				this.accessToken,
				this.selectOrgId,
			);
			if (retData !== undefined && retData !== null) {
				await partnerServiceRoutines.delete(
					this.serverInstance,
					this.accessToken,
					retData,
				);
				let updatedArrayIndex =
					(this.pageNumber - 1) * this.recordsPerPage +
					this.selectArrayIndex;
				this.partners.splice(updatedArrayIndex, 1);
				// this.setState({ data: this.partners });
				this.setState({ showModal1: false });
			}
		} catch (e) {
			control.error = true;
			control.errorMsg = e.error_message;
		}
	};

	prepare_pending_request_object = (pending_request) => {
		const result_objects = [];
		for (const element of pending_request) {
			const newObject = {
				org_id: element.receiver_org_id,
				relationship_type: element.relationship_type,
			};
			result_objects.push(newObject);
		}
		return result_objects;
	};

	async fetchCompany(org_id) {
		return await partnerServiceRoutines.fetch(
			this.serverInstance,
			this.accessToken,
			org_id,
		);
	}

	async update_partners_list(partners_list, partners_request) {
		await Promise.all(
			partners_request.map(async (request) => {
				const orgIdToSearch = request.org_id;
				const relationshipType = request.relationship_type;

				const partnerIndex = partners_list.findIndex(
					(partner) => partner.org_id === orgIdToSearch,
				);

				if (partnerIndex !== -1) {
					const partner = partners_list.splice(partnerIndex, 1)[0];
					const updatedPartner = {
						...partner,
						partner_relationship_type: 'P' + relationshipType,
					};

					partners_list.unshift(updatedPartner);
				} else {
					const company = await this.fetchCompany(orgIdToSearch);
					const updatedPartner = {
						...company,
						partner_relationship_type: 'P' + relationshipType,
					};
					partners_list.unshift(updatedPartner);
				}
			}),
		);
		return partners_list;
	}

	async getFrame(offset, recordsPerFrame) {
		let pending_request;
		try {
			pending_request = await partnerServiceRoutines.list_pending_request(
				this.session.getServerInstance('SUBSCRIBER'),
				this.session.getAccessToken(),
				offset,
				this.recordsPerFrame,
				this.session.getLoggedInParentCompanyId(),
			);
		} catch (e) {
			console.log(e);
			this.setError(raiseError(e.error_message));
		}
		let partners_list;
		let params = {
			offset: offset,
			num_recs: recordsPerFrame,
			deleted: 0,
			parent_org_id: this.session.getLoggedInParentCompanyId(),
		};

		copyFormFieldsToDataFields(mapping, this.state.data, params, fields);

		try {
			partners_list = await partnerServiceRoutines.list(
				this.serverInstance,
				this.accessToken,
				params,
			);
		} catch (e) {
			console.log(e);
			this.setError(raiseError(e.error_message));
		}

		let pending_request_obj =
			this.prepare_pending_request_object(pending_request);
		let updated_list = await this.update_partners_list(
			partners_list,
			pending_request_obj,
		);

		if (offset > 1 && updated_list.length > 0) {
			this.partners = this.partners.concat(updated_list);
		} else {
			this.partners = updated_list || [];
		}

		// this.setState({ data: this.partners });
		this.setState({ isLoading: false });
	}

	getRow(data, index, pageNumber, arrayIndex) {
		this.pageNumber = pageNumber;
		return (
			<tr key={index}>
				<td>
					<button
						type="button"
						style={{
							color: 'blue',
							textDecoration: 'underline',
							cursor: 'pointer',
							border: 'none',
							background: 'none',
							padding: 0,
						}}
						onClick={() => this.viewHandler(data.org_id)}
					>
						{data.org_name}
					</button>
				</td>
				<td className="text-wrap" style={{ width: '10rem' }}>
					{data.partner_relationship_type
						? partners_status_type[data.partner_relationship_type]
						: '-'}
				</td>
				<td>{data.contact_phone_number}</td>
				<td>{data.contact_email_id}</td>
				{data.is_subscriber === false && (
					<td>
						{!data.partner_relationship_type ? (
							<>
								<span
									onClick={() =>
										this.linkPartner(
											data.org_id,
											arrayIndex,
											false,
										)
									}
									title="Link Partner"
									style={{ cursor: 'pointer' }}
								>
									<i
										className="bi bi-link-45deg"
										style={{ fontSize: 20 }}
									></i>
								</span>
							</>
						) : (
							<>
								<span
									onClick={() =>
										this.managePartnerParameters(
											data.org_id,
											data.partner_relationship_type,
											arrayIndex,
											true,
										)
									}
									title="Manage parameters"
									style={{ cursor: 'pointer' }}
								>
									<i
										className="bi bi-info-square"
										stype={{ fontSize: 20 }}
									></i>
								</span>
								&nbsp;&nbsp;
								<span
									onClick={() =>
										this.editHandler(
											data.org_id,
											arrayIndex,
										)
									}
									title="Edit"
									style={{ cursor: 'pointer' }}
								>
									<i
										className="bi bi-pencil-square"
										style={{ fontSize: 20 }}
									></i>
								</span>
								{/*&nbsp;&nbsp;<span onClick={() => this.deleteHandler(data.org_id, arrayIndex)}
							title="Delete"
							style={{ cursor: 'pointer' }} >
							<i className="bi bi-trash3" style={{ fontSize: 20 }} ></i>
							</span>
                            */}
								&nbsp;&nbsp;
								<span
									onClick={() =>
										this.editPartner(
											data.org_id,
											data.partner_relationship_type,
											arrayIndex,
											false,
										)
									}
									title="Edit Relationship"
									style={{ cursor: 'pointer' }}
								>
									<i
										className="bi bi-pencil"
										style={{ fontSize: 20 }}
									></i>
								</span>
							</>
						)}
					</td>
				)}
				{data.is_subscriber === true &&
					!data.partner_relationship_type && (
						<td>
							<span
								onClick={() =>
									this.linkPartner(
										data.org_id,
										arrayIndex,
										true,
									)
								}
								title="Send relationship request"
								style={{ cursor: 'pointer' }}
							>
								<i
									className="bi bi-send"
									style={{ fontSize: 20 }}
								></i>
							</span>
						</td>
					)}
				{data.is_subscriber === true &&
					data.partner_relationship_type && (
						<td>
							{(data.partner_relationship_type === 'S' ||
								data.partner_relationship_type === 'D' ||
								data.partner_relationship_type === 'B') && (
								<>
									<span
										onClick={() =>
											this.managePartnerParameters(
												data.org_id,
												data.partner_relationship_type,
												arrayIndex,
												true,
											)
										}
										title="Manage parameters"
										style={{ cursor: 'pointer' }}
									>
										<i
											className="bi bi-info-square"
											stype={{ fontSize: 20 }}
										></i>
									</span>
									&nbsp;&nbsp;
									<span
										onClick={() =>
											this.editPartner(
												data.org_id,
												data.partner_relationship_type,
												arrayIndex,
												true,
											)
										}
										title="Send Relationship Amendment Request"
										style={{ cursor: 'pointer' }}
									>
										<i
											className="bi bi-pencil"
											style={{ fontSize: 20 }}
										></i>
									</span>
								</>
							)}
						</td>
					)}
			</tr>
		);
	}

	getHeader() {
		let key = 0;
		return (
			<tr key={++key}>
				<th key={++key}>Company Name</th>
				<th key={++key}>Relationship</th>
				<th key={++key}>Contact Number</th>
				<th key={++key}>Company Email</th>
				<th key={++key}>Actions</th>
			</tr>
		);
	}

	addNew = {
		literal: 'Add',
		func: function () {
			this.callForm(AddPartners, { ...this.props }, this.onReturn);
		},
	};

	render = () => {
		let filterContent = (
			<div className="row">
				<div className="col-sm-6">
					<FormField
						field={fields.org_name}
						controlProps={this.controlProps}
					/>
				</div>
				<div className="col-sm-6">
					<FormField
						field={fields.contact_number}
						controlProps={this.controlProps}
					/>
				</div>
				<div className="col-sm-6">
					<FormField
						field={fields.company_email}
						controlProps={this.controlProps}
					/>
				</div>

				{/* <div className="col-sm-6">
					<FormField
						field={fields.to_date}
						controlProps={this.controlProps}
					/>
				</div>*/}

				<div className="col-sm-6">
					<FormField
						field={fields.relationship_type}
						controlProps={this.controlProps}
						refType={'PartnersType'}
					/>
				</div>
			</div>
		);
		let buttons = [];
		let key = 0;
		let originalPartnersType = this.selectedPartnerRelation;

		let onRadioChange = (event) => {
			this.selectedPartnerRelation = event.target.value;
		};
		let modalContent = (
			<div>
				{Object.entries(partners_type).map(([code, desc]) => {
					return (
						<div key={++key}>
							<input
								type="radio"
								value={code}
								id={desc}
								name="partnersType"
								key={(++key).toString()}
								defaultChecked={originalPartnersType === code}
								onChange={onRadioChange}
							/>
							<label htmlFor={desc}> &nbsp;&nbsp; {desc} </label>
						</div>
					);
				})}
			</div>
		);

		const tableclass = 'table table-striped border-dark';
		let datablk = (
			<div className="container-fluid pt-0 m-0 pe-2">
				<div className="col-sm-12">
					<GenericTable
						pageHeading={this.getBreadcrumb()}
						pageHeadingClass="h6 text-left"
						data={this.partners}
						recordsPerPage={this.recordsPerPage}
						getRow={this.getRow}
						getHeader={this.getHeader}
						tableclass={tableclass}
						getFrame={this.getFrame}
						recordsPerFrame={this.recordsPerFrame}
						pageNumber={this.pageNumber}
						isLoading={this.state.isLoading}
						button={this.addNew}
						showFilterCriteria={filterContent}
						modalDialogStyle={{ marginLeft: '15em' }}
						resetFilters={(control) =>
							resetFilters({
								fields,
								setState: this.setState.bind(this),
							})
						}
					/>
				</div>
				{this.state.showModal1 && (
					<Modal
						title="Delete Confirmation"
						msg="Are you sure, you want to delete the Partner?"
						btn1="Confirm"
						btn2="Cancel"
						onClick={this.closeModal1}
						onConfirm={this.onConfirm1}
					/>
				)}
				{this.state.showModal2 && (
					<Modal_generic_content
						title={
							this.link_request
								? 'Send Relationship Request'
								: 'Add Relationship'
						}
						btn1="Confirm"
						btn2="Cancel"
						onClick={this.closeModal2}
						onConfirm={this.onConfirm2}
						modalContent={modalContent}
					/>
				)}
				{this.state.showModal3 && (
					<Modal_generic_content
						title={
							this.link_request
								? 'Send Relationship Edit Request'
								: 'Edit Relationship'
						}
						btn1="Confirm"
						btn2="Cancel"
						onClick={this.closeModal3}
						onConfirm={this.onConfirm3}
						modalContent={this.modalContent}
					/>
				)}
				{(this.state.showModal1 ||
					this.state.showModal2 ||
					this.state.showModal3) && <Backdrop />}
			</div>
		);

		let frm = (
			<GenericForm
				datablk={datablk}
				buttons={buttons}
				controlProps={this.controlProps}
				{...this.props}
			/>
		);
		return frm;
	};
}

export default ManagePartners;
