import {
	copyFormFieldsToDataFields,
	deepCopy,
	FormField,
	generic_select,
	generic_string,
	GenericComponent,
	GenericForm,
	genericselect,
	genericstring,
	GenericTable,
	getInitialState,
	raiseError,
	valuePresent,
} from 'WebUI_Framework';
import 'bootstrap-icons/font/bootstrap-icons.css';
import React from 'react';
import { resetFilters } from '../lib/helper';
import AddEditRule from './AddEditRule';
import ruleServiceRtn from './ruleServiceRoutines.js';

let documentTypes = window.getGenericRefCode('documentTypes');

const fields = {
	document_type: {
		id: 'document_type',
		name: 'document_type',
		label: window.getLabelLiteralWithColon('DOCUMENT_TYPE'),
		class: genericselect,
		component: generic_select,
		mandatory: false,
	},
	description: {
		id: 'description',
		name: 'description',
		label: window.getLabelLiteralWithColon('DESCRIPTION'),
		class: genericstring,
		component: generic_string,
		mandatory: false,
	},
};

let mapping = [
	['document_type', 'document_type'],
	['description', 'description'],
];

class ManageRules extends GenericComponent {
	constructor(p) {
		super(p);
		this.recordsPerFrame = window.getParam('CURSOR_FRAME_SIZE');
		this.pageNumber = 1;
		//this.recordsPerPage = 15;
		this.recordsPerPage = window.getParam('TABLE_PAGE_SIZE');
		this.rocServerInstance = this.session.getServerInstance('SUBSCRIBER');
		this.accessToken = this.session.getAccessToken();
		this.rule_set_id = null;
		this.rule_configs = [];
		this.state.editCount = 0;
		this.state.isLoading = true;
	}

	initialState = getInitialState(fields);
	state = {
		data: deepCopy(this.initialState),
	};

	componentDidMount = () => {
		this.getFrame(1, this.recordsPerFrame);
	};

	getFrame = async (offset, recordsPerFrame) => {
		let params = {
			offset,
			num_recs: recordsPerFrame,
			deleted: 0,
		};

		copyFormFieldsToDataFields(mapping, this.state.data, params, fields);

		let retData;
		try {
			retData = await ruleServiceRtn.list(this.session, params);
			// if (retData.length > 0) {
			// 		for (let i = 0; i < retData.length; i++) {
			// 				retData[i].document_type_desc =
			// 					documentTypes[retData[i].document_type];
			// 			}
			// 			this.rule_configs = this.rule_configs.concat(retData);
			// 		}
			for (let i = 0; i < retData.length; i++) {
				retData[i].document_type_desc =
					documentTypes[retData[i].document_type];
			}
			if (offset > 1 && retData.length > 0) {
				this.rule_configs = this.rule_configs.concat(retData);
			} else {
				this.rule_configs = retData || [];
			}
			this.setState({ isLoading: false });
		} catch (e) {
			this.setError(raiseError(e.error_message));
		}
	};

	onReturn = (ret) => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: 'smooth',
		});

		this.resetError();
		if (ret.error) {
			this.setError(ret.error);
			return;
		}

		let updatedArrayIndex =
			(this.pageNumber - 1) * this.recordsPerPage + ret.arrayIndex;
		/*eslint no-fallthrough: 0*/
		switch (ret.retFrom) {
			case 'ADD':
				this.rule_configs.splice(
					(this.pageNumber - 1) * this.recordsPerPage,
					0,
					ret.selectData.header,
				);
				this.rule_configs[0].document_type_desc =
					documentTypes[this.rule_configs[0].document_type];
				break;
			case 'AMEND':
			case 'EDIT':
			case 'PUBLISH':
			case 'DEPRECATE':
			case 'VIEW':
			case 'CANCEL':
				this.setState((prevState, props) => {
					this.rule_configs[updatedArrayIndex] =
						ret.selectData.header;
					this.rule_configs[updatedArrayIndex].document_type_desc =
						documentTypes[
							this.rule_configs[updatedArrayIndex].document_type
						];
					let newState = deepCopy(prevState);
					newState.editCount++;
					return newState;
				});
				break;
			case 'BACK':
				break;
			case 'DELETE':
				if (this.rule_configs[updatedArrayIndex].entity_state === '1') {
					this.setState((prevState, props) => {
						this.rule_configs[updatedArrayIndex] =
							ret.selectData.header;
						this.rule_configs[
							updatedArrayIndex
						].document_type_desc =
							documentTypes[
								this.rule_configs[
									updatedArrayIndex
								].document_type
							];
						let newState = deepCopy(prevState);
						newState.editCount++;
						return newState;
					});
				} else {
					this.rule_configs.splice(ret.arrayIndex, 1);
					this.setState({ editCount: this.state.editCount + 1 });
				}
				break;
			case 'APPROVE':
				if (this.rule_configs[updatedArrayIndex].mod_action === '2') {
					this.rule_configs.splice(ret.arrayIndex, 1);
					this.setState({ editCount: this.state.editCount + 1 });
				} else {
					this.setState((prevState, props) => {
						this.rule_configs[updatedArrayIndex] =
							ret.selectData.header;
						this.rule_configs[
							updatedArrayIndex
						].document_type_desc =
							documentTypes[
								this.rule_configs[
									updatedArrayIndex
								].document_type
							];
						let newState = deepCopy(prevState);
						newState.editCount++;
						return newState;
					});
				}
				break;
			default:
				this.setError(raiseError('Invalid return from AddEditRule'));
		}
	};

	handler = async (action, rule_set_id, pageNumber, arrayIndex) => {
		this.pageNumber = pageNumber;
		this.callForm(
			AddEditRule,
			{ action, rule_set_id, arrayIndex, pageNumber, ...this.props },
			this.onReturn,
		);
	};

	button = {
		literal: 'Add',
		func: () => {
			this.handler('ADD', null, this.pageNumber, 0);
		},
	};

	getRow = (data, index, pageNumber, arrayIndex) => {
		this.pageNumber = pageNumber;
		return (
			<tr key={index}>
				<td>{data.document_type_desc}</td>
				<td>
					{' '}
					<button
						type="button"
						style={{
							color: 'blue',
							textDecoration: 'underline',
							cursor: 'pointer',
							border: 'none',
							background: 'none',
							padding: 0,
						}}
						onClick={() =>
							this.handler(
								'VIEW',
								data.rule_set_id,
								pageNumber,
								arrayIndex,
							)
						}
					>
						{data.description}
					</button>
				</td>
				<td>{data.condition}</td>
				<td>{data.inbox_name}</td>
				<td>
					{data.entity_state === '0' && (
						<>
							<span
								onClick={() =>
									this.handler(
										'EDIT',
										data.rule_set_id,
										pageNumber,
										arrayIndex,
									)
								}
								title="Edit"
								style={{ cursor: 'pointer' }}
							>
								<i
									className="bi bi-pencil-square"
									style={{ fontSize: 20 }}
								></i>
							</span>
							&nbsp;&nbsp;
							<span
								onClick={() =>
									this.handler(
										'APPROVE',
										data.rule_set_id,
										pageNumber,
										arrayIndex,
									)
								}
								title="Approve"
								style={{ cursor: 'pointer' }}
							>
								<i
									className="bi bi-check2"
									style={{ fontSize: '20' }}
								></i>
							</span>
							&nbsp;&nbsp;
							<span
								onClick={() =>
									this.handler(
										'DELETE',
										data.rule_set_id,
										pageNumber,
										arrayIndex,
									)
								}
								title="Delete"
								style={{ cursor: 'pointer' }}
							>
								<i
									className="bi bi-trash3"
									style={{ fontSize: 20 }}
								></i>
							</span>
						</>
					)}
					{data.entity_state === '1' && (
						<>
							<span
								onClick={() =>
									this.handler(
										'AMEND',
										data.rule_set_id,
										pageNumber,
										arrayIndex,
									)
								}
								title="Amend"
								style={{ cursor: 'pointer' }}
							>
								<i
									className="bi bi-pencil-square"
									style={{ fontSize: 20 }}
								></i>
							</span>
							&nbsp;&nbsp;
							<span
								onClick={() =>
									this.handler(
										'DELETE',
										data.rule_set_id,
										pageNumber,
										arrayIndex,
									)
								}
								title="Delete"
								style={{ cursor: 'pointer' }}
							>
								<i
									className="bi bi-trash3"
									style={{ fontSize: 20 }}
								></i>
							</span>
						</>
					)}
					{data.entity_state === '2' && (
						<>
							{data.mod_action !== '2' && (
								<>
									<span
										onClick={() =>
											this.handler(
												'AMEND',
												data.rule_set_id,
												pageNumber,
												arrayIndex,
											)
										}
										title="Amend"
										style={{ cursor: 'pointer' }}
									>
										<i
											className="bi bi-pencil-square"
											style={{ fontSize: 20 }}
										></i>
									</span>
									&nbsp;&nbsp;
								</>
							)}
							<span
								onClick={() =>
									this.handler(
										'APPROVE',
										data.rule_set_id,
										pageNumber,
										arrayIndex,
									)
								}
								title="Approve"
								style={{ cursor: 'pointer' }}
							>
								<i
									className="bi bi-check2"
									style={{ fontSize: '20' }}
								></i>
							</span>
							&nbsp;&nbsp;
							<span
								onClick={() =>
									this.handler(
										'CANCEL',
										data.rule_set_id,
										pageNumber,
										arrayIndex,
									)
								}
								title={window.getLabelLiteral('CANCEL')}
								style={{ cursor: 'pointer' }}
							>
								<i
									className="bi bi-x-square"
									style={{ fontSize: 20 }}
								></i>
							</span>
							&nbsp;&nbsp;
							<span>
								{valuePresent(data.mod_action)
									? '(' +
									  window.getGenericRefCode('actionStatus')[
											data.mod_action
									  ] +
									  ')'
									: ''}
							</span>
							&nbsp;&nbsp;
						</>
					)}
				</td>
			</tr>
		);
	};

	getHeader() {
		let key = 0;
		return (
			<tr key={++key}>
				<th key={++key}>{window.getLabelLiteral('DOCUMENT_TYPE')}</th>
				<th key={++key}>{window.getLabelLiteral('DESCRIPTION')}</th>
				<th key={++key}>{window.getLabelLiteral('CONDITION_TITLE')}</th>
				<th key={++key}>{window.getLabelLiteral('INBOX_NAME')}</th>
				<th key={++key}>{window.getLabelLiteral('ACTIONS')}</th>
			</tr>
		);
	}

	render() {
		let buttons = [];
		let filterContent = (
			<div className="row">
				<div className="col-sm-6">
					<FormField
						field={fields.document_type}
						controlProps={this.controlProps}
						refType={'documentTypes'}
					/>
				</div>
				<div className="col-sm-6">
					<FormField
						field={fields.description}
						controlProps={this.controlProps}
					/>
				</div>
			</div>
		);
		const tableclass = 'table table-striped border-dark';
		let datablk = (
			<div className="container-fluid pt-0 m-0 pe-2">
				<div className="col-sm-12">
					<GenericTable
						pageHeading={this.getBreadcrumb()}
						pageHeadingClass="h6 text-left"
						data={this.rule_configs}
						recordsPerPage={this.recordsPerPage}
						getRow={this.getRow}
						getHeader={this.getHeader}
						tableclass={tableclass}
						getFrame={this.getFrame}
						recordsPerFrame={this.recordsPerFrame}
						pageNumber={this.pageNumber}
						isLoading={this.state.isLoading}
						button={this.button}
						showFilterCriteria={filterContent}
						modalDialogStyle={{ marginLeft: '15em' }}
						resetFilters={(control) =>
							resetFilters({
								fields,
								setState: this.setState.bind(this),
							})
						}
					/>
				</div>
			</div>
		);

		let frm = (
			<GenericForm
				datablk={datablk}
				buttons={buttons}
				controlProps={this.controlProps}
				{...this.props}
			/>
		);
		return frm;
	}
}

export default ManageRules;
